:root {
    --blue: #007bff;
    --aie-blue: #17225A;
    --indigo: #6610f2;
    --purple: #6f42c1;
    --pink: #e83e8c;
    --red: #dc3545;
    --orange: #FA6515;
    --yellow: #ffc107;
    --green: #28a745;
    --teal: #20c997;
    --cyan: #17a2b8;
    --white: #fff;
    --gray: #868e96;
    --gray-dark: #343a40;
    --primary: #1B2064;
    --secondary: #868e96;
    --success: #77c13a;
    --info: #17a2b8;
    --warning: #e4a93c;
    --danger: #d9534f;
    --light: #f8f9fa;
    --dark: #303840;
    --black: #272c33;
    --accent: #ed0b4c;
    --accent-red: #ed0b4c;
    --accent-yellow: #e4a93c;
    --accent-dodger-blue: #5567ff;
    --accent-pickled-bluewood: #303956;
    --accent-electric-violet: #9c42ff;
    --primary-purple: #824ee1;
    --primary-red: #ed0b4c;
    --primary-yellow: #e4a93c;
    --primary-light: #9cf;
    --primary-dodger-blue: #5567ff;
    --primary-pickled-bluewood: #303956;
    --breakpoint-xs: 0;
    --breakpoint-sm: 576px;
    --breakpoint-md: 768px;
    --breakpoint-lg: 992px;
    --breakpoint-xl: 1200px;
    --breakpoint-xxl: 1366px;
    --global-color: #5a5a5a;
    --bs-border-color: #dee2e6;
    --bs-border-color-translucent: rgba(0, 0, 0, 0.175);
    --apm-form-card-bg-color: #f8fafc;
}

@font-face {
    font-family: 'DINNextLTPro';
    src: url('fonts/DINNextLTPro-Regular.eot');
    src: url('fonts/DINNextLTPro-Regular.eot?#iefix') format('embedded-opentype'), url('fonts/DINNextLTPro-Regular.woff') format('woff');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'DINNextLTPro';
    src: url('fonts/DINNextLTPro-Medium.eot');
    src: url('fonts/DINNextLTPro-Medium.eot?#iefix') format('embedded-opentype'), url('fonts/DINNextLTPro-Medium.woff') format('woff');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'VAG Rounded Std';
    src: url('fonts/VAGRoundedStdBold.otf');
    font-weight: 700;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'VAG Rounded Std';
    src: url('fonts/VAGRoundedStdLight.otf');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Inter Regular';
    src: url('fonts/InterRegular400.otf');
}

*, :after, :before {
    box-sizing: border-box
}

html {
    -webkit-text-size-adjust: 100%;
    -webkit-tap-highlight-color: rgba(39, 44, 51, 0)
}

article, aside, figcaption, figure, footer, header, hgroup, main, nav, section {
    display: block
}

body {
    margin: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.5;
    color: var(--global-color);
    font-family: 'DINNextLTPro', sans-serif;
}

[dir] body {
    margin: 0;
    background-color: #f5f7fa
}

[dir=ltr] body {
    text-align: left
}

[tabindex="-1"]:focus:not(:focus-visible) {
    outline: 0 !important
}

hr {
    box-sizing: content-box;
    height: 0;
    overflow: visible
}

[dir] h1, [dir] h2, [dir] h3, [dir] h4, [dir] h5, [dir] h6, [dir] p {
    margin-top: 0;
    margin-bottom: 1rem
}

[dir] blockquote {
    margin: 0 0 1rem
}

b, strong {
    font-weight: bolder
}

small {
    font-size: 80%
}

sub, sup {
    position: relative;
    font-size: 75%;
    line-height: 0;
    vertical-align: baseline
}

sub {
    bottom: -.25em
}

sup {
    top: -.5em
}

a {
    color: var(--primary);
    text-decoration: auto;
}

code, kbd, pre, samp {
    font-family: "Helvetica Neue", Helvetica, Arial, sans-serif;
    font-size: 1em
}

pre {
    overflow: auto;
    -ms-overflow-style: scrollbar
}

[dir] pre {
    margin-top: 0;
    margin-bottom: 1rem
}

[dir] figure {
    margin: 0 0 1rem
}

img {
    vertical-align: middle
}

[dir] img {
    border-style: none
}

svg {
    overflow: hidden;
    vertical-align: middle
}

table {
    border-collapse: collapse
}

caption {
    color: rgba(39, 44, 51, .5);
    caption-side: bottom
}

[dir=ltr] caption {
    text-align: left
}

[dir] th {
    text-align: inherit;
    text-align: -webkit-match-parent
}

label {
    display: inline-block;
    margin-bottom: .5rem
}

button:focus {
    outline: 1px dotted;
    outline: 5px auto -webkit-focus-ring-color
}

button, input, optgroup, select, textarea {
    font-family: inherit;
    font-size: inherit;
    line-height: inherit
}

[dir] button, [dir] input, [dir] optgroup, [dir] select, [dir] textarea {
    margin: 0
}

button, input {
    overflow: visible
}

button, select {
    text-transform: none
}

[dir] [role=button] {
    cursor: pointer
}

select {
    word-wrap: normal
}

[type=button], [type=reset], [type=submit], button {
    -webkit-appearance: button
}

[dir] [type=button]:not(:disabled), [dir] [type=reset]:not(:disabled), [dir] [type=submit]:not(:disabled), [dir] button:not(:disabled) {
    cursor: pointer
}

[dir] [type=button]::-moz-focus-inner, [dir] [type=reset]::-moz-focus-inner, [dir] [type=submit]::-moz-focus-inner, [dir] button::-moz-focus-inner {
    padding: 0;
    border-style: none
}

input[type=checkbox], input[type=radio] {
    box-sizing: border-box
}

[dir] input[type=checkbox], [dir] input[type=radio] {
    padding: 0
}

textarea {
    overflow: auto;
    resize: vertical
}

fieldset {
    min-width: 0
}

[dir] fieldset {
    padding: 0;
    margin: 0;
    border: 0
}

legend {
    display: block;
    width: 100%;
    max-width: 100%;
    font-size: 1.5rem;
    line-height: inherit;
    color: inherit;
    white-space: normal
}

[dir] legend {
    padding: 0;
    margin-bottom: .5rem
}

progress {
    vertical-align: baseline
}

[type=number]::-webkit-inner-spin-button, [type=number]::-webkit-outer-spin-button {
    height: auto
}

[type=search] {
    outline-offset: -2px;
    -webkit-appearance: none
}

[type=search]::-webkit-search-decoration {
    -webkit-appearance: none
}

::-webkit-file-upload-button {
    font: inherit;
    -webkit-appearance: button
}

.form-control[type=file] {
    overflow: hidden;
}

.form-control[type=file]:not(:disabled):not([readonly]) {
    cursor: pointer;
}

.form-control::-webkit-file-upload-button {
    padding: 0.625rem 0.75rem;
    margin: -0.625rem -0.75rem;
    -webkit-margin-end: 0.75rem;
    margin-inline-end: 0.75rem;
    color: #212529;
    background-color: transparent;
    pointer-events: none;
    border-inline-end-width: 0;
    border: 0;
    border-right: 1px solid #DFE0DF;
    border-radius: 0;
    -webkit-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control::file-selector-button {
    padding: 0.625rem 0.75rem;
    margin: -0.625rem -0.75rem;
    -webkit-margin-end: 0.75rem;
    margin-inline-end: 0.75rem;
    color: #212529;
    background-color: transparent;
    pointer-events: none;
    border-inline-end-width: 0;
    border: 0;
    border-right: 1px solid #DFE0DF;
    border-radius: 0;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .form-control::-webkit-file-upload-button {
        -webkit-transition: none;
        transition: none;
    }

    .form-control::file-selector-button {
        transition: none;
    }
}

.form-control:hover:not(:disabled):not([readonly])::-webkit-file-upload-button {
    background-color: #dde0e3;
}

.form-control:hover:not(:disabled):not([readonly])::file-selector-button {
    background-color: #dde0e3;
}

output {
    display: inline-block
}

summary {
    display: list-item
}

[dir] summary {
    cursor: pointer
}

template {
    display: none
}

[hidden] {
    display: none !important
}

.h1, .h2, .h3, .h4, .h5, .h6, h1, h2, h3, h4, h5, h6 {
    font-weight: 500;
    line-height: 1.5;
    color: #333333;
}

[dir] .h1, [dir] .h2, [dir] .h3, [dir] .h4, [dir] .h5, [dir] .h6, [dir] h1, [dir] h2, [dir] h3, [dir] h4, [dir] h5, [dir] h6 {
    margin-bottom: 1rem
}

.h1, h1 {
    font-size: 2.827rem
}

.h2, h2 {
    font-size: 1.999rem
}

.h3, .h4, h3, h4 {
    font-size: 1.414rem
}

.h5, h5 {
    font-size: 1rem
}

.h6, h6 {
    font-size: .8125rem
}

[dir] hr {
    margin-top: 1rem;
    margin-bottom: 1rem;
    border: 0;
    border-top: 1px solid rgba(39, 44, 51, .1)
}

.small, small {
    font-size: .75rem;
    font-weight: 400
}

[dir] .mark, [dir] mark {
    padding: .2em;
    background-color: #fcf8e3
}

.list-unstyled {
    list-style: none
}

[dir=ltr] .list-unstyled {
    padding-left: 0
}

.list-inline {
    list-style: none
}

[dir=ltr] .list-inline {
    padding-left: 0
}

.list-inline-item {
    display: inline-block
}

[dir=ltr] .list-inline-item:not(:last-child) {
    margin-right: .5rem
}

.initialism {
    font-size: 90%;
    text-transform: uppercase
}

.img-fluid, .img-thumbnail {
    max-width: 100%;
    height: auto
}

[dir] .img-thumbnail {
    padding: .25rem;
    background-color: #f5f7fa;
    border: 1px solid #dee2e6;
    border-radius: .25rem;
    box-shadow: 0 1px 2px rgba(39, 44, 51, .075)
}

.figure {
    display: inline-block
}

.figure-img {
    line-height: 1
}

[dir] .figure-img {
    margin-bottom: .5rem
}

.figure-caption {
    font-size: 90%;
    color: #868e96
}

.pre-scrollable {
    max-height: 520px;
    overflow-y: auto
}

.container, .container-fluid, .container-lg, .container-md, .container-sm, .container-xl {
    width: 100%
}

[dir=ltr] .container, [dir=ltr] .container-fluid, [dir=ltr] .container-lg, [dir=ltr] .container-md, [dir=ltr] .container-sm, [dir=ltr] .container-xl {
    padding-right: 12px;
    padding-left: 12px;
    margin-right: auto;
    margin-left: auto
}

@media (min-width: 576px) {
    .container, .container-sm {
        max-width: 540px
    }
}

@media (min-width: 768px) {
    .container, .container-md, .container-sm {
        max-width: 720px
    }
}

@media (min-width: 992px) {
    .container, .container-lg, .container-md, .container-sm {
        max-width: 960px
    }
}

@media (min-width: 1200px) {
    .container, .container-lg, .container-md, .container-sm, .container-xl {
        max-width: 1140px
    }
}

.row {
    display: flex;
    flex-wrap: wrap
}

[dir=ltr] .row {
    margin-right: -12px;
    margin-left: -12px
}

[dir=ltr] .no-gutters {
    margin-right: 0;
    margin-left: 0
}

[dir=ltr] .no-gutters > .col, [dir=ltr] .no-gutters > [class*=col-] {
    padding-right: 0;
    padding-left: 0
}

.col, .col-1, .col-2, .col-3, .col-4, .col-5, .col-6, .col-7, .col-8, .col-9, .col-10, .col-11, .col-12, .col-auto, .col-lg, .col-lg-1, .col-lg-2, .col-lg-3, .col-lg-4, .col-lg-5, .col-lg-6, .col-lg-7, .col-lg-8, .col-lg-9, .col-lg-10, .col-lg-11, .col-lg-12, .col-lg-auto, .col-md, .col-md-1, .col-md-2, .col-md-3, .col-md-4, .col-md-5, .col-md-6, .col-md-7, .col-md-8, .col-md-9, .col-md-10, .col-md-11, .col-md-12, .col-md-auto, .col-sm, .col-sm-1, .col-sm-2, .col-sm-3, .col-sm-4, .col-sm-5, .col-sm-6, .col-sm-7, .col-sm-8, .col-sm-9, .col-sm-10, .col-sm-11, .col-sm-12, .col-sm-auto, .col-xl, .col-xl-1, .col-xl-2, .col-xl-3, .col-xl-4, .col-xl-5, .col-xl-6, .col-xl-7, .col-xl-8, .col-xl-9, .col-xl-10, .col-xl-11, .col-xl-12, .col-xl-auto, .col-xxl, .col-xxl-1, .col-xxl-2, .col-xxl-3, .col-xxl-4, .col-xxl-5, .col-xxl-6, .col-xxl-7, .col-xxl-8, .col-xxl-9, .col-xxl-10, .col-xxl-11, .col-xxl-12, .col-xxl-auto {
    position: relative;
    width: 100%
}

[dir=ltr] .col, [dir=ltr] .col-1, [dir=ltr] .col-2, [dir=ltr] .col-3, [dir=ltr] .col-4, [dir=ltr] .col-5, [dir=ltr] .col-6, [dir=ltr] .col-7, [dir=ltr] .col-8, [dir=ltr] .col-9, [dir=ltr] .col-10, [dir=ltr] .col-11, [dir=ltr] .col-12, [dir=ltr] .col-auto, [dir=ltr] .col-lg, [dir=ltr] .col-lg-1, [dir=ltr] .col-lg-2, [dir=ltr] .col-lg-3, [dir=ltr] .col-lg-4, [dir=ltr] .col-lg-5, [dir=ltr] .col-lg-6, [dir=ltr] .col-lg-7, [dir=ltr] .col-lg-8, [dir=ltr] .col-lg-9, [dir=ltr] .col-lg-10, [dir=ltr] .col-lg-11, [dir=ltr] .col-lg-12, [dir=ltr] .col-lg-auto, [dir=ltr] .col-md, [dir=ltr] .col-md-1, [dir=ltr] .col-md-2, [dir=ltr] .col-md-3, [dir=ltr] .col-md-4, [dir=ltr] .col-md-5, [dir=ltr] .col-md-6, [dir=ltr] .col-md-7, [dir=ltr] .col-md-8, [dir=ltr] .col-md-9, [dir=ltr] .col-md-10, [dir=ltr] .col-md-11, [dir=ltr] .col-md-12, [dir=ltr] .col-md-auto, [dir=ltr] .col-sm, [dir=ltr] .col-sm-1, [dir=ltr] .col-sm-2, [dir=ltr] .col-sm-3, [dir=ltr] .col-sm-4, [dir=ltr] .col-sm-5, [dir=ltr] .col-sm-6, [dir=ltr] .col-sm-7, [dir=ltr] .col-sm-8, [dir=ltr] .col-sm-9, [dir=ltr] .col-sm-10, [dir=ltr] .col-sm-11, [dir=ltr] .col-sm-12, [dir=ltr] .col-sm-auto, [dir=ltr] .col-xl, [dir=ltr] .col-xl-1, [dir=ltr] .col-xl-2, [dir=ltr] .col-xl-3, [dir=ltr] .col-xl-4, [dir=ltr] .col-xl-5, [dir=ltr] .col-xl-6, [dir=ltr] .col-xl-7, [dir=ltr] .col-xl-8, [dir=ltr] .col-xl-9, [dir=ltr] .col-xl-10, [dir=ltr] .col-xl-11, [dir=ltr] .col-xl-12, [dir=ltr] .col-xl-auto, [dir=ltr] .col-xxl, [dir=ltr] .col-xxl-1, [dir=ltr] .col-xxl-2, [dir=ltr] .col-xxl-3, [dir=ltr] .col-xxl-4, [dir=ltr] .col-xxl-5, [dir=ltr] .col-xxl-6, [dir=ltr] .col-xxl-7, [dir=ltr] .col-xxl-8, [dir=ltr] .col-xxl-9, [dir=ltr] .col-xxl-10, [dir=ltr] .col-xxl-11, [dir=ltr] .col-xxl-12, [dir=ltr] .col-xxl-auto {
    padding-right: 12px;
    padding-left: 12px
}

.col {
    flex-basis: 0;
    flex-grow: 1;
    max-width: 100%
}

.row-cols-1 > * {
    flex: 0 0 100%;
    max-width: 100%
}

.row-cols-2 > * {
    flex: 0 0 50%;
    max-width: 50%
}

.row-cols-3 > * {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%
}

.row-cols-4 > * {
    flex: 0 0 25%;
    max-width: 25%
}

.row-cols-5 > * {
    flex: 0 0 20%;
    max-width: 20%
}

.row-cols-6 > * {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%
}

.col-auto {
    flex: 0 0 auto;
    width: auto;
    max-width: 100%
}

.col-1 {
    flex: 0 0 8.3333333333%;
    max-width: 8.3333333333%
}

.col-2 {
    flex: 0 0 16.6666666667%;
    max-width: 16.6666666667%
}

.col-3 {
    flex: 0 0 25%;
    max-width: 25%
}

.col-4 {
    flex: 0 0 33.3333333333%;
    max-width: 33.3333333333%
}

.col-5 {
    flex: 0 0 41.6666666667%;
    max-width: 41.6666666667%
}

.col-6 {
    flex: 0 0 50%;
    max-width: 50%
}

.col-7 {
    flex: 0 0 58.3333333333%;
    max-width: 58.3333333333%
}

.col-8 {
    flex: 0 0 66.6666666667%;
    max-width: 66.6666666667%
}

.col-9 {
    flex: 0 0 75%;
    max-width: 75%
}

.col-10 {
    flex: 0 0 83.3333333333%;
    max-width: 83.3333333333%
}

.col-11 {
    flex: 0 0 91.6666666667%;
    max-width: 91.6666666667%
}

.col-12 {
    flex: 0 0 100%;
    max-width: 100%
}

.order-first {
    order: -1
}

.order-last {
    order: 13
}

.order-0 {
    order: 0
}

.order-1 {
    order: 1
}

.order-2 {
    order: 2
}

.order-3 {
    order: 3
}

.order-4 {
    order: 4
}

.order-5 {
    order: 5
}

.order-6 {
    order: 6
}

.order-7 {
    order: 7
}

.order-8 {
    order: 8
}

.order-9 {
    order: 9
}

.order-10 {
    order: 10
}

.order-11 {
    order: 11
}

.order-12 {
    order: 12
}

[dir=ltr] .offset-1 {
    margin-left: 8.3333333333%
}

[dir=ltr] .offset-2 {
    margin-left: 16.6666666667%
}

[dir=ltr] .offset-3 {
    margin-left: 25%
}

[dir=ltr] .offset-4 {
    margin-left: 33.3333333333%
}

[dir=ltr] .offset-5 {
    margin-left: 41.6666666667%
}

[dir=ltr] .offset-6 {
    margin-left: 50%
}

[dir=ltr] .offset-7 {
    margin-left: 58.3333333333%
}

[dir=ltr] .offset-8 {
    margin-left: 66.6666666667%
}

[dir=ltr] .offset-9 {
    margin-left: 75%
}

[dir=ltr] .offset-10 {
    margin-left: 83.3333333333%
}

[dir=ltr] .offset-11 {
    margin-left: 91.6666666667%
}

@media (min-width: 576px) {
    .col-sm {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%
    }

    .row-cols-sm-1 > * {
        flex: 0 0 100%;
        max-width: 100%
    }

    .row-cols-sm-2 > * {
        flex: 0 0 50%;
        max-width: 50%
    }

    .row-cols-sm-3 > * {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%
    }

    .row-cols-sm-4 > * {
        flex: 0 0 25%;
        max-width: 25%
    }

    .row-cols-sm-5 > * {
        flex: 0 0 20%;
        max-width: 20%
    }

    .row-cols-sm-6 > * {
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%
    }

    .col-sm-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%
    }

    .col-sm-1 {
        flex: 0 0 8.3333333333%;
        max-width: 8.3333333333%
    }

    .col-sm-2 {
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%
    }

    .col-sm-3 {
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-sm-4 {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%
    }

    .col-sm-5 {
        flex: 0 0 41.6666666667%;
        max-width: 41.6666666667%
    }

    .col-sm-6 {
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-sm-7 {
        flex: 0 0 58.3333333333%;
        max-width: 58.3333333333%
    }

    .col-sm-8 {
        flex: 0 0 66.6666666667%;
        max-width: 66.6666666667%
    }

    .col-sm-9 {
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-sm-10 {
        flex: 0 0 83.3333333333%;
        max-width: 83.3333333333%
    }

    .col-sm-11 {
        flex: 0 0 91.6666666667%;
        max-width: 91.6666666667%
    }

    .col-sm-12 {
        flex: 0 0 100%;
        max-width: 100%
    }

    .order-sm-first {
        order: -1
    }

    .order-sm-last {
        order: 13
    }

    .order-sm-0 {
        order: 0
    }

    .order-sm-1 {
        order: 1
    }

    .order-sm-2 {
        order: 2
    }

    .order-sm-3 {
        order: 3
    }

    .order-sm-4 {
        order: 4
    }

    .order-sm-5 {
        order: 5
    }

    .order-sm-6 {
        order: 6
    }

    .order-sm-7 {
        order: 7
    }

    .order-sm-8 {
        order: 8
    }

    .order-sm-9 {
        order: 9
    }

    .order-sm-10 {
        order: 10
    }

    .order-sm-11 {
        order: 11
    }

    .order-sm-12 {
        order: 12
    }

    [dir=ltr] .offset-sm-0 {
        margin-left: 0
    }

    [dir=ltr] .offset-sm-1 {
        margin-left: 8.3333333333%
    }

    [dir=ltr] .offset-sm-2 {
        margin-left: 16.6666666667%
    }

    [dir=ltr] .offset-sm-3 {
        margin-left: 25%
    }

    [dir=ltr] .offset-sm-4 {
        margin-left: 33.3333333333%
    }

    [dir=ltr] .offset-sm-5 {
        margin-left: 41.6666666667%
    }

    [dir=ltr] .offset-sm-6 {
        margin-left: 50%
    }

    [dir=ltr] .offset-sm-7 {
        margin-left: 58.3333333333%
    }

    [dir=ltr] .offset-sm-8 {
        margin-left: 66.6666666667%
    }

    [dir=ltr] .offset-sm-9 {
        margin-left: 75%
    }

    [dir=ltr] .offset-sm-10 {
        margin-left: 83.3333333333%
    }

    [dir=ltr] .offset-sm-11 {
        margin-left: 91.6666666667%
    }
}


@media (min-width: 768px) {
    .col-md {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%
    }

    .row-cols-md-1 > * {
        flex: 0 0 100%;
        max-width: 100%
    }

    .row-cols-md-2 > * {
        flex: 0 0 50%;
        max-width: 50%
    }

    .row-cols-md-3 > * {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%
    }

    .row-cols-md-4 > * {
        flex: 0 0 25%;
        max-width: 25%
    }

    .row-cols-md-5 > * {
        flex: 0 0 20%;
        max-width: 20%
    }

    .row-cols-md-6 > * {
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%
    }

    .col-md-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%
    }

    .col-md-1 {
        flex: 0 0 8.3333333333%;
        max-width: 8.3333333333%
    }

    .col-md-2 {
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%
    }

    .col-md-3 {
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-md-4 {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%
    }

    .col-md-5 {
        flex: 0 0 41.6666666667%;
        max-width: 41.6666666667%
    }

    .col-md-6 {
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-md-7 {
        flex: 0 0 58.3333333333%;
        max-width: 58.3333333333%
    }

    .col-md-8 {
        flex: 0 0 66.6666666667%;
        max-width: 66.6666666667%
    }

    .col-md-9 {
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-md-10 {
        flex: 0 0 83.3333333333%;
        max-width: 83.3333333333%
    }

    .col-md-11 {
        flex: 0 0 91.6666666667%;
        max-width: 91.6666666667%
    }

    .col-md-12 {
        flex: 0 0 100%;
        max-width: 100%
    }
}

@media (min-width: 992px) {
    .col-lg {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%
    }

    .row-cols-lg-1 > * {
        flex: 0 0 100%;
        max-width: 100%
    }

    .row-cols-lg-2 > * {
        flex: 0 0 50%;
        max-width: 50%
    }

    .row-cols-lg-3 > * {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%
    }

    .row-cols-lg-4 > * {
        flex: 0 0 25%;
        max-width: 25%
    }

    .row-cols-lg-5 > * {
        flex: 0 0 20%;
        max-width: 20%
    }

    .row-cols-lg-6 > * {
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%
    }

    .col-lg-auto {
        flex: 0 0 auto;
        width: auto;
        max-width: 100%
    }

    .col-lg-1 {
        flex: 0 0 8.3333333333%;
        max-width: 8.3333333333%
    }

    .col-lg-2 {
        flex: 0 0 16.6666666667%;
        max-width: 16.6666666667%
    }

    .col-lg-3 {
        flex: 0 0 25%;
        max-width: 25%
    }

    .col-lg-4 {
        flex: 0 0 33.3333333333%;
        max-width: 33.3333333333%
    }

    .col-lg-5 {
        flex: 0 0 41.6666666667%;
        max-width: 41.6666666667%
    }

    .col-lg-6 {
        flex: 0 0 50%;
        max-width: 50%
    }

    .col-lg-7 {
        flex: 0 0 58.3333333333%;
        max-width: 58.3333333333%
    }

    .col-lg-8 {
        flex: 0 0 66.6666666667%;
        max-width: 66.6666666667%
    }

    .col-lg-9 {
        flex: 0 0 75%;
        max-width: 75%
    }

    .col-lg-10 {
        flex: 0 0 83.3333333333%;
        max-width: 83.3333333333%
    }

    .col-lg-11 {
        flex: 0 0 91.6666666667%;
        max-width: 91.6666666667%
    }

    .col-lg-12 {
        flex: 0 0 100%;
        max-width: 100%
    }

    [dir=ltr] .offset-lg-0 {
        margin-left: 0
    }

    [dir=ltr] .offset-lg-1 {
        margin-left: 8.3333333333%
    }

    [dir=ltr] .offset-lg-2 {
        margin-left: 16.6666666667%
    }

    [dir=ltr] .offset-lg-3 {
        margin-left: 25%
    }

    [dir=ltr] .offset-lg-4 {
        margin-left: 33.3333333333%
    }

    [dir=ltr] .offset-lg-5 {
        margin-left: 41.6666666667%
    }

    [dir=ltr] .offset-lg-6 {
        margin-left: 50%
    }

    [dir=ltr] .offset-lg-7 {
        margin-left: 58.3333333333%
    }

    [dir=ltr] .offset-lg-8 {
        margin-left: 66.6666666667%
    }

    [dir=ltr] .offset-lg-9 {
        margin-left: 75%
    }

    [dir=ltr] .offset-lg-10 {
        margin-left: 83.3333333333%
    }

    [dir=ltr] .offset-lg-11 {
        margin-left: 91.6666666667%
    }
}

.table {
    width: 100%;
    color: #272c33
}

[dir] .table {
    margin-bottom: 1rem
}

[dir] .table td, [dir] .table th {
    padding: .75rem;
    border-top: 1px solid #e9edf2;
}

.table thead th {
    padding-bottom: .5rem;
    vertical-align: middle;
}

[dir] .table thead th {
    border-bottom: 2px solid #e9edf2
}

[dir] .table tbody + tbody {
    border-top: 2px solid #e9edf2
}

[dir] .table-sm td, [dir] .table-sm th {
    padding: .3rem
}

[dir] .table-bordered, [dir] .table-bordered td, [dir] .table-bordered th {
    border: 1px solid #e9edf2
}

[dir] .table-bordered thead td, [dir] .table-bordered thead th {
    border-bottom-width: 2px
}

[dir] .table-borderless tbody + tbody, [dir] .table-borderless td, [dir] .table-borderless th, [dir] .table-borderless thead th {
    border: 0
}

[dir] .table-striped tbody tr:nth-of-type(odd) {
    background-color: rgb(242, 245, 248);
}

[dir] .table-striped tbody tr:nth-of-type(even) {
    background-color: rgb(255, 255, 255);
}

.table-hover tbody tr:hover {
    color: #272c33
}

[dir] .table-hover tbody tr:hover {
    background-color: rgba(39, 44, 51, .075)
}

[dir] .table-primary, [dir] .table-primary > td, [dir] .table-primary > th {
    background-color: #cfd4ff
}

[dir] .table-primary tbody + tbody, [dir] .table-primary td, [dir] .table-primary th, [dir] .table-primary thead th {
    border-color: #a7b0ff
}

[dir] .table-hover .table-primary:hover, [dir] .table-hover .table-primary:hover > td, [dir] .table-hover .table-primary:hover > th {
    background-color: #b6bdff
}

[dir] .table-secondary, [dir] .table-secondary > td, [dir] .table-secondary > th {
    background-color: #dddfe2
}

[dir] .table-secondary tbody + tbody, [dir] .table-secondary td, [dir] .table-secondary th, [dir] .table-secondary thead th {
    border-color: #c0c4c8
}

[dir] .table-hover .table-secondary:hover, [dir] .table-hover .table-secondary:hover > td, [dir] .table-hover .table-secondary:hover > th {
    background-color: #cfd2d6
}

[dir] .table-success, [dir] .table-success > td, [dir] .table-success > th {
    background-color: #d9eec8
}

[dir] .table-hover .table-success:hover, [dir] .table-hover .table-success:hover > td, [dir] .table-hover .table-success:hover > th {
    background-color: #cce8b5
}


.table-responsive {
    display: block;
    width: 100%;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch
}

[dir] .table-responsive > .table-bordered {
    border: 0
}

[dir] .form-control {
    display: block;
    width: 100%;
    padding: 0.625rem 0.75rem;
    font-size: 1rem;
    font-weight: 400;
    line-height: 1.5;
    color: #212529;
    background-color: #fff;
    background-clip: padding-box;
    -webkit-appearance: none;
    appearance: none;
    border-radius: 0.375rem;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out;
    border: 1px solid rgba(0, 0, 0, 0.23);
}

@media (prefers-reduced-motion: reduce) {
    .form-control {
        transition: none
    }
}

[dir] .form-control::-ms-expand {
    background-color: transparent;
    border: 0
}

.form-control:-moz-focusring {
    color: transparent
}

[dir] .form-control:-moz-focusring {
    text-shadow: 0 0 0 #272c33
}

.form-control:focus {
    color: #272c33;
    outline: 0
}

[dir] .form-control:focus {
    background-color: #fff;
    border-color: #fff;
    box-shadow: inset 0 1px 1px rgba(39, 44, 51, .075), 0 0 0 1px var(--primary)
}

.form-control::-moz-placeholder {
    color: #949595;
    opacity: 1
}

.form-control:-ms-input-placeholder {
    color: #949595;
    opacity: 1
}

.form-control::placeholder {
    color: #949595;
    opacity: 1
}

.form-control:disabled, .form-control[readonly] {
    opacity: 1
}

[dir] .form-control:disabled, [dir] .form-control[readonly] {
    background-color: #e9ecef
}

input[type=date].form-control, input[type=datetime-local].form-control, input[type=month].form-control, input[type=time].form-control {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none
}

select.form-control:focus::-ms-value {
    color: #272c33
}

[dir] select.form-control:focus::-ms-value {
    background-color: #fff
}

.form-control-file, .form-control-range {
    display: block;
    width: 100%
}

.col-form-label {
    font-size: inherit;
    line-height: 1.5
}

[dir] .col-form-label {
    padding-top: calc(.5rem + 1px);
    padding-bottom: calc(.5rem + 1px);
    margin-bottom: 0
}

.col-form-label-lg {
    font-size: 1.21875rem;
    line-height: 1.75
}

[dir] .col-form-label-lg {
    padding-top: calc(.5rem + 1px);
    padding-bottom: calc(.5rem + 1px)
}

.col-form-label-sm {
    font-size: .7109375rem;
    line-height: 1.5
}

[dir] .col-form-label-sm {
    padding-top: calc(.25rem + 1px);
    padding-bottom: calc(.25rem + 1px)
}

.form-control-sm {
    height: calc(1.5em + .5rem + 2px);
    font-size: .7109375rem;
    line-height: 1.5
}

[dir] .form-control-sm {
    padding: .25rem .5rem;
    border-radius: .2rem
}

.form-control-lg {
    height: calc(1.75em + 1rem + 2px);
    font-size: 1.21875rem;
    line-height: 1.75
}

[dir] .form-control-lg {
    padding: .5rem 1rem;
    border-radius: .5rem
}

select.form-control[multiple], select.form-control[size], textarea.form-control {
    height: auto
}

[dir] .form-group {
    margin-bottom: 1rem
}

.form-text {
    display: block
}

[dir] .form-text {
    margin-top: .25rem
}

.form-row {
    display: flex;
    flex-wrap: wrap
}

[dir=ltr] .form-row {
    margin-right: -5px;
    margin-left: -5px
}

[dir=ltr] .form-row > .col, [dir=ltr] .form-row > [class*=col-] {
    padding-right: 5px;
    padding-left: 5px
}

.form-check {
    position: relative;
    display: block
}

[dir=ltr] .form-check {
    padding-left: 1.25rem
}

.form-check-input {
    position: absolute
}

[dir] .form-check-input {
    margin-top: .3rem
}

[dir=ltr] .form-check-input {
    margin-left: -1.25rem
}

.form-check-input:disabled ~ .form-check-label, .form-check-input[disabled] ~ .form-check-label {
    color: rgba(39, 44, 51, .5)
}

[dir] .form-check-label {
    margin-bottom: 0
}

.form-check-inline {
    display: inline-flex;
    align-items: center
}

[dir=ltr] .form-check-inline {
    padding-left: 0;
    margin-right: .75rem
}

.form-check-inline .form-check-input {
    position: static
}

[dir] .form-check-inline .form-check-input {
    margin-top: 0
}

[dir=ltr] .form-check-inline .form-check-input {
    margin-right: .3125rem;
    margin-left: 0
}

.valid-feedback {
    display: none;
    width: 100%;
    font-size: .8125rem;
    color: #77c13a
}

[dir] .valid-feedback {
    margin-top: .25rem
}

.valid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    font-size: 1rem;
    line-height: 1.5;
    color: #212529
}

[dir] .valid-tooltip {
    padding: .5rem 1rem;
    margin-top: .1rem;
    background-color: #77c13a;
    border-radius: .25rem
}

[dir=ltr] .valid-tooltip {
    left: 0
}

.is-valid ~ .valid-feedback, .is-valid ~ .valid-tooltip, .was-validated :valid ~ .valid-feedback, .was-validated :valid ~ .valid-tooltip {
    display: block
}

[dir] .form-control.is-valid, [dir] .was-validated .form-control:valid {
    border-color: #77c13a;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='8' height='8'%3E%3Cpath fill='%2377c13a' d='M2.3 6.73L.6 4.53c-.4-1.04.46-1.4 1.1-.8l1.1 1.4 3.4-3.8c.6-.63 1.6-.27 1.2.7l-4 4.6c-.43.5-.8.4-1.1.1z'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: calc(.75em + .5rem) calc(.75em + .5rem)
}

[dir=ltr] .form-control.is-valid, [dir=ltr] .was-validated .form-control:valid {
    padding-right: calc(1.5em + 1rem);
    background-position: right calc(.375em + .25rem) center
}

[dir] .form-control.is-valid:focus, [dir] .was-validated .form-control:valid:focus {
    border-color: #77c13a;
    box-shadow: 0 0 0 1px rgba(119, 193, 58, .25)
}

[dir=ltr] .was-validated textarea.form-control:valid, [dir=ltr] textarea.form-control.is-valid {
    padding-right: calc(1.5em + 1rem);
    background-position: top calc(.375em + .25rem) right calc(.375em + .25rem)
}

[dir] .custom-select.is-valid, [dir] .was-validated .custom-select:valid {
    border-color: #77c13a
}

[dir] .custom-select.is-valid:focus, [dir] .was-validated .custom-select:valid:focus {
    border-color: #77c13a;
    box-shadow: 0 0 0 1px rgba(119, 193, 58, .25)
}

.form-check-input.is-valid ~ .form-check-label, .was-validated .form-check-input:valid ~ .form-check-label {
    color: #77c13a
}

.form-check-input.is-valid ~ .valid-feedback, .form-check-input.is-valid ~ .valid-tooltip, .was-validated .form-check-input:valid ~ .valid-feedback, .was-validated .form-check-input:valid ~ .valid-tooltip {
    display: block
}

.custom-control-input.is-valid ~ .custom-control-label, .was-validated .custom-control-input:valid ~ .custom-control-label {
    color: #77c13a
}

[dir] .custom-control-input.is-valid ~ .custom-control-label:before, [dir] .was-validated .custom-control-input:valid ~ .custom-control-label:before {
    border-color: #77c13a
}

[dir] .custom-control-input.is-valid:checked ~ .custom-control-label:before, [dir] .was-validated .custom-control-input:valid:checked ~ .custom-control-label:before {
    border-color: #92cf5f;
    background-color: #92cf5f
}

[dir] .custom-control-input.is-valid:focus ~ .custom-control-label:before, [dir] .was-validated .custom-control-input:valid:focus ~ .custom-control-label:before {
    box-shadow: 0 0 0 1px rgba(119, 193, 58, .25)
}

[dir] .custom-control-input.is-valid:focus:not(:checked) ~ .custom-control-label:before, [dir] .custom-file-input.is-valid ~ .custom-file-label, [dir] .was-validated .custom-control-input:valid:focus:not(:checked) ~ .custom-control-label:before, [dir] .was-validated .custom-file-input:valid ~ .custom-file-label {
    border-color: #77c13a
}

[dir] .custom-file-input.is-valid:focus ~ .custom-file-label, [dir] .was-validated .custom-file-input:valid:focus ~ .custom-file-label {
    border-color: #77c13a;
    box-shadow: 0 0 0 1px rgba(119, 193, 58, .25)
}

.invalid-feedback {
    display: none;
    width: 100%;
    font-size: .8125rem;
    color: #d9534f
}

.invalid-feedback-custom {
    display: block;
    width: 100%;
    font-size: .8125rem;
    color: #d9534f
}

.invalid-custom-border {
    border: 2px solid red;
}

[dir] .invalid-feedback {
    margin-top: .25rem
}

.invalid-tooltip {
    position: absolute;
    top: 100%;
    z-index: 5;
    display: none;
    max-width: 100%;
    font-size: 1rem;
    line-height: 1.5;
    color: #fff
}

[dir] .invalid-tooltip {
    padding: .5rem 1rem;
    margin-top: .1rem;
    background-color: #d9534f;
    border-radius: .25rem
}

[dir=ltr] .invalid-tooltip {
    left: 0
}

.is-invalid ~ .invalid-feedback, .is-invalid ~ .invalid-tooltip, .was-validated :invalid ~ .invalid-feedback, .was-validated :invalid ~ .invalid-tooltip {
    display: block
}

[dir] .form-control.is-invalid, [dir] .was-validated .form-control:invalid {
    border-color: #d9534f;
    background-image: url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' width='12' height='12' fill='none' stroke='%23d9534f'%3E%3Ccircle cx='6' cy='6' r='4.5'/%3E%3Cpath stroke-linejoin='round' d='M5.8 3.6h.4L6 6.5z'/%3E%3Ccircle cx='6' cy='8.2' r='.6' fill='%23d9534f' stroke='none'/%3E%3C/svg%3E");
    background-repeat: no-repeat;
    background-size: calc(.75em + .5rem) calc(.75em + .5rem)
}

[dir=ltr] .form-control.is-invalid, [dir=ltr] .was-validated .form-control:invalid {
    padding-right: calc(1.5em + 1rem);
    background-position: right calc(.375em + .25rem) center
}

[dir] .form-control.is-invalid:focus, [dir] .was-validated .form-control:invalid:focus {
    border-color: #d9534f;
    box-shadow: 0 0 0 1px rgba(217, 83, 79, .25)
}

[dir=ltr] .was-validated textarea.form-control:invalid, [dir=ltr] textarea.form-control.is-invalid {
    padding-right: calc(1.5em + 1rem);
    background-position: top calc(.375em + .25rem) right calc(.375em + .25rem)
}

[dir] .custom-select.is-invalid, [dir] .was-validated .custom-select:invalid {
    border-color: #d9534f
}

[dir] .custom-select.is-invalid:focus, [dir] .was-validated .custom-select:invalid:focus {
    border-color: #d9534f;
    box-shadow: 0 0 0 1px rgba(217, 83, 79, .25)
}

.form-check-input.is-invalid ~ .form-check-label, .was-validated .form-check-input:invalid ~ .form-check-label {
    color: #d9534f
}

.form-check-input.is-invalid ~ .invalid-feedback, .form-check-input.is-invalid ~ .invalid-tooltip, .was-validated .form-check-input:invalid ~ .invalid-feedback, .was-validated .form-check-input:invalid ~ .invalid-tooltip {
    display: block
}

.custom-control-input.is-invalid ~ .custom-control-label, .was-validated .custom-control-input:invalid ~ .custom-control-label {
    color: #d9534f
}

[dir] .custom-control-input.is-invalid ~ .custom-control-label:before, [dir] .was-validated .custom-control-input:invalid ~ .custom-control-label:before {
    border-color: #d9534f
}

[dir] .custom-control-input.is-invalid:checked ~ .custom-control-label:before, [dir] .was-validated .custom-control-input:invalid:checked ~ .custom-control-label:before {
    border-color: #e27c79;
    background-color: #e27c79
}

[dir] .custom-control-input.is-invalid:focus ~ .custom-control-label:before, [dir] .was-validated .custom-control-input:invalid:focus ~ .custom-control-label:before {
    box-shadow: 0 0 0 1px rgba(217, 83, 79, .25)
}

[dir] .custom-control-input.is-invalid:focus:not(:checked) ~ .custom-control-label:before, [dir] .custom-file-input.is-invalid ~ .custom-file-label, [dir] .was-validated .custom-control-input:invalid:focus:not(:checked) ~ .custom-control-label:before, [dir] .was-validated .custom-file-input:invalid ~ .custom-file-label {
    border-color: #d9534f
}

[dir] .custom-file-input.is-invalid:focus ~ .custom-file-label, [dir] .was-validated .custom-file-input:invalid:focus ~ .custom-file-label {
    border-color: #d9534f;
    box-shadow: 0 0 0 1px rgba(217, 83, 79, .25)
}

.form-inline {
    display: flex;
    flex-flow: row wrap;
    align-items: center
}

.form-inline .form-check {
    width: 100%
}

@media (min-width: 576px) {
    .form-inline label {
        display: flex;
        align-items: center;
        justify-content: center
    }

    [dir] .form-inline label {
        margin-bottom: 0
    }

    .form-inline .form-group {
        display: flex;
        flex: 0 0 auto;
        flex-flow: row wrap;
        align-items: center
    }

    [dir] .form-inline .form-group {
        margin-bottom: 0
    }

    .form-inline .form-control {
        display: inline-block;
        width: auto;
        vertical-align: middle
    }

    .form-inline .form-control-plaintext {
        display: inline-block
    }

    .form-inline .custom-select, .form-inline .input-group {
        width: auto
    }

    .form-inline .form-check {
        display: flex;
        align-items: center;
        justify-content: center;
        width: auto
    }

    [dir=ltr] .form-inline .form-check {
        padding-left: 0
    }

    .form-inline .form-check-input {
        position: relative;
        flex-shrink: 0
    }

    [dir] .form-inline .form-check-input {
        margin-top: 0
    }

    [dir=ltr] .form-inline .form-check-input {
        margin-right: .25rem;
        margin-left: 0
    }

    .form-inline .custom-control {
        align-items: center;
        justify-content: center
    }

    [dir] .form-inline .custom-control-label {
        margin-bottom: 0
    }
}

.btn {
    font-family: 'VAG Rounded Std';
    font-weight: 700;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 12px 20px;
    border-radius: 3px;
    vertical-align: middle;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    line-height: 1.2;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.14);
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out;
}

@media (prefers-reduced-motion: reduce) {
    .btn {
        transition: none
    }
}

.btn:hover {
    color: #272c33;
    text-decoration: none
}

.btn.focus, .btn:focus {
    outline: 0
}

[dir] .btn.focus, [dir] .btn:focus {
    box-shadow: 0 0 0 1px var(--primary)
}

.btn.disabled, .btn:disabled {
    opacity: .65
}

[dir] .btn.disabled, [dir] .btn:disabled {
    box-shadow: none
}

[dir] .btn:not(:disabled):not(.disabled) {
    cursor: pointer
}

[dir] .btn:not(:disabled):not(.disabled).active, [dir] .btn:not(:disabled):not(.disabled):active {
    box-shadow: none;
}

[dir] .btn:not(:disabled):not(.disabled).active:focus, [dir] .btn:not(:disabled):not(.disabled):active:focus {
    box-shadow: 0 0 0 1px var(--primary), inset 0 3px 5px rgba(39, 44, 51, .125)
}

a.btn.disabled, fieldset:disabled a.btn {
    pointer-events: none
}

.btn-primary {
    color: #fff;
    background-color: var(--primary);
    border-color: var(--primary);
}

.btn-primary:hover {
    color: #fff;
    background-color: #2935c5;
    border-color: #2935c5;
}

.btn-primary.focus, .btn-primary:focus {
    color: #fff;
    background-color: #2935c5;
    border-color: #2935c5;
}

.btn-primary.disabled, .btn-primary:disabled {
    color: #fff;
    background-color: var(--primary);
    border-color: var(--primary)
}

.btn-primary:not(:disabled):not(.disabled).active, .btn-primary:not(:disabled):not(.disabled):active, .show > .btn-primary.dropdown-toggle {
    color: #fff;
    background-color: #2239ff;
    border-color: #152eff
}

.btn-primary:not(:disabled):not(.disabled).active:focus, .btn-primary:not(:disabled):not(.disabled):active:focus, .show > .btn-primary.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(39, 44, 51, .125), 0 0 0 1px rgba(111, 126, 255, .5)
}

.btn-secondary {
    color: #fff
}

[dir] .btn-secondary {
    background-color: #868e96;
    border-color: #868e96;
    box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, .15), 0 1px 1px rgba(39, 44, 51, .075)
}

.btn-secondary:hover {
    color: #fff
}

[dir] .btn-secondary:hover {
    background-color: #727b84;
    border-color: #6c757d
}

.btn-secondary.focus, .btn-secondary:focus {
    color: #fff
}

[dir] .btn-secondary.focus, [dir] .btn-secondary:focus {
    background-color: #727b84;
    border-color: #6c757d;
}

.btn-secondary.disabled, .btn-secondary:disabled {
    color: #fff
}

[dir] .btn-secondary.disabled, [dir] .btn-secondary:disabled {
    background-color: #868e96;
    border-color: #868e96
}

.btn-secondary:not(:disabled):not(.disabled).active, .btn-secondary:not(:disabled):not(.disabled):active, .show > .btn-secondary.dropdown-toggle {
    color: #fff
}

[dir] .btn-secondary:not(:disabled):not(.disabled).active, [dir] .btn-secondary:not(:disabled):not(.disabled):active, [dir] .show > .btn-secondary.dropdown-toggle {
    background-color: #6c757d;
    border-color: #666e76
}

[dir] .btn-secondary:not(:disabled):not(.disabled).active:focus, [dir] .btn-secondary:not(:disabled):not(.disabled):active:focus, [dir] .show > .btn-secondary.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(39, 44, 51, .125), 0 0 0 1px rgba(152, 159, 166, .5)
}

.btn-success {
    color: #212529
}

[dir] .btn-success {
    background-color: #77c13a;
    border-color: #77c13a;
}

.btn-success:hover {
    color: #fff
}

[dir] .btn-success:hover {
    background-color: #65a431;
    border-color: #5f9a2e
}

.btn-success.focus, .btn-success:focus {
    color: #fff
}

[dir] .btn-success.focus, [dir] .btn-success:focus {
    background-color: #65a431;
    border-color: #5f9a2e;
    box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, .15), 0 1px 1px rgba(39, 44, 51, .075), 0 0 0 1px rgba(106, 170, 55, .5)
}

.btn-success.disabled, .btn-success:disabled {
    color: #212529
}

[dir] .btn-success.disabled, [dir] .btn-success:disabled {
    background-color: #77c13a;
    border-color: #77c13a
}

.btn-success:not(:disabled):not(.disabled).active, .btn-success:not(:disabled):not(.disabled):active, .show > .btn-success.dropdown-toggle {
    color: #fff
}

[dir] .btn-success:not(:disabled):not(.disabled).active, [dir] .btn-success:not(:disabled):not(.disabled):active, [dir] .show > .btn-success.dropdown-toggle {
    background-color: #5f9a2e;
    border-color: #59902b
}

[dir] .btn-success:not(:disabled):not(.disabled).active:focus, [dir] .btn-success:not(:disabled):not(.disabled):active:focus, [dir] .show > .btn-success.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(39, 44, 51, .125), 0 0 0 1px rgba(106, 170, 55, .5)
}

.btn-info {
    color: #fff
}

[dir] .btn-info {
    background-color: #17a2b8;
    border-color: #17a2b8;
}

.btn-info:hover {
    color: #fff
}

[dir] .btn-info:hover {
    background-color: #138496;
    border-color: #117a8b
}

.btn-info.focus, .btn-info:focus {
    color: #fff
}

[dir] .btn-info.focus, [dir] .btn-info:focus {
    background-color: #138496;
    border-color: #117a8b;
}

.btn-info.disabled, .btn-info:disabled {
    color: #fff
}

[dir] .btn-info.disabled, [dir] .btn-info:disabled {
    background-color: #17a2b8;
    border-color: #17a2b8
}

.btn-info:not(:disabled):not(.disabled).active, .btn-info:not(:disabled):not(.disabled):active, .show > .btn-info.dropdown-toggle {
    color: #fff
}

[dir] .btn-info:not(:disabled):not(.disabled).active, [dir] .btn-info:not(:disabled):not(.disabled):active, [dir] .show > .btn-info.dropdown-toggle {
    background-color: #117a8b;
    border-color: #10707f
}

[dir] .btn-info:not(:disabled):not(.disabled).active:focus, [dir] .btn-info:not(:disabled):not(.disabled):active:focus, [dir] .show > .btn-info.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(39, 44, 51, .125), 0 0 0 1px rgba(58, 176, 195, .5)
}

.btn-danger {
    color: #fff
}

[dir] .btn-danger {
    background-color: #ED4B48;
    border-color: #d9534f;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.14);
}

.btn-danger:hover {
    color: #fff
}

[dir] .btn-danger:hover {
    background-color: #d23430;
    border-color: #c9302c
}

.btn-danger.focus, .btn-danger:focus {
    color: #fff
}

[dir] .btn-danger.focus, [dir] .btn-danger:focus {
    background-color: #d23430;
    border-color: #c9302c;
}

.btn-danger.disabled, .btn-danger:disabled {
    color: #fff
}

[dir] .btn-danger.disabled, [dir] .btn-danger:disabled {
    background-color: #d9534f;
    border-color: #d9534f
}

.btn-danger:not(:disabled):not(.disabled).active, .btn-danger:not(:disabled):not(.disabled):active, .show > .btn-danger.dropdown-toggle {
    color: #fff
}

[dir] .btn-danger:not(:disabled):not(.disabled).active, [dir] .btn-danger:not(:disabled):not(.disabled):active, [dir] .show > .btn-danger.dropdown-toggle {
    background-color: #c9302c;
    border-color: #bf2e29
}

[dir] .btn-danger:not(:disabled):not(.disabled).active:focus, [dir] .btn-danger:not(:disabled):not(.disabled):active:focus, [dir] .show > .btn-danger.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(39, 44, 51, .125), 0 0 0 1px rgba(223, 109, 105, .5)
}

.btn-primary-purple:not(:disabled):not(.disabled).active, .btn-primary-purple:not(:disabled):not(.disabled):active, .show > .btn-primary-purple.dropdown-toggle {
    color: #fff
}

.btn-link {
    font-weight: 400;
    color: rgba(39, 44, 51, .7);
    text-decoration: none
}

.btn-link:hover {
    color: rgba(6, 7, 8, .7)
}

.btn-link.focus, .btn-link:focus, .btn-link:hover {
    text-decoration: underline
}

.btn-link.disabled, .btn-link:disabled {
    color: #868e96;
    pointer-events: none
}

.btn-group-lg > .btn, .btn-lg {
    font-size: 1.21875rem;
    line-height: 1.75
}

[dir] .btn-group-lg > .btn, [dir] .btn-lg {
    padding: .5rem 2rem;
    border-radius: .5rem
}

.btn-group-sm > .btn, .btn-sm {
    font-size: .7109375rem;
    line-height: 1.5
}

[dir] .btn-group-sm > .btn, [dir] .btn-sm {
    padding: .25rem .5rem;
    border-radius: .2rem
}

.btn-block {
    display: block;
    width: 100%
}

[dir] .btn-block + .btn-block {
    margin-top: .5rem
}

input[type=button].btn-block, input[type=reset].btn-block, input[type=submit].btn-block {
    width: 100%
}

.fade {
    transition: opacity .15s linear
}

@media (prefers-reduced-motion: reduce) {
    .fade {
        transition: none
    }
}

.fade:not(.show) {
    opacity: 0
}

.collapse:not(.show) {
    display: none
}

.collapsing {
    position: relative;
    height: 0;
    overflow: hidden;
    transition: height .35s ease
}

@media (prefers-reduced-motion: reduce) {
    .collapsing {
        transition: none
    }
}

.dropdown, .dropleft, .dropright, .dropup {
    position: relative
}

.dropdown-toggle {
    white-space: nowrap
}

.dropdown-toggle:after {
    display: inline-block;
    vertical-align: .255em;
    content: ""
}

[dir] .dropdown-toggle:after {
    border-top: .3em solid;
    border-bottom: 0
}

[dir=ltr] .dropdown-toggle:after {
    margin-left: .255em
}

[dir=ltr] .dropdown-toggle:after {
    border-right: .3em solid transparent;
    border-left: .3em solid transparent
}

[dir=ltr] .dropdown-toggle:empty:after {
    margin-left: 0
}

.dropdown-menu {
    position: absolute;
    top: 100%;
    z-index: 1000;
    display: none;
    min-width: 10rem;
    font-size: .875rem;
    color: #272c33;
    list-style: none
}

[dir] .dropdown-menu {
    padding: .5rem 0;
    margin: .5rem 0 0;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid transparent;
    border-radius: .1875rem;
    box-shadow: 0 .5rem 1rem rgba(39, 44, 51, .175)
}

[dir=ltr] .dropdown-menu {
    left: 0;
    float: left;
    text-align: left
}

[dir=ltr] .dropdown-menu-left {
    right: auto;
    left: 0
}

[dir=ltr] .dropdown-menu-right {
    left: auto;
    right: 0
}

@media (min-width: 576px) {
    [dir=ltr] .dropdown-menu-sm-left {
        right: auto;
        left: 0
    }

    [dir=ltr] .dropdown-menu-sm-right {
        left: auto;
        right: 0
    }
}

@media (min-width: 768px) {
    [dir=ltr] .dropdown-menu-md-left {
        right: auto;
        left: 0
    }

    [dir=ltr] .dropdown-menu-md-right {
        left: auto;
        right: 0
    }
}

@media (min-width: 992px) {
    [dir=ltr] .dropdown-menu-lg-left {
        right: auto;
        left: 0
    }

    [dir=ltr] .dropdown-menu-lg-right {
        left: auto;
        right: 0
    }
}

@media (min-width: 1200px) {
    [dir=ltr] .dropdown-menu-xl-left {
        right: auto;
        left: 0
    }

    [dir=ltr] .dropdown-menu-xl-right {
        left: auto;
        right: 0
    }
}

@media (min-width: 1366px) {
    [dir=ltr] .dropdown-menu-xxl-left {
        right: auto;
        left: 0
    }

    [dir=ltr] .dropdown-menu-xxl-right {
        left: auto;
        right: 0
    }
}

.dropup .dropdown-menu {
    top: auto;
    bottom: 100%
}

[dir] .dropup .dropdown-menu {
    margin-top: 0;
    margin-bottom: .5rem
}

.dropup .dropdown-toggle:after {
    display: inline-block;
    vertical-align: .255em;
    content: ""
}

[dir] .dropup .dropdown-toggle:after {
    border-top: 0;
    border-bottom: .3em solid
}

[dir=ltr] .dropup .dropdown-toggle:after {
    margin-left: .255em;
    border-right: .3em solid transparent;
    border-left: .3em solid transparent
}

[dir=ltr] .dropup .dropdown-toggle:empty:after {
    margin-left: 0
}

.dropright .dropdown-menu {
    top: 0
}

[dir] .dropright .dropdown-menu {
    margin-top: 0
}

[dir=ltr] .dropright .dropdown-menu {
    right: auto;
    left: 100%;
    margin-left: .5rem
}

.dropright .dropdown-toggle:after {
    display: inline-block;
    vertical-align: .255em;
    content: ""
}

.no-caret .dropdown-toggle:after {
    content: none;
}

[dir] .dropright .dropdown-toggle:after {
    border-top: .3em solid transparent;
    border-bottom: .3em solid transparent
}

[dir=ltr] .dropright .dropdown-toggle:after {
    margin-left: .255em;
    border-right: 0;
    border-left: .3em solid
}

[dir=ltr] .dropright .dropdown-toggle:empty:after {
    margin-left: 0
}

.dropright .dropdown-toggle:after {
    vertical-align: 0
}

.dropleft .dropdown-menu {
    top: 0
}

[dir] .dropleft .dropdown-menu {
    margin-top: 0
}

[dir=ltr] .dropleft .dropdown-menu {
    right: 100%;
    left: auto;
    margin-right: .5rem
}

.dropleft .dropdown-toggle:after {
    display: inline-block;
    vertical-align: .255em;
    content: ""
}

[dir=ltr] .dropleft .dropdown-toggle:after {
    margin-left: .255em
}

.dropleft .dropdown-toggle:after {
    display: none
}

.dropleft .dropdown-toggle:before {
    display: inline-block;
    vertical-align: .255em;
    content: ""
}

[dir] .dropleft .dropdown-toggle:before {
    border-top: .3em solid transparent;
    border-bottom: .3em solid transparent
}

[dir=ltr] .dropleft .dropdown-toggle:before {
    margin-right: .255em;
    border-right: .3em solid
}

[dir=ltr] .dropleft .dropdown-toggle:empty:after {
    margin-left: 0
}

.dropleft .dropdown-toggle:before {
    vertical-align: 0
}

.dropdown-menu[x-placement^=bottom], .dropdown-menu[x-placement^=left], .dropdown-menu[x-placement^=right], .dropdown-menu[x-placement^=top] {
    bottom: auto
}

[dir=ltr] .dropdown-menu[x-placement^=bottom], [dir=ltr] .dropdown-menu[x-placement^=left], [dir=ltr] .dropdown-menu[x-placement^=right], [dir=ltr] .dropdown-menu[x-placement^=top] {
    right: auto
}

.dropdown-divider {
    height: 0;
    overflow: hidden
}

[dir] .dropdown-divider {
    margin: .5rem 0;
    border-top: 1px solid #e9ecef
}

.dropdown-item {
    display: block;
    width: 100%;
    font-weight: 400;
    color: rgba(39, 44, 51, .7);
    white-space: nowrap
}

[dir] .dropdown-item {
    padding: .25rem 1.25rem;
    clear: both;
    text-align: inherit;
    background-color: transparent;
    border: 0
}

.dropdown-item:focus, .dropdown-item:hover {
    color: rgba(39, 44, 51, .9);
    text-decoration: none
}

[dir] .dropdown-item:focus, [dir] .dropdown-item:hover {
    background-color: transparent
}

.dropdown-item.active, .dropdown-item:active {
    text-decoration: none
}

.dropdown-item.disabled, .dropdown-item:disabled {
    color: rgba(39, 44, 51, .3);
    pointer-events: none
}

[dir] .dropdown-item.disabled, [dir] .dropdown-item:disabled {
    background-color: transparent
}

.dropdown-menu.show {
    display: block
}

.dropdown-header {
    display: block;
    font-size: .7109375rem;
    color: #868e96;
    white-space: nowrap
}

[dir] .dropdown-header {
    padding: .5rem 1.25rem;
    margin-bottom: 0
}

.dropdown-item-text {
    display: block;
    color: rgba(39, 44, 51, .7)
}

[dir] .dropdown-item-text {
    padding: .25rem 1.25rem
}

.btn-group, .btn-group-vertical {
    position: relative;
    display: inline-flex;
    vertical-align: middle
}

.btn-group-vertical > .btn, .btn-group > .btn {
    position: relative;
    flex: 1 1 auto
}

.btn-group-vertical > .btn.active, .btn-group-vertical > .btn:active, .btn-group-vertical > .btn:focus, .btn-group-vertical > .btn:hover, .btn-group > .btn.active, .btn-group > .btn:active, .btn-group > .btn:focus, .btn-group > .btn:hover {
    z-index: 1
}

.btn-toolbar {
    display: flex;
    flex-wrap: wrap;
    justify-content: flex-start
}

.btn-toolbar .input-group {
    width: auto
}

[dir=ltr] .btn-group > .btn-group:not(:first-child), [dir=ltr] .btn-group > .btn:not(:first-child) {
    margin-left: -1px
}

[dir=ltr] .btn-group > .btn-group:not(:last-child) > .btn, [dir=ltr] .btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

[dir=ltr] .btn-group > .btn-group:not(:first-child) > .btn, [dir=ltr] .btn-group > .btn:not(:first-child), [dir=rtl] .btn-group > .btn-group:not(:last-child) > .btn, [dir=rtl] .btn-group > .btn:not(:last-child):not(.dropdown-toggle) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

[dir=ltr] .dropdown-toggle-split {
    padding-right: .75rem;
    padding-left: .75rem
}

[dir=ltr] .dropdown-toggle-split:after, [dir=ltr] .dropright .dropdown-toggle-split:after, [dir=ltr] .dropup .dropdown-toggle-split:after {
    margin-left: 0
}

[dir=ltr] .dropleft .dropdown-toggle-split:before {
    margin-right: 0
}

[dir=ltr] .btn-group-sm > .btn + .dropdown-toggle-split, [dir=ltr] .btn-sm + .dropdown-toggle-split {
    padding-right: .375rem;
    padding-left: .375rem
}

[dir=ltr] .btn-group-lg > .btn + .dropdown-toggle-split, [dir=ltr] .btn-lg + .dropdown-toggle-split {
    padding-right: 1.5rem;
    padding-left: 1.5rem
}

[dir] .btn-group.show .dropdown-toggle {
    box-shadow: inset 0 3px 5px rgba(39, 44, 51, .125)
}

[dir] .btn-group.show .dropdown-toggle.btn-link {
    box-shadow: none
}

.btn-group-vertical {
    flex-direction: column;
    align-items: flex-start;
    justify-content: center
}

.btn-group-vertical > .btn, .btn-group-vertical > .btn-group {
    width: 100%
}

[dir] .btn-group-vertical > .btn-group:not(:first-child), [dir] .btn-group-vertical > .btn:not(:first-child) {
    margin-top: -1px
}

[dir=ltr] .btn-group-vertical > .btn-group:not(:last-child) > .btn, [dir=ltr] .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle), [dir=rtl] .btn-group-vertical > .btn-group:not(:last-child) > .btn, [dir=rtl] .btn-group-vertical > .btn:not(:last-child):not(.dropdown-toggle) {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

[dir=ltr] .btn-group-vertical > .btn-group:not(:first-child) > .btn, [dir=ltr] .btn-group-vertical > .btn:not(:first-child), [dir=rtl] .btn-group-vertical > .btn-group:not(:first-child) > .btn, [dir=rtl] .btn-group-vertical > .btn:not(:first-child) {
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

[dir] .btn-group-toggle > .btn, [dir] .btn-group-toggle > .btn-group > .btn {
    margin-bottom: 0
}

.btn-group-toggle > .btn-group > .btn input[type=checkbox], .btn-group-toggle > .btn-group > .btn input[type=radio], .btn-group-toggle > .btn input[type=checkbox], .btn-group-toggle > .btn input[type=radio] {
    position: absolute;
    clip: rect(0, 0, 0, 0);
    pointer-events: none
}

.input-group {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    width: 100%
}

.input-group > .custom-file, .input-group > .custom-select, .input-group > .form-control, .input-group > .form-control-plaintext {
    position: relative;
    flex: 1 1 auto;
    width: 1%;
    min-width: 0
}

[dir] .input-group > .custom-file, [dir] .input-group > .custom-select, [dir] .input-group > .form-control, [dir] .input-group > .form-control-plaintext {
    margin-bottom: 0
}

[dir=ltr] .input-group > .custom-file + .custom-file, [dir=ltr] .input-group > .custom-file + .custom-select, [dir=ltr] .input-group > .custom-file + .form-control, [dir=ltr] .input-group > .custom-select + .custom-file, [dir=ltr] .input-group > .custom-select + .custom-select, [dir=ltr] .input-group > .custom-select + .form-control, [dir=ltr] .input-group > .form-control + .custom-file, [dir=ltr] .input-group > .form-control + .custom-select, [dir=ltr] .input-group > .form-control + .form-control, [dir=ltr] .input-group > .form-control-plaintext + .custom-file, [dir=ltr] .input-group > .form-control-plaintext + .custom-select, [dir=ltr] .input-group > .form-control-plaintext + .form-control {
    margin-left: -1px
}

.input-group > .custom-file .custom-file-input:focus ~ .custom-file-label, .input-group > .custom-select:focus, .input-group > .form-control:focus {
    z-index: 3
}

.input-group > .custom-file .custom-file-input:focus {
    z-index: 4
}

[dir=ltr] .input-group > .custom-select:not(:last-child), [dir=ltr] .input-group > .form-control:not(:last-child) {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

[dir=ltr] .input-group > .custom-select:not(:first-child), [dir=ltr] .input-group > .form-control:not(:first-child), [dir=rtl] .input-group > .custom-select:not(:last-child), [dir=rtl] .input-group > .form-control:not(:last-child) {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.input-group-append, .input-group-prepend {
    display: flex
}

.input-group-append .btn, .input-group-prepend .btn {
    position: relative;
    z-index: 2
}

.input-group-append .btn:focus, .input-group-prepend .btn:focus {
    z-index: 3
}

[dir=ltr] .input-group-append .btn + .btn, [dir=ltr] .input-group-append .btn + .input-group-text, [dir=ltr] .input-group-append .input-group-text + .btn, [dir=ltr] .input-group-append .input-group-text + .input-group-text, [dir=ltr] .input-group-prepend .btn + .btn, [dir=ltr] .input-group-prepend .btn + .input-group-text, [dir=ltr] .input-group-prepend .input-group-text + .btn, [dir=ltr] .input-group-prepend .input-group-text + .input-group-text {
    margin-left: -1px
}

.input-group-text {
    display: flex;
    align-items: center;
    font-size: .8125rem;
    font-weight: 400;
    line-height: 1.5;
    color: #272c33;
    white-space: nowrap
}

[dir] .input-group-text {
    padding: .5rem .75rem;
    margin-bottom: 0;
    text-align: center;
    background-color: #fff;
    border: 1px solid #edf0f2;
    border-radius: .25rem
}

[dir] .input-group-text input[type=checkbox], [dir] .input-group-text input[type=radio] {
    margin-top: 0
}

.input-group-lg > .form-control:not(textarea) {
    height: calc(1.75em + 1rem + 2px)
}

.input-group-lg > .form-control, .input-group-lg > .input-group-append > .btn, .input-group-lg > .input-group-append > .input-group-text, .input-group-lg > .input-group-prepend > .btn, .input-group-lg > .input-group-prepend > .input-group-text {
    font-size: 1.21875rem;
    line-height: 1.75
}

[dir] .input-group-lg > .form-control, [dir] .input-group-lg > .input-group-append > .btn, [dir] .input-group-lg > .input-group-append > .input-group-text, [dir] .input-group-lg > .input-group-prepend > .btn, [dir] .input-group-lg > .input-group-prepend > .input-group-text {
    padding: .5rem 1rem;
    border-radius: .5rem
}

.input-group-sm > .form-control:not(textarea) {
    height: calc(1.5em + .5rem + 2px)
}

.input-group-sm > .form-control, .input-group-sm > .input-group-append > .btn, .input-group-sm > .input-group-append > .input-group-text, .input-group-sm > .input-group-prepend > .btn, .input-group-sm > .input-group-prepend > .input-group-text {
    font-size: .7109375rem;
    line-height: 1.5
}

[dir] .input-group-sm > .form-control, [dir] .input-group-sm > .input-group-append > .btn, [dir] .input-group-sm > .input-group-append > .input-group-text, [dir] .input-group-sm > .input-group-prepend > .btn, [dir] .input-group-sm > .input-group-prepend > .input-group-text {
    padding: .25rem .5rem;
    border-radius: .2rem
}

[dir=ltr] .input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle), [dir=ltr] .input-group > .input-group-append:last-child > .input-group-text:not(:last-child), [dir=ltr] .input-group > .input-group-append:not(:last-child) > .btn, [dir=ltr] .input-group > .input-group-append:not(:last-child) > .input-group-text, [dir=ltr] .input-group > .input-group-prepend > .btn, [dir=ltr] .input-group > .input-group-prepend > .input-group-text {
    border-top-right-radius: 0;
    border-bottom-right-radius: 0
}

[dir=ltr] .input-group > .input-group-append > .btn, [dir=ltr] .input-group > .input-group-append > .input-group-text, [dir=ltr] .input-group > .input-group-prepend:first-child > .btn:not(:first-child), [dir=ltr] .input-group > .input-group-prepend:first-child > .input-group-text:not(:first-child), [dir=ltr] .input-group > .input-group-prepend:not(:first-child) > .btn, [dir=ltr] .input-group > .input-group-prepend:not(:first-child) > .input-group-text, [dir=rtl] .input-group > .input-group-append:last-child > .btn:not(:last-child):not(.dropdown-toggle), [dir=rtl] .input-group > .input-group-append:last-child > .input-group-text:not(:last-child), [dir=rtl] .input-group > .input-group-append:not(:last-child) > .btn, [dir=rtl] .input-group > .input-group-append:not(:last-child) > .input-group-text, [dir=rtl] .input-group > .input-group-prepend > .btn, [dir=rtl] .input-group > .input-group-prepend > .input-group-text {
    border-top-left-radius: 0;
    border-bottom-left-radius: 0
}

.nav {
    display: flex;
    flex-wrap: wrap;
    list-style: none
}

[dir] .nav {
    margin-bottom: 0
}

[dir=ltr] .nav {
    padding-left: 0
}

[dir=rtl] .nav {
    padding-right: 0
}

.nav-link {
    display: block
}

[dir] .nav-link {
    padding: .5rem 1rem
}

.nav-link:focus, .nav-link:hover {
    text-decoration: none
}

.nav-link.disabled {
    color: #868e96;
    pointer-events: none
}

[dir] .nav-link.disabled {
    cursor: default
}

[dir] .nav-tabs {
    border-bottom: 1px solid #e9edf2
}

[dir] .nav-tabs .nav-item {
    margin-bottom: -1px
}

[dir] .nav-tabs .nav-link {
    border: 1px solid transparent
}

[dir=ltr] .nav-tabs .nav-link {
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem;
    background-color: transparent;
}

[dir] .nav-tabs .nav-link:focus, [dir] .nav-tabs .nav-link:hover {
    border-color: transparent transparent #e9edf2
}

.nav-tabs .nav-link.disabled {
    color: #868e96
}

[dir] .nav-tabs .nav-link.disabled {
    background-color: transparent;
    border-color: transparent
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
    color: #272c33
}

[dir] .nav-tabs .nav-item.show .nav-link, [dir] .nav-tabs .nav-link.active {
    background-color: #f5f7fa;
    border-color: transparent transparent #f5f7fa
}

[dir] .nav-tabs .dropdown-menu {
    margin-top: -1px
}

[dir=ltr] .nav-tabs .dropdown-menu {
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

[dir] .nav-pills .nav-link {
    border-radius: .25rem
}

.nav-pills .nav-link.active, .nav-pills .show > .nav-link {
    color: #fff
}

[dir] .nav-pills .nav-link.active, [dir] .nav-pills .show > .nav-link {
    background-color: var(--primary)
}

.nav-fill .nav-item, .nav-fill > .nav-link {
    flex: 1 1 auto
}

[dir] .nav-fill .nav-item, [dir] .nav-fill > .nav-link {
    text-align: center
}

.nav-justified .nav-item, .nav-justified > .nav-link {
    flex-basis: 0;
    flex-grow: 1
}

[dir] .nav-justified .nav-item, [dir] .nav-justified > .nav-link {
    text-align: center
}

.tab-content > .tab-pane {
    display: none
}

.tab-content > .active {
    display: block
}

.navbar {
    position: relative;
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between
}

[dir] .navbar {
    padding: .5rem 1rem
}

.navbar .container, .navbar .container-fluid, .navbar .container-lg, .navbar .container-md, .navbar .container-sm, .navbar .container-xl {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between
}

.navbar-brand {
    display: inline-block;
    font-size: 1.21875rem;
    line-height: inherit;
    white-space: nowrap
}

[dir] .navbar-brand {
    padding-top: .1953125rem;
    padding-bottom: .1953125rem
}

.navbar-brand:focus, .navbar-brand:hover {
    text-decoration: none
}

.navbar-nav {
    display: flex;
    flex-direction: column;
    list-style: none
}

[dir] .navbar-nav {
    margin-bottom: 0
}

[dir=ltr] .navbar-nav {
    padding-left: 0
}

[dir=ltr] .navbar-nav .nav-link {
    padding-right: 0;
    padding-left: 0
}

.navbar-nav .dropdown-menu {
    position: static
}

[dir] .navbar-nav .dropdown-menu {
    float: none
}

.navbar-text {
    display: inline-block
}

[dir] .navbar-text {
    padding-top: .5rem;
    padding-bottom: .5rem
}

.navbar-collapse {
    flex-basis: 100%;
    flex-grow: 1;
    align-items: center
}

.navbar-toggler {
    font-size: 1.21875rem;
    line-height: 1
}

[dir] .navbar-toggler {
    padding: .25rem .75rem;
    background-color: transparent;
    border: 1px solid transparent;
    border-radius: .25rem
}

.navbar-toggler:focus, .navbar-toggler:hover {
    text-decoration: none
}

.navbar-toggler-icon {
    display: inline-block;
    width: 1.5em;
    height: 1.5em;
    vertical-align: middle;
    content: ""
}

[dir] .navbar-toggler-icon {
    background: no-repeat 50%;
    background-size: 100% 100%
}

@media (max-width: 575.98px) {
    [dir=ltr] .navbar-expand-sm > .container, [dir=ltr] .navbar-expand-sm > .container-fluid, [dir=ltr] .navbar-expand-sm > .container-lg, [dir=ltr] .navbar-expand-sm > .container-md, [dir=ltr] .navbar-expand-sm > .container-sm, [dir=ltr] .navbar-expand-sm > .container-xl, [dir=rtl] .navbar-expand-sm > .container, [dir=rtl] .navbar-expand-sm > .container-fluid, [dir=rtl] .navbar-expand-sm > .container-lg, [dir=rtl] .navbar-expand-sm > .container-md, [dir=rtl] .navbar-expand-sm > .container-sm, [dir=rtl] .navbar-expand-sm > .container-xl {
        padding-right: 0;
        padding-left: 0
    }
}

@media (min-width: 576px) {
    .navbar-expand-sm {
        flex-flow: row nowrap;
        justify-content: flex-start
    }

    .navbar-expand-sm .navbar-nav {
        flex-direction: row
    }

    .navbar-expand-sm .navbar-nav .dropdown-menu {
        position: absolute
    }

    [dir=ltr] .navbar-expand-sm .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }

    .navbar-expand-sm > .container, .navbar-expand-sm > .container-fluid, .navbar-expand-sm > .container-lg, .navbar-expand-sm > .container-md, .navbar-expand-sm > .container-sm, .navbar-expand-sm > .container-xl {
        flex-wrap: nowrap
    }

    .navbar-expand-sm .navbar-collapse {
        display: flex !important;
        flex-basis: auto
    }

    .navbar-expand-sm .navbar-toggler {
        display: none
    }
}

@media (max-width: 767.98px) {
    [dir=ltr] .navbar-expand-md > .container, [dir=ltr] .navbar-expand-md > .container-fluid, [dir=ltr] .navbar-expand-md > .container-lg, [dir=ltr] .navbar-expand-md > .container-md, [dir=ltr] .navbar-expand-md > .container-sm, [dir=ltr] .navbar-expand-md > .container-xl {
        padding-right: 0;
        padding-left: 0
    }
}

@media (min-width: 768px) {
    .navbar-expand-md {
        flex-flow: row nowrap;
        justify-content: flex-start
    }

    .navbar-expand-md .navbar-nav {
        flex-direction: row
    }

    .navbar-expand-md .navbar-nav .dropdown-menu {
        position: absolute
    }

    [dir=ltr] .navbar-expand-md .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }

    .navbar-expand-md > .container, .navbar-expand-md > .container-fluid, .navbar-expand-md > .container-lg, .navbar-expand-md > .container-md, .navbar-expand-md > .container-sm, .navbar-expand-md > .container-xl {
        flex-wrap: nowrap
    }

    .navbar-expand-md .navbar-collapse {
        display: flex !important;
        flex-basis: auto
    }

    .navbar-expand-md .navbar-toggler {
        display: none
    }
}

@media (max-width: 991.98px) {
    [dir=ltr] .navbar-expand-lg > .container, [dir=ltr] .navbar-expand-lg > .container-fluid, [dir=ltr] .navbar-expand-lg > .container-lg, [dir=ltr] .navbar-expand-lg > .container-md, [dir=ltr] .navbar-expand-lg > .container-sm, [dir=ltr] .navbar-expand-lg > .container-xl {
        padding-right: 0;
        padding-left: 0
    }
}

@media (min-width: 992px) {
    .navbar-expand-lg {
        flex-flow: row nowrap;
        justify-content: flex-start
    }

    .navbar-expand-lg .navbar-nav {
        flex-direction: row
    }

    .navbar-expand-lg .navbar-nav .dropdown-menu {
        position: absolute
    }

    [dir=ltr] .navbar-expand-lg .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }

    .navbar-expand-lg > .container, .navbar-expand-lg > .container-fluid, .navbar-expand-lg > .container-lg, .navbar-expand-lg > .container-md, .navbar-expand-lg > .container-sm, .navbar-expand-lg > .container-xl {
        flex-wrap: nowrap
    }

    .navbar-expand-lg .navbar-collapse {
        display: flex !important;
        flex-basis: auto
    }

    .navbar-expand-lg .navbar-toggler {
        display: none
    }
}

@media (max-width: 1199.98px) {
    [dir=ltr] .navbar-expand-xl > .container, [dir=ltr] .navbar-expand-xl > .container-fluid, [dir=ltr] .navbar-expand-xl > .container-lg, [dir=ltr] .navbar-expand-xl > .container-md, [dir=ltr] .navbar-expand-xl > .container-sm, [dir=ltr] .navbar-expand-xl > .container-xl {
        padding-right: 0;
        padding-left: 0
    }
}

@media (min-width: 1200px) {
    .navbar-expand-xl {
        flex-flow: row nowrap;
        justify-content: flex-start
    }

    .navbar-expand-xl .navbar-nav {
        flex-direction: row
    }

    .navbar-expand-xl .navbar-nav .dropdown-menu {
        position: absolute
    }

    [dir=ltr] .navbar-expand-xl .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }

    .navbar-expand-xl > .container, .navbar-expand-xl > .container-fluid, .navbar-expand-xl > .container-lg, .navbar-expand-xl > .container-md, .navbar-expand-xl > .container-sm, .navbar-expand-xl > .container-xl {
        flex-wrap: nowrap
    }

    .navbar-expand-xl .navbar-collapse {
        display: flex !important;
        flex-basis: auto
    }

    .navbar-expand-xl .navbar-toggler {
        display: none
    }
}

@media (max-width: 1365.98px) {
    [dir=ltr] .navbar-expand-xxl > .container, [dir=ltr] .navbar-expand-xxl > .container-fluid, [dir=ltr] .navbar-expand-xxl > .container-lg, [dir=ltr] .navbar-expand-xxl > .container-md, [dir=ltr] .navbar-expand-xxl > .container-sm, [dir=ltr] .navbar-expand-xxl > .container-xl, [dir=rtl] .navbar-expand-xxl > .container, [dir=rtl] .navbar-expand-xxl > .container-fluid, [dir=rtl] .navbar-expand-xxl > .container-lg, [dir=rtl] .navbar-expand-xxl > .container-md, [dir=rtl] .navbar-expand-xxl > .container-sm, [dir=rtl] .navbar-expand-xxl > .container-xl {
        padding-right: 0;
        padding-left: 0
    }
}

@media (min-width: 1366px) {
    .navbar-expand-xxl {
        flex-flow: row nowrap;
        justify-content: flex-start
    }

    .navbar-expand-xxl .navbar-nav {
        flex-direction: row
    }

    .navbar-expand-xxl .navbar-nav .dropdown-menu {
        position: absolute
    }

    [dir=ltr] .navbar-expand-xxl .navbar-nav .nav-link {
        padding-right: .5rem;
        padding-left: .5rem
    }

    .navbar-expand-xxl > .container, .navbar-expand-xxl > .container-fluid, .navbar-expand-xxl > .container-lg, .navbar-expand-xxl > .container-md, .navbar-expand-xxl > .container-sm, .navbar-expand-xxl > .container-xl {
        flex-wrap: nowrap
    }

    .navbar-expand-xxl .navbar-collapse {
        display: flex !important;
        flex-basis: auto
    }

    .navbar-expand-xxl .navbar-toggler {
        display: none
    }
}

.navbar-expand {
    flex-flow: row nowrap;
    justify-content: flex-start
}

[dir=ltr] .navbar-expand > .container, [dir=ltr] .navbar-expand > .container-fluid, [dir=ltr] .navbar-expand > .container-lg, [dir=ltr] .navbar-expand > .container-md, [dir=ltr] .navbar-expand > .container-sm, [dir=ltr] .navbar-expand > .container-xl {
    padding-right: 0;
    padding-left: 0
}

.navbar-expand .navbar-nav {
    flex-direction: row
}

.navbar-expand .navbar-nav .dropdown-menu {
    position: absolute
}

[dir=ltr] .navbar-expand .navbar-nav .nav-link {
    padding-right: .5rem;
    padding-left: .5rem
}

.navbar-expand > .container, .navbar-expand > .container-fluid, .navbar-expand > .container-lg, .navbar-expand > .container-md, .navbar-expand > .container-sm, .navbar-expand > .container-xl {
    flex-wrap: nowrap
}

.navbar-expand .navbar-collapse {
    display: flex !important;
    flex-basis: auto
}

.navbar-expand .navbar-toggler {
    display: none
}

.navbar-light .navbar-brand, .navbar-light .navbar-brand:focus, .navbar-light .navbar-brand:hover {
    color: rgba(39, 44, 51, .9)
}

.navbar-light .navbar-nav .nav-link {
    color: rgba(39, 44, 51, .5)
}

.navbar-light .navbar-nav .nav-link:focus, .navbar-light .navbar-nav .nav-link:hover {
    color: rgba(39, 44, 51, .7)
}

.card, .card-nav .tab-content {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word
}

[dir] .card, [dir] .card-nav .tab-content {
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid #dfe2e6;
    border-radius: .5rem
}

[dir=ltr] .card-nav .tab-content > hr, [dir=ltr] .card > hr {
    margin-right: 0;
    margin-left: 0
}

[dir] .card-nav .tab-content > .list-group, [dir] .card > .list-group {
    border-top: inherit;
    border-bottom: inherit
}

[dir] .card-nav .tab-content > .list-group:first-child, [dir] .card > .list-group:first-child {
    border-top-width: 0
}

[dir=ltr] .card-nav .tab-content > .list-group:first-child, [dir=ltr] .card > .list-group:first-child {
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem
}

[dir] .card-nav .tab-content > .list-group:last-child, [dir] .card > .list-group:last-child {
    border-bottom-width: 0
}

[dir=ltr] .card-nav .tab-content > .list-group:last-child, [dir=ltr] .card > .list-group:last-child {
    border-bottom-right-radius: .5rem;
    border-bottom-left-radius: .5rem
}

[dir] .card-nav .tab-content > .card-header + .list-group, [dir] .card-nav .tab-content > .list-group + .card-footer, [dir] .card > .card-header + .list-group, [dir] .card > .list-group + .card-footer {
    border-top: 0
}

.card-body {
    flex: 1 1 auto;
    min-height: 1px
}

[dir] .card-body {
    padding: 1rem
}

[dir] .card-title {
    margin-bottom: 1rem
}

[dir] .card-subtitle {
    margin-top: -.5rem;
    margin-bottom: 0
}

[dir] .card-text:last-child {
    margin-bottom: 0
}

.card-link:hover {
    text-decoration: none
}

[dir=ltr] .card-link + .card-link {
    margin-left: 1rem
}

[dir] .card-header {
    padding: 1rem;
    margin-bottom: 0;
    background-color: #fff
}

[dir] .card-header:first-child {
    border-radius: .5rem .5rem 0 0
}

[dir] .card-footer {
    padding: 1rem;
    background-color: #fff
}

[dir] .card-footer:last-child {
    border-radius: 0 0 .5rem .5rem
}

[dir] .card-header-tabs {
    margin-bottom: -1rem;
    border-bottom: 0
}

[dir=ltr] .card-header-pills, [dir=ltr] .card-header-tabs {
    margin-right: -.5rem;
    margin-left: -.5rem
}

.card-img-overlay {
    position: absolute;
    top: 0;
    bottom: 0
}

[dir] .card-img-overlay {
    padding: 1.25rem;
    border-radius: .5rem
}

[dir=ltr] .card-img-overlay {
    right: 0;
    left: 0
}

.card-img, .card-img-bottom, .card-img-top {
    flex-shrink: 0;
    width: 100%
}

[dir=ltr] .card-img, [dir=ltr] .card-img-top {
    border-top-left-radius: .5rem;
    border-top-right-radius: .5rem
}

[dir=ltr] .card-img, [dir=ltr] .card-img-bottom {
    border-bottom-right-radius: .5rem;
    border-bottom-left-radius: .5rem
}

[dir] .card-deck .card, [dir] .card-deck .card-nav .tab-content, [dir] .card-nav .card-deck .tab-content {
    margin-bottom: 12px
}

@media (min-width: 576px) {
    .card-deck {
        display: flex;
        flex-flow: row wrap
    }

    [dir=ltr] .card-deck {
        margin-right: -12px;
        margin-left: -12px
    }

    .card-deck .card, .card-deck .card-nav .tab-content, .card-nav .card-deck .tab-content {
        flex: 1 0 0%
    }

    [dir] .card-deck .card, [dir] .card-deck .card-nav .tab-content, [dir] .card-nav .card-deck .tab-content {
        margin-bottom: 0
    }

    [dir=ltr] .card-deck .card, [dir=ltr] .card-deck .card-nav .tab-content, [dir=ltr] .card-nav .card-deck .tab-content, [dir=rtl] .card-deck .card, [dir=rtl] .card-deck .card-nav .tab-content, [dir=rtl] .card-nav .card-deck .tab-content {
        margin-right: 12px;
        margin-left: 12px
    }
}

[dir] .card-group > .card, [dir] .card-nav .card-group > .tab-content {
    margin-bottom: 12px
}

@media (min-width: 576px) {
    .card-group {
        display: flex;
        flex-flow: row wrap
    }

    .card-group > .card, .card-nav .card-group > .tab-content {
        flex: 1 0 0%
    }

    [dir] .card-group > .card, [dir] .card-nav .card-group > .tab-content {
        margin-bottom: 0
    }

    [dir=ltr] .card-group > .card + .card, [dir=ltr] .card-nav .card-group > .card + .tab-content, [dir=ltr] .card-nav .card-group > .tab-content + .card, [dir=ltr] .card-nav .card-group > .tab-content + .tab-content {
        margin-left: 0;
        border-left: 0
    }

    [dir=ltr] .card-group > .card:not(:last-child), [dir=ltr] .card-nav .card-group > .tab-content:not(:last-child) {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0
    }

    [dir=ltr] .card-group > .card:not(:last-child) .card-header, [dir=ltr] .card-group > .card:not(:last-child) .card-img-top, [dir=ltr] .card-nav .card-group > .tab-content:not(:last-child) .card-header, [dir=ltr] .card-nav .card-group > .tab-content:not(:last-child) .card-img-top {
        border-top-right-radius: 0
    }

    [dir=ltr] .card-group > .card:not(:last-child) .card-footer, [dir=ltr] .card-group > .card:not(:last-child) .card-img-bottom, [dir=ltr] .card-nav .card-group > .tab-content:not(:last-child) .card-footer, [dir=ltr] .card-nav .card-group > .tab-content:not(:last-child) .card-img-bottom {
        border-bottom-right-radius: 0
    }

    [dir=ltr] .card-group > .card:not(:first-child), [dir=ltr] .card-nav .card-group > .tab-content:not(:first-child) {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0
    }

    [dir=ltr] .card-group > .card:not(:first-child) .card-header, [dir=ltr] .card-group > .card:not(:first-child) .card-img-top, [dir=ltr] .card-nav .card-group > .tab-content:not(:first-child) .card-header, [dir=ltr] .card-nav .card-group > .tab-content:not(:first-child) .card-img-top {
        border-top-left-radius: 0
    }

    [dir=ltr] .card-group > .card:not(:first-child) .card-footer, [dir=ltr] .card-group > .card:not(:first-child) .card-img-bottom, [dir=ltr] .card-nav .card-group > .tab-content:not(:first-child) .card-footer, [dir=ltr] .card-nav .card-group > .tab-content:not(:first-child) .card-img-bottom {
        border-bottom-left-radius: 0
    }
}

[dir] .card-columns .card, [dir] .card-columns .card-nav .tab-content, [dir] .card-nav .card-columns .tab-content {
    margin-bottom: 1rem
}

@media (min-width: 576px) {
    .card-columns {
        -moz-column-count: 3;
        column-count: 3;
        -moz-column-gap: 24px;
        column-gap: 24px;
        orphans: 1;
        widows: 1
    }

    .card-columns .card, .card-columns .card-nav .tab-content, .card-nav .card-columns .tab-content {
        display: inline-block;
        width: 100%
    }
}

.accordion {
    overflow-anchor: none
}

.accordion > .card, .card-nav .accordion > .tab-content {
    overflow: hidden
}

[dir] .accordion > .card:not(:last-of-type), [dir] .card-nav .accordion > .tab-content:not(:last-of-type) {
    border-bottom: 0
}

[dir=ltr] .accordion > .card:not(:last-of-type), [dir=ltr] .card-nav .accordion > .tab-content:not(:last-of-type), [dir=rtl] .accordion > .card:not(:last-of-type), [dir=rtl] .card-nav .accordion > .tab-content:not(:last-of-type) {
    border-bottom-right-radius: 0;
    border-bottom-left-radius: 0
}

[dir=ltr] .accordion > .card:not(:first-of-type), [dir=ltr] .card-nav .accordion > .tab-content:not(:first-of-type), [dir=rtl] .accordion > .card:not(:first-of-type), [dir=rtl] .card-nav .accordion > .tab-content:not(:first-of-type) {
    border-top-left-radius: 0;
    border-top-right-radius: 0
}

[dir] .accordion > .card > .card-header, [dir] .card-nav .accordion > .tab-content > .card-header {
    border-radius: 0;
    margin-bottom: -1px
}

.breadcrumb {
    display: flex;
    flex-wrap: wrap;
    list-style: none
}

[dir] .breadcrumb {
    padding: 0;
    margin-bottom: 1rem;
    background-color: transparent;
    border-radius: .25rem
}

[dir=ltr] .breadcrumb-item + .breadcrumb-item {
    padding-left: 0
}

.breadcrumb-item + .breadcrumb-item:before {
    color: rgba(39, 44, 51, .5);
    content: "/"
}

[dir=ltr] .breadcrumb-item + .breadcrumb-item:before {
    padding-right: 0
}

.breadcrumb-item + .breadcrumb-item:hover:before {
    text-decoration: underline;
    text-decoration: none
}

.breadcrumb-item.active {
    color: rgba(39, 44, 51, .35)
}

.pagination {
    display: flex;
    list-style: none
}

[dir] .pagination {
    border-radius: .25rem
}

[dir=ltr] .pagination {
    padding-left: 0
}

.page-link {
    position: relative;
    display: block;
    line-height: 1.25;
    color: rgba(39, 44, 51, .7)
}

[dir] .page-link {
    padding: .5rem .75rem;
    background-color: transparent;
    border: 1px solid #dee2e6
}

[dir=ltr] .page-link {
    margin-left: -1px
}

.page-link:hover {
    z-index: 2;
    color: rgba(6, 7, 8, .7);
    text-decoration: none
}

[dir] .page-link:hover {
    background-color: #e9ecef;
    border-color: #dee2e6
}

.page-link:focus {
    z-index: 3;
    outline: 0
}

[dir] .page-link:focus {
    box-shadow: 0 0 0 1px var(--primary)
}

[dir=ltr] .page-item:first-child .page-link {
    margin-left: 0;
    border-top-left-radius: .25rem;
    border-bottom-left-radius: .25rem
}

[dir=ltr] .page-item:last-child .page-link {
    border-top-right-radius: .25rem;
    border-bottom-right-radius: .25rem
}

.page-item.active .page-link {
    z-index: 3;
    color: #fff
}

[dir] .page-item.active .page-link {
    background-color: var(--primary);
    border-color: var(--primary)
}

.page-item.disabled .page-link {
    color: #cfcfcf;
    pointer-events: none
}

[dir] .page-item.disabled .page-link {
    cursor: auto;
    background-color: transparent;
    border-color: #dee2e6
}

.pagination-lg .page-link {
    font-size: 1.21875rem;
    line-height: 1.5
}

[dir] .pagination-lg .page-link {
    padding: .75rem 1.5rem
}

[dir=ltr] .pagination-lg .page-item:first-child .page-link {
    border-top-left-radius: .5rem;
    border-bottom-left-radius: .5rem
}

[dir=ltr] .pagination-lg .page-item:last-child .page-link {
    border-top-right-radius: .5rem;
    border-bottom-right-radius: .5rem
}

.pagination-sm .page-link {
    font-size: .7109375rem;
    line-height: 1.5
}

[dir] .pagination-sm .page-link {
    padding: .25rem .5rem
}

[dir=ltr] .pagination-sm .page-item:first-child .page-link {
    border-top-left-radius: .2rem;
    border-bottom-left-radius: .2rem
}

[dir=ltr] .pagination-sm .page-item:last-child .page-link {
    border-top-right-radius: .2rem;
    border-bottom-right-radius: .2rem
}

.badge {
    display: inline-block;
    font-size: 75%;
    font-weight: 500;
    line-height: 1;
    white-space: nowrap;
    vertical-align: baseline;
    transition: color .15s ease-in-out, background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

[dir] .badge {
    padding: .25em .4em;
    text-align: center;
    border-radius: .25rem
}

@media (prefers-reduced-motion: reduce) {
    .badge {
        transition: none
    }
}

a.badge:focus, a.badge:hover {
    text-decoration: none
}

.badge:empty {
    display: none
}

[dir] .badge-pill {
    border-radius: 10rem
}

[dir=ltr] .badge-pill, [dir=rtl] .badge-pill {
    padding-right: .6em;
    padding-left: .6em
}

.badge-success {
    color: #212529
}

[dir] .badge-success {
    background-color: #77c13a
}

a.badge-success:focus, a.badge-success:hover {
    color: #212529
}

[dir] a.badge-success:focus, [dir] a.badge-success:hover {
    background-color: #5f9a2e
}

a.badge-success.focus, a.badge-success:focus {
    outline: 0
}

[dir] a.badge-success.focus, [dir] a.badge-success:focus {
    box-shadow: 0 0 0 1px rgba(119, 193, 58, .5)
}

.badge-info {
    color: #fff
}

[dir] .badge-info {
    background-color: #17a2b8
}

a.badge-info:focus, a.badge-info:hover {
    color: #fff
}

[dir] a.badge-info:focus, [dir] a.badge-info:hover {
    background-color: #117a8b
}

a.badge-info.focus, a.badge-info:focus {
    outline: 0
}

[dir] a.badge-info.focus, [dir] a.badge-info:focus {
    box-shadow: 0 0 0 1px rgba(23, 162, 184, .5)
}

.badge-warning {
    color: #212529
}

[dir] .badge-warning {
    background-color: #e4a93c
}

a.badge-warning:focus, a.badge-warning:hover {
    color: #212529
}

[dir] a.badge-warning:focus, [dir] a.badge-warning:hover {
    background-color: #d0911d
}

a.badge-warning.focus, a.badge-warning:focus {
    outline: 0
}

[dir] a.badge-warning.focus, [dir] a.badge-warning:focus {
    box-shadow: 0 0 0 1px rgba(228, 169, 60, .5)
}

.badge-danger {
    color: #fff
}

[dir] .badge-danger {
    background-color: #d9534f
}

a.badge-danger:focus, a.badge-danger:hover {
    color: #fff
}

[dir] a.badge-danger:focus, [dir] a.badge-danger:hover {
    background-color: #c9302c
}

a.badge-danger.focus, a.badge-danger:focus {
    outline: 0
}

[dir] a.badge-danger.focus, [dir] a.badge-danger:focus {
    box-shadow: 0 0 0 1px rgba(217, 83, 79, .5)
}

.badge-light {
    color: #212529
}

[dir] .badge-light {
    background-color: #f8f9fa
}

a.badge-light:focus, a.badge-light:hover {
    color: #212529
}

[dir] a.badge-light:focus, [dir] a.badge-light:hover {
    background-color: #dae0e5
}

a.badge-light.focus, a.badge-light:focus {
    outline: 0
}

[dir] a.badge-light.focus, [dir] a.badge-light:focus {
    box-shadow: 0 0 0 1px rgba(248, 249, 250, .5)
}

.badge-accent-white {
    color: #fff;
    background-color: #999999;
}

[dir] .badge-accent {
    background-color: #ed0b4c
}

a.badge-accent:focus, a.badge-accent:hover {
    color: #fff
}

[dir] a.badge-accent:focus, [dir] a.badge-accent:hover {
    background-color: #bc093c
}

a.badge-accent.focus, a.badge-accent:focus {
    outline: 0
}

[dir] a.badge-accent.focus, [dir] a.badge-accent:focus {
    box-shadow: 0 0 0 1px rgba(237, 11, 76, .5)
}

.badge-accent-red {
    color: #fff
}

[dir] .badge-accent-red {
    background-color: #ed0b4c
}

a.badge-accent-red:focus, a.badge-accent-red:hover {
    color: #fff
}

[dir] a.badge-accent-red:focus, [dir] a.badge-accent-red:hover {
    background-color: #bc093c
}

a.badge-accent-red.focus, a.badge-accent-red:focus {
    outline: 0
}

[dir] a.badge-accent-red.focus, [dir] a.badge-accent-red:focus {
    box-shadow: 0 0 0 1px rgba(237, 11, 76, .5)
}

.alert {
    position: relative
}

[dir] .alert {
    padding: .5rem;
    margin-bottom: 1rem;
    border: 1px solid transparent;
    border-radius: .25rem
}

.alert-heading {
    color: inherit
}

.alert-link {
    font-weight: 500
}

[dir=ltr] .alert-dismissible {
    padding-right: 2.21875rem
}

.alert-dismissible .close {
    position: absolute;
    top: 0;
    z-index: 2;
    color: inherit
}

[dir] .alert-dismissible .close {
    padding: .5rem
}

[dir=ltr] .alert-dismissible .close {
    right: 0
}

.alert-success {
    color: #517937
}

[dir] .alert-success {
    background-color: #e4f3d8;
    border-color: #d9eec8
}

[dir] .alert-success hr {
    border-top-color: #cce8b5
}

.alert-success .alert-link {
    color: #3a5627
}

.alert-info {
    color: #1f6978
}

[dir] .alert-info {
    background-color: #d1ecf1;
    border-color: #bee5eb
}

[dir] .alert-info hr {
    border-top-color: #abdde5
}

.alert-info .alert-link {
    color: #15464f
}

.alert-warning {
    color: #896d38
}

[dir] .alert-warning {
    background-color: #faeed8;
    border-color: #f7e7c8
}

[dir] .alert-warning hr {
    border-top-color: #f4ddb2
}

.alert-warning .alert-link {
    color: #655029
}

.alert-danger {
    color: #844042
}

[dir] .alert-danger {
    background-color: #f7dddc;
    border-color: #f4cfce
}

[dir] .alert-danger hr {
    border-top-color: #efbbb9
}

.alert-danger .alert-link {
    color: #622f31
}

@-webkit-keyframes progress-bar-stripes-ltr {
    0% {
        background-position: 1rem 0
    }
    to {
        background-position: 0 0
    }
}

@keyframes progress-bar-stripes-ltr {
    0% {
        background-position: 1rem 0
    }
    to {
        background-position: 0 0
    }
}

@-webkit-keyframes progress-bar-stripes-rtl {
    0% {
        background-position: 1rem 0
    }
    to {
        background-position: 100% 0
    }
}

@keyframes progress-bar-stripes-rtl {
    0% {
        background-position: 1rem 0
    }
    to {
        background-position: 100% 0
    }
}

.media {
    align-items: flex-start
}

.media-body {
    flex: 1
}

.list-group {
    display: flex;
    flex-direction: column
}

[dir] .list-group {
    margin-bottom: 0
}

[dir=ltr] .list-group {
    padding-left: 0
}

.list-group-item-action {
    width: 100%;
    color: #495057
}

[dir] .list-group-item-action {
    text-align: inherit
}

.list-group-item-action:focus, .list-group-item-action:hover {
    z-index: 1;
    color: #495057;
    text-decoration: none
}

[dir] .list-group-item-action:focus, [dir] .list-group-item-action:hover {
    background-color: #f8f9fa
}

.list-group-item-action:active {
    color: #272c33
}

[dir] .list-group-item-action:active {
    background-color: #e9ecef
}

.list-group-item {
    position: relative;
    display: block
}

[dir] .list-group-item {
    padding: .75rem 1.5rem;
    background-color: #fff;
    border: 1px solid #e9edf2
}

[dir=ltr] .list-group-item:first-child {
    border-top-left-radius: inherit;
    border-top-right-radius: inherit
}

[dir=ltr] .list-group-item:last-child {
    border-bottom-right-radius: inherit;
    border-bottom-left-radius: inherit
}

.list-group-item.disabled, .list-group-item:disabled {
    color: #868e96;
    pointer-events: none
}

[dir] .list-group-item.disabled, [dir] .list-group-item:disabled {
    background-color: #fff
}

.list-group-item.active {
    z-index: 2;
    color: #272c33
}

[dir] .list-group-item.active {
    background-color: transparent;
    border-color: transparent
}

[dir] .list-group-item + .list-group-item {
    border-top-width: 0
}

[dir] .list-group-item + .list-group-item.active {
    margin-top: -1px;
    border-top-width: 1px
}

.list-group-horizontal {
    flex-direction: row
}

[dir=ltr] .list-group-horizontal > .list-group-item:first-child {
    border-bottom-left-radius: .25rem;
    border-top-right-radius: 0
}

[dir=ltr] .list-group-horizontal > .list-group-item:last-child {
    border-top-right-radius: .25rem;
    border-bottom-left-radius: 0
}

[dir] .list-group-horizontal > .list-group-item.active {
    margin-top: 0
}

[dir] .list-group-horizontal > .list-group-item + .list-group-item {
    border-top-width: 1px
}

[dir=ltr] .list-group-horizontal > .list-group-item + .list-group-item {
    border-left-width: 0
}

[dir=ltr] .list-group-horizontal > .list-group-item + .list-group-item.active {
    margin-left: -1px;
    border-left-width: 1px
}

@media (min-width: 576px) {
    .list-group-horizontal-sm {
        flex-direction: row
    }

    [dir=ltr] .list-group-horizontal-sm > .list-group-item:first-child {
        border-bottom-left-radius: .25rem;
        border-top-right-radius: 0
    }

    [dir=ltr] .list-group-horizontal-sm > .list-group-item:last-child {
        border-top-right-radius: .25rem;
        border-bottom-left-radius: 0
    }

    [dir] .list-group-horizontal-sm > .list-group-item.active {
        margin-top: 0
    }

    [dir] .list-group-horizontal-sm > .list-group-item + .list-group-item {
        border-top-width: 1px
    }

    [dir=ltr] .list-group-horizontal-sm > .list-group-item + .list-group-item {
        border-left-width: 0
    }

    [dir=ltr] .list-group-horizontal-sm > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px
    }
}

@media (min-width: 768px) {
    .list-group-horizontal-md {
        flex-direction: row
    }

    [dir=ltr] .list-group-horizontal-md > .list-group-item:first-child {
        border-bottom-left-radius: .25rem;
        border-top-right-radius: 0
    }

    [dir=ltr] .list-group-horizontal-md > .list-group-item:last-child {
        border-top-right-radius: .25rem;
        border-bottom-left-radius: 0
    }

    [dir] .list-group-horizontal-md > .list-group-item.active {
        margin-top: 0
    }

    [dir] .list-group-horizontal-md > .list-group-item + .list-group-item {
        border-top-width: 1px
    }

    [dir=ltr] .list-group-horizontal-md > .list-group-item + .list-group-item {
        border-left-width: 0
    }

    [dir=ltr] .list-group-horizontal-md > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px
    }
}

@media (min-width: 992px) {
    .list-group-horizontal-lg {
        flex-direction: row
    }

    [dir=ltr] .list-group-horizontal-lg > .list-group-item:first-child {
        border-bottom-left-radius: .25rem;
        border-top-right-radius: 0
    }

    [dir=ltr] .list-group-horizontal-lg > .list-group-item:last-child {
        border-top-right-radius: .25rem;
        border-bottom-left-radius: 0
    }

    [dir] .list-group-horizontal-lg > .list-group-item.active {
        margin-top: 0
    }

    [dir] .list-group-horizontal-lg > .list-group-item + .list-group-item {
        border-top-width: 1px
    }

    [dir=ltr] .list-group-horizontal-lg > .list-group-item + .list-group-item {
        border-left-width: 0
    }

    [dir=ltr] .list-group-horizontal-lg > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px
    }
}

@media (min-width: 1200px) {
    .list-group-horizontal-xl {
        flex-direction: row
    }

    [dir=ltr] .list-group-horizontal-xl > .list-group-item:first-child {
        border-bottom-left-radius: .25rem;
        border-top-right-radius: 0
    }

    [dir=ltr] .list-group-horizontal-xl > .list-group-item:last-child {
        border-top-right-radius: .25rem;
        border-bottom-left-radius: 0
    }

    [dir] .list-group-horizontal-xl > .list-group-item.active {
        margin-top: 0
    }

    [dir] .list-group-horizontal-xl > .list-group-item + .list-group-item {
        border-top-width: 1px
    }

    [dir=ltr] .list-group-horizontal-xl > .list-group-item + .list-group-item {
        border-left-width: 0
    }

    [dir=ltr] .list-group-horizontal-xl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px
    }
}

@media (min-width: 1366px) {
    .list-group-horizontal-xxl {
        flex-direction: row
    }

    [dir=ltr] .list-group-horizontal-xxl > .list-group-item:first-child {
        border-bottom-left-radius: .25rem;
        border-top-right-radius: 0
    }

    [dir=ltr] .list-group-horizontal-xxl > .list-group-item:last-child {
        border-top-right-radius: .25rem;
        border-bottom-left-radius: 0
    }

    [dir] .list-group-horizontal-xxl > .list-group-item.active {
        margin-top: 0
    }

    [dir] .list-group-horizontal-xxl > .list-group-item + .list-group-item {
        border-top-width: 1px
    }

    [dir=ltr] .list-group-horizontal-xxl > .list-group-item + .list-group-item {
        border-left-width: 0
    }

    [dir=ltr] .list-group-horizontal-xxl > .list-group-item + .list-group-item.active {
        margin-left: -1px;
        border-left-width: 1px
    }
}

[dir] .list-group-flush > .list-group-item {
    border-width: 0 0 1px
}

[dir] .list-group-flush > .list-group-item:last-child {
    border-bottom-width: 0
}

.list-group-item-success {
    color: #517937
}

[dir] .list-group-item-success {
    background-color: #d9eec8
}

.list-group-item-success.list-group-item-action:focus, .list-group-item-success.list-group-item-action:hover {
    color: #517937
}

[dir] .list-group-item-success.list-group-item-action:focus, [dir] .list-group-item-success.list-group-item-action:hover {
    background-color: #cce8b5
}

.list-group-item-success.list-group-item-action.active {
    color: #fff
}

[dir] .list-group-item-success.list-group-item-action.active {
    background-color: #517937;
    border-color: #517937
}

.list-group-item-info {
    color: #1f6978
}

[dir] .list-group-item-info {
    background-color: #bee5eb
}

.list-group-item-info.list-group-item-action:focus, .list-group-item-info.list-group-item-action:hover {
    color: #1f6978
}

[dir] .list-group-item-info.list-group-item-action:focus, [dir] .list-group-item-info.list-group-item-action:hover {
    background-color: #abdde5
}

.list-group-item-info.list-group-item-action.active {
    color: #fff
}

[dir] .list-group-item-info.list-group-item-action.active {
    background-color: #1f6978;
    border-color: #1f6978
}

.list-group-item-warning {
    color: #896d38
}

[dir] .list-group-item-warning {
    background-color: #f7e7c8
}

.list-group-item-warning.list-group-item-action:focus, .list-group-item-warning.list-group-item-action:hover {
    color: #896d38
}

[dir] .list-group-item-warning.list-group-item-action:focus, [dir] .list-group-item-warning.list-group-item-action:hover {
    background-color: #f4ddb2
}

.list-group-item-warning.list-group-item-action.active {
    color: #fff
}

[dir] .list-group-item-warning.list-group-item-action.active {
    background-color: #896d38;
    border-color: #896d38
}

.list-group-item-danger {
    color: #844042
}

[dir] .list-group-item-danger {
    background-color: #f4cfce
}

.list-group-item-danger.list-group-item-action:focus, .list-group-item-danger.list-group-item-action:hover {
    color: #844042
}

[dir] .list-group-item-danger.list-group-item-action:focus, [dir] .list-group-item-danger.list-group-item-action:hover {
    background-color: #efbbb9
}

.list-group-item-danger.list-group-item-action.active {
    color: #fff
}

[dir] .list-group-item-danger.list-group-item-action.active {
    background-color: #844042;
    border-color: #844042
}

.close {
    font-size: 1.21875rem;
    font-weight: 500;
    line-height: 1;
    color: #272c33;
    opacity: .5
}

[dir] .close {
    text-shadow: 0 1px 0 #fff
}

.btn-close {
    box-sizing: content-box;
    width: 1em;
    height: 1em;
    padding: 0.25em;
    color: #000;
    background: transparent url("data:image/svg+xml;charset=utf-8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23000'%3E%3Cpath d='M.293.293a1 1 0 0 1 1.414 0L8 6.586 14.293.293a1 1 0 1 1 1.414 1.414L9.414 8l6.293 6.293a1 1 0 0 1-1.414 1.414L8 9.414l-6.293 6.293a1 1 0 0 1-1.414-1.414L6.586 8 .293 1.707a1 1 0 0 1 0-1.414z'/%3E%3C/svg%3E") 50%/1em auto no-repeat;
    border: 0;
    border-radius: 0.375rem;
    opacity: .5;
}

.modal-header .btn-close {
    padding: calc(1rem * 0.5) calc(1rem * 0.5);
    margin: calc(-0.5 * 1rem) calc(-0.5 * 1rem) calc(-0.5 * 1rem) auto;
}

[dir=ltr] .close {
    float: right
}

.close:hover {
    color: #272c33;
    text-decoration: none
}

.close:not(:disabled):not(.disabled):focus, .close:not(:disabled):not(.disabled):hover {
    opacity: .75
}

[dir] button.close {
    padding: 0;
    background-color: transparent;
    border: 0
}

a.close.disabled {
    pointer-events: none
}

.toast {
    flex-basis: 350px;
    max-width: 350px;
    font-size: .875rem;
    opacity: 0
}

[dir] .toast {
    background-color: hsla(0, 0%, 100%, .85);
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .1);
    box-shadow: 0 .25rem .75rem rgba(39, 44, 51, .1);
    border-radius: .25rem
}

[dir] .toast:not(:last-child) {
    margin-bottom: .75rem
}

.toast.showing {
    opacity: 1
}

.toast.show {
    display: block;
    opacity: 1
}

.toast.hide {
    display: none
}

.toast-header {
    display: flex;
    align-items: center;
    color: #868e96
}

[dir] .toast-header {
    padding: .25rem .75rem;
    background-color: hsla(0, 0%, 100%, .85);
    background-clip: padding-box;
    border-bottom: 1px solid rgba(0, 0, 0, .05)
}

[dir=ltr] .toast-header {
    border-top-left-radius: calc(.25rem - 1px);
    border-top-right-radius: calc(.25rem - 1px)
}

[dir] .toast-body {
    padding: .75rem
}

.modal-open {
    overflow: hidden
}

.modal-open .modal {
    overflow-x: hidden;
    overflow-y: auto
}

.modal {
    position: fixed;
    top: 0;
    z-index: 1050;
    display: none;
    width: 100%;
    height: 100%;
    overflow: hidden;
    outline: 0
}

[dir=ltr] .modal {
    left: 0
}

.modal-dialog {
    position: relative;
    width: auto;
    pointer-events: none
}

[dir] .modal-dialog {
    margin: .5rem
}

.modal.fade .modal-dialog {
    transition: transform .3s ease-out
}

[dir] .modal.fade .modal-dialog {
    transform: translateY(-50px)
}

@media (prefers-reduced-motion: reduce) {
    .modal.fade .modal-dialog {
        transition: none
    }
}

[dir] .modal.show .modal-dialog {
    transform: none
}

[dir] .modal.modal-static .modal-dialog {
    transform: scale(1.02)
}

.modal-dialog-scrollable {
    display: flex;
    max-height: calc(100% - 1rem)
}

.modal-dialog-scrollable .modal-content {
    max-height: calc(100vh - 1rem);
    overflow: hidden
}

.modal-dialog-scrollable .modal-footer, .modal-dialog-scrollable .modal-header {
    flex-shrink: 0
}

.modal-dialog-scrollable .modal-body {
    overflow-y: auto
}

.modal-dialog-centered {
    display: flex;
    align-items: center;
    min-height: calc(100% - 1rem)
}

.modal-dialog-centered:before {
    display: block;
    height: calc(100vh - 1rem);
    content: ""
}

.modal-dialog-centered.modal-dialog-scrollable {
    flex-direction: column;
    justify-content: center;
    height: 100%
}

.modal-dialog-centered.modal-dialog-scrollable .modal-content {
    max-height: none
}

.modal-dialog-centered.modal-dialog-scrollable:before {
    content: none
}

.modal-content {
    position: relative;
    display: flex;
    flex-direction: column;
    width: 100%;
    pointer-events: auto;
    outline: 0
}

[dir] .modal-content {
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid rgba(39, 44, 51, .2);
    border-radius: .5rem;
    box-shadow: 0 .25rem .5rem rgba(39, 44, 51, .5)
}

.modal-backdrop {
    position: fixed;
    top: 0;
    z-index: 1040;
    width: 100vw;
    height: 100vh
}

[dir] .modal-backdrop {
    background-color: rgba(39, 44, 51, .2)
}

[dir=ltr] .modal-backdrop {
    left: 0
}

.modal-backdrop.fade {
    opacity: 0
}

.modal-backdrop.show {
    opacity: 1
}

.modal-header {
    display: flex;
    align-items: flex-start;
    justify-content: space-between
}

[dir] .modal-header {
    padding: 1rem;
    border-bottom: 1px solid #e9edf2
}

[dir=ltr] .modal-header {
    border-top-left-radius: calc(.5rem - 1px);
    border-top-right-radius: calc(.5rem - 1px)
}

[dir] .modal-header .close {
    padding: 1rem
}

[dir=ltr] .modal-header .close {
    margin: -1rem -1rem -1rem auto
}

.modal-title {
    line-height: 1.5
}

[dir] .modal-title {
    margin-bottom: 0
}

.modal-body {
    position: relative;
    flex: 1 1 auto
}

[dir] .modal-body {
    padding: 1rem
}

.modal-footer {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: flex-end
}

[dir] .modal-footer {
    padding: .75rem;
    border-top: 1px solid #e9edf2
}

[dir=ltr] .modal-footer {
    border-bottom-right-radius: calc(.5rem - 1px);
    border-bottom-left-radius: calc(.5rem - 1px)
}

[dir] .modal-footer > * {
    margin: .25rem
}

.modal-scrollbar-measure {
    position: absolute;
    top: -9999px;
    width: 50px;
    height: 50px;
    overflow: scroll
}

@media (min-width: 576px) {
    .modal-dialog {
        max-width: 500px
    }

    [dir] .modal-dialog {
        margin: 1.75rem auto
    }

    .modal-dialog-scrollable {
        max-height: calc(100% - 3.5rem)
    }

    .modal-dialog-scrollable .modal-content {
        max-height: calc(100vh - 3.5rem)
    }

    .modal-dialog-centered {
        min-height: calc(100% - 3.5rem)
    }

    .modal-dialog-centered:before {
        height: calc(100vh - 3.5rem);
    }

    [dir] .modal-content {
        box-shadow: 0 .5rem 1rem rgba(39, 44, 51, .5)
    }

    .modal-sm {
        max-width: 300px
    }
}

@media (min-width: 992px) {
    .modal-lg, .modal-xl {
        max-width: 800px !important;
    }
}

@media (min-width: 1200px) {
    .modal-xl {
        max-width: 1140px
    }
}

.tooltip {
    position: absolute;
    z-index: 1070;
    display: block;
    font-family: inherit;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-decoration: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: 1rem;
    word-wrap: break-word;
    opacity: 0
}

[dir] .tooltip {
    margin: .5rem;
    text-align: start;
    text-shadow: none
}

[dir=ltr] .tooltip {
    text-align: left
}

.tooltip.show {
    opacity: 1
}

.tooltip .arrow {
    position: absolute;
    display: block;
    width: .8rem;
    height: .4rem
}

.tooltip .arrow:before {
    position: absolute;
    content: ""
}

[dir] .tooltip .arrow:before {
    border-color: transparent;
    border-style: solid
}

[dir] .bs-tooltip-auto[x-placement^=top], [dir] .bs-tooltip-top {
    padding: .4rem 0
}

.bs-tooltip-auto[x-placement^=top] .arrow, .bs-tooltip-top .arrow {
    bottom: 0
}

.bs-tooltip-auto[x-placement^=top] .arrow:before, .bs-tooltip-top .arrow:before {
    top: 0
}

[dir] .bs-tooltip-auto[x-placement^=top] .arrow:before, [dir] .bs-tooltip-top .arrow:before {
    border-width: .4rem .4rem 0;
    border-top-color: #fff
}

[dir] .bs-tooltip-auto[x-placement^=right], [dir] .bs-tooltip-right {
    padding: 0 .4rem
}

.bs-tooltip-auto[x-placement^=right] .arrow, .bs-tooltip-right .arrow {
    width: .4rem;
    height: .8rem
}

[dir=ltr] .bs-tooltip-auto[x-placement^=right] .arrow, [dir=ltr] .bs-tooltip-right .arrow {
    left: 0
}

[dir=ltr] .bs-tooltip-auto[x-placement^=right] .arrow:before, [dir=ltr] .bs-tooltip-right .arrow:before {
    right: 0;
    border-width: .4rem .4rem .4rem 0;
    border-right-color: #fff
}

[dir] .bs-tooltip-auto[x-placement^=bottom], [dir] .bs-tooltip-bottom {
    padding: .4rem 0
}

.bs-tooltip-auto[x-placement^=bottom] .arrow, .bs-tooltip-bottom .arrow {
    top: 0
}

.bs-tooltip-auto[x-placement^=bottom] .arrow:before, .bs-tooltip-bottom .arrow:before {
    bottom: 0
}

[dir] .bs-tooltip-auto[x-placement^=bottom] .arrow:before, [dir] .bs-tooltip-bottom .arrow:before {
    border-width: 0 .4rem .4rem;
    border-bottom-color: #fff
}

[dir] .bs-tooltip-auto[x-placement^=left], [dir] .bs-tooltip-left {
    padding: 0 .4rem
}

.bs-tooltip-auto[x-placement^=left] .arrow, .bs-tooltip-left .arrow {
    width: .4rem;
    height: .8rem
}

[dir=ltr] .bs-tooltip-auto[x-placement^=left] .arrow, [dir=ltr] .bs-tooltip-left .arrow {
    right: 0
}

[dir=ltr] .bs-tooltip-auto[x-placement^=left] .arrow:before, [dir=ltr] .bs-tooltip-left .arrow:before {
    left: 0;
    border-width: .4rem 0 .4rem .4rem;
    border-left-color: #fff
}

.tooltip-inner {
    max-width: 200px;
    color: #272c33
}

[dir] .tooltip-inner {
    padding: .5rem 1rem;
    text-align: center;
    background-color: #fff;
    border-radius: .25rem
}

.popover {
    position: absolute;
    top: 0;
    z-index: 1060;
    display: block;
    max-width: 304px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.5;
    text-decoration: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    white-space: normal;
    line-break: auto;
    font-size: .8125rem;
    word-wrap: break-word
}

[dir] .popover {
    text-align: start;
    text-shadow: none;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #e9edf2;
    border-radius: .5rem;
    box-shadow: 0 2px 3px rgba(39, 44, 51, .05)
}

[dir=ltr] .popover {
    left: 0;
    text-align: left
}

.popover .arrow {
    position: absolute;
    display: block;
    width: 1rem;
    height: .5rem
}

[dir] .popover .arrow {
    margin: 0 .5rem
}

.popover .arrow:after, .popover .arrow:before {
    position: absolute;
    display: block;
    content: ""
}

[dir] .popover .arrow:after, [dir] .popover .arrow:before {
    border-color: transparent;
    border-style: solid
}

[dir] .bs-popover-auto[x-placement^=top], [dir] .bs-popover-top {
    margin-bottom: .5rem
}

.bs-popover-auto[x-placement^=top] > .arrow, .bs-popover-top > .arrow {
    bottom: calc(-.5rem - 1px)
}

.bs-popover-auto[x-placement^=top] > .arrow:before, .bs-popover-top > .arrow:before {
    bottom: 0
}

[dir] .bs-popover-auto[x-placement^=top] > .arrow:before, [dir] .bs-popover-top > .arrow:before {
    border-width: .5rem .5rem 0;
    border-top-color: #e9edf2
}

.bs-popover-auto[x-placement^=top] > .arrow:after, .bs-popover-top > .arrow:after {
    bottom: 1px
}

[dir] .bs-popover-auto[x-placement^=top] > .arrow:after, [dir] .bs-popover-top > .arrow:after {
    border-width: .5rem .5rem 0;
    border-top-color: #fff
}

[dir=ltr] .bs-popover-auto[x-placement^=right], [dir=ltr] .bs-popover-right {
    margin-left: .5rem
}

.bs-popover-auto[x-placement^=right] > .arrow, .bs-popover-right > .arrow {
    width: .5rem;
    height: 1rem
}

[dir] .bs-popover-auto[x-placement^=right] > .arrow, [dir] .bs-popover-right > .arrow {
    margin: .5rem 0
}

[dir=ltr] .bs-popover-auto[x-placement^=right] > .arrow, [dir=ltr] .bs-popover-right > .arrow {
    left: calc(-.5rem - 1px)
}

[dir=ltr] .bs-popover-auto[x-placement^=right] > .arrow:before, [dir=ltr] .bs-popover-right > .arrow:before {
    left: 0;
    border-width: .5rem .5rem .5rem 0;
    border-right-color: #e9edf2
}

[dir=ltr] .bs-popover-auto[x-placement^=right] > .arrow:after, [dir=ltr] .bs-popover-right > .arrow:after {
    left: 1px;
    border-width: .5rem .5rem .5rem 0;
    border-right-color: #fff
}

[dir] .bs-popover-auto[x-placement^=bottom], [dir] .bs-popover-bottom {
    margin-top: .5rem
}

.bs-popover-auto[x-placement^=bottom] > .arrow, .bs-popover-bottom > .arrow {
    top: calc(-.5rem - 1px)
}

.bs-popover-auto[x-placement^=bottom] > .arrow:before, .bs-popover-bottom > .arrow:before {
    top: 0
}

[dir] .bs-popover-auto[x-placement^=bottom] > .arrow:before, [dir] .bs-popover-bottom > .arrow:before {
    border-width: 0 .5rem .5rem;
    border-bottom-color: #e9edf2
}

.bs-popover-auto[x-placement^=bottom] > .arrow:after, .bs-popover-bottom > .arrow:after {
    top: 1px
}

[dir] .bs-popover-auto[x-placement^=bottom] > .arrow:after, [dir] .bs-popover-bottom > .arrow:after {
    border-width: 0 .5rem .5rem;
    border-bottom-color: #fff
}

.bs-popover-auto[x-placement^=bottom] .popover-header:before, .bs-popover-bottom .popover-header:before {
    position: absolute;
    top: 0;
    display: block;
    width: 1rem;
    content: ""
}

[dir] .bs-popover-auto[x-placement^=bottom] .popover-header:before, [dir] .bs-popover-bottom .popover-header:before {
    border-bottom: 1px solid #f5f7fa
}

[dir=ltr] .bs-popover-auto[x-placement^=bottom] .popover-header:before, [dir=ltr] .bs-popover-bottom .popover-header:before {
    left: 50%;
    margin-left: -.5rem
}

[dir=ltr] .bs-popover-auto[x-placement^=left], [dir=ltr] .bs-popover-left {
    margin-right: .5rem
}

.bs-popover-auto[x-placement^=left] > .arrow, .bs-popover-left > .arrow {
    width: .5rem;
    height: 1rem
}

[dir] .bs-popover-auto[x-placement^=left] > .arrow, [dir] .bs-popover-left > .arrow {
    margin: .5rem 0
}

[dir=ltr] .bs-popover-auto[x-placement^=left] > .arrow, [dir=ltr] .bs-popover-left > .arrow {
    right: calc(-.5rem - 1px)
}

[dir=ltr] .bs-popover-auto[x-placement^=left] > .arrow:before, [dir=ltr] .bs-popover-left > .arrow:before {
    right: 0;
    border-width: .5rem 0 .5rem .5rem;
    border-left-color: #e9edf2
}

[dir=ltr] .bs-popover-auto[x-placement^=left] > .arrow:after, [dir=ltr] .bs-popover-left > .arrow:after {
    right: 1px;
    border-width: .5rem 0 .5rem .5rem;
    border-left-color: #fff
}

.popover-header {
    font-size: .8125rem;
    color: #303840
}

[dir] .popover-header {
    padding: .5rem .75rem;
    margin-bottom: 0;
    background-color: #f5f7fa;
    border-bottom: 1px solid #e4e9f2
}

[dir=ltr] .popover-header {
    border-top-left-radius: calc(.5rem - 1px);
    border-top-right-radius: calc(.5rem - 1px)
}

.popover-header:empty {
    display: none
}

.popover-body {
    color: #272c33
}

[dir] .popover-body {
    padding: .5rem .75rem
}

@-webkit-keyframes spinner-border-ltr {
    to {
        transform: rotate(1turn)
    }
}

@keyframes spinner-border-ltr {
    to {
        transform: rotate(1turn)
    }
}

@-webkit-keyframes spinner-border-rtl {
    to {
        transform: rotate(-1turn)
    }
}

@keyframes spinner-border-rtl {
    to {
        transform: rotate(-1turn)
    }
}

.spinner-border {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom
}

[dir] .spinner-border {
    border: .25em solid;
    border-radius: 50%
}

[dir=ltr] .spinner-border {
    border-right-color: transparent;
    -webkit-animation: spinner-border-ltr .75s linear infinite;
    animation: spinner-border-ltr .75s linear infinite
}

.spinner-border-sm {
    width: 1rem;
    height: 1rem
}

[dir] .spinner-border-sm {
    border-width: .2em
}

@-webkit-keyframes spinner-grow {
    0% {
        transform: scale(0)
    }
    50% {
        opacity: 1;
        transform: none
    }
}

@keyframes spinner-grow {
    0% {
        transform: scale(0)
    }
    50% {
        opacity: 1;
        transform: none
    }
}

.spinner-grow {
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    opacity: 0
}

[dir] .spinner-grow {
    background-color: currentColor;
    border-radius: 50%
}

[dir=ltr] .spinner-grow {
    -webkit-animation: spinner-grow .75s linear infinite;
    animation: spinner-grow .75s linear infinite
}

.spinner-grow-sm {
    width: 1rem;
    height: 1rem
}

.align-baseline {
    vertical-align: baseline !important
}

.align-top {
    vertical-align: top !important
}

.align-middle {
    vertical-align: middle !important
}

.align-bottom {
    vertical-align: bottom !important
}

.align-text-bottom {
    vertical-align: text-bottom !important
}

.align-text-top {
    vertical-align: text-top !important
}

[dir] a.bg-primary:focus, [dir] a.bg-primary:hover, [dir] button.bg-primary:focus, [dir] button.bg-primary:hover {
    background-color: #2239ff !important
}

[dir] a.bg-secondary:focus, [dir] a.bg-secondary:hover, [dir] button.bg-secondary:focus, [dir] button.bg-secondary:hover {
    background-color: #6c757d !important
}

[dir] a.bg-success:focus, [dir] a.bg-success:hover, [dir] button.bg-success:focus, [dir] button.bg-success:hover {
    background-color: #5f9a2e !important
}

[dir] a.bg-info:focus, [dir] a.bg-info:hover, [dir] button.bg-info:focus, [dir] button.bg-info:hover {
    background-color: #117a8b !important
}

[dir] a.bg-warning:focus, [dir] a.bg-warning:hover, [dir] button.bg-warning:focus, [dir] button.bg-warning:hover {
    background-color: #d0911d !important
}

[dir] a.bg-danger:focus, [dir] a.bg-danger:hover, [dir] button.bg-danger:focus, [dir] button.bg-danger:hover {
    background-color: #c9302c !important
}

[dir] .bg-transparent {
    background-color: transparent !important
}

[dir] .border-white {
    border-color: #fff !important
}

[dir] .rounded-sm {
    border-radius: .2rem !important
}

[dir] .rounded {
    border-radius: .25rem !important
}

[dir=ltr] .rounded-top {
    border-top-left-radius: .25rem !important;
    border-top-right-radius: .25rem !important
}

[dir=ltr] .rounded-right {
    border-top-right-radius: .25rem !important;
    border-bottom-right-radius: .25rem !important
}

[dir=ltr] .rounded-bottom {
    border-bottom-right-radius: .25rem !important;
    border-bottom-left-radius: .25rem !important
}

[dir=ltr] .rounded-left {
    border-top-left-radius: .25rem !important;
    border-bottom-left-radius: .25rem !important
}

[dir] .rounded-lg {
    border-radius: .5rem !important
}

[dir] .rounded-circle {
    border-radius: 50% !important
}

[dir] .rounded-pill {
    border-radius: 50rem !important
}

[dir] .rounded-0 {
    border-radius: 0 !important
}

.clearfix:after {
    display: block;
    content: ""
}

[dir] .clearfix:after {
    clear: both
}

.d-none {
    display: none !important
}

.d-inline {
    display: inline !important
}

.d-inline-block {
    display: inline-block !important
}

.d-block {
    display: block !important
}

.d-table {
    display: table !important
}

.d-table-row {
    display: table-row !important
}

.d-table-cell {
    display: table-cell !important
}

.d-flex {
    display: flex !important
}

.d-inline-flex {
    display: inline-flex !important
}

@media (min-width: 576px) {
    .d-sm-none {
        display: none !important
    }

    .d-sm-inline {
        display: inline !important
    }

    .d-sm-inline-block {
        display: inline-block !important
    }

    .d-sm-block {
        display: block !important
    }

    .d-sm-table {
        display: table !important
    }

    .d-sm-table-row {
        display: table-row !important
    }

    .d-sm-table-cell {
        display: table-cell !important
    }

    .d-sm-flex {
        display: flex !important
    }

    .d-sm-inline-flex {
        display: inline-flex !important
    }
}

@media (min-width: 768px) {
    .d-md-none {
        display: none !important
    }

    .d-md-inline {
        display: inline !important
    }

    .d-md-inline-block {
        display: inline-block !important
    }

    .d-md-block {
        display: block !important
    }

    .d-md-table {
        display: table !important
    }

    .d-md-table-row {
        display: table-row !important
    }

    .d-md-table-cell {
        display: table-cell !important
    }

    .d-md-flex {
        display: flex !important
    }

    .d-md-inline-flex {
        display: inline-flex !important
    }
}

@media (min-width: 992px) {
    .d-lg-none {
        display: none !important
    }

    .d-lg-inline {
        display: inline !important
    }

    .d-lg-inline-block {
        display: inline-block !important
    }

    .d-lg-block {
        display: block !important
    }

    .d-lg-table {
        display: table !important
    }

    .d-lg-table-row {
        display: table-row !important
    }

    .d-lg-table-cell {
        display: table-cell !important
    }

    .d-lg-flex {
        display: flex !important
    }

    .d-lg-inline-flex {
        display: inline-flex !important
    }
}

.flex-row {
    flex-direction: row !important
}

.flex-column {
    flex-direction: column !important
}

.flex-row-reverse {
    flex-direction: row-reverse !important
}

.flex-column-reverse {
    flex-direction: column-reverse !important
}

.flex-wrap {
    flex-wrap: wrap !important
}

.flex-nowrap {
    flex-wrap: nowrap !important
}

.flex-wrap-reverse {
    flex-wrap: wrap-reverse !important
}

.flex-fill {
    flex: 1 1 auto !important
}

.flex-grow-0 {
    flex-grow: 0 !important
}

.flex-grow-1 {
    flex-grow: 1 !important
}

.flex-shrink-1 {
    flex-shrink: 1 !important
}

.justify-content-start {
    justify-content: flex-start !important
}

.justify-content-end {
    justify-content: flex-end !important
}

.justify-content-center {
    justify-content: center !important
}

.justify-content-between {
    justify-content: space-between !important
}

.justify-content-around {
    justify-content: space-around !important
}

.align-items-start {
    align-items: flex-start !important
}

.align-items-end {
    align-items: flex-end !important
}

.align-items-center {
    align-items: center !important
}

.align-items-baseline {
    align-items: baseline !important
}

.align-items-stretch {
    align-items: stretch !important
}

.align-content-start {
    align-content: flex-start !important
}

.align-content-end {
    align-content: flex-end !important
}

.align-content-center {
    align-content: center !important
}

.align-content-between {
    align-content: space-between !important
}

.align-content-around {
    align-content: space-around !important
}

.align-content-stretch {
    align-content: stretch !important
}

.align-self-auto {
    align-self: auto !important
}

.align-self-start {
    align-self: flex-start !important
}

.align-self-end {
    align-self: flex-end !important
}

.align-self-center {
    align-self: center !important
}

.align-self-baseline {
    align-self: baseline !important
}

.align-self-stretch {
    align-self: stretch !important
}

@media (min-width: 576px) {
    .flex-sm-row {
        flex-direction: row !important
    }

    .flex-sm-column {
        flex-direction: column !important
    }

    .flex-sm-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-sm-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-sm-wrap {
        flex-wrap: wrap !important
    }

    .flex-sm-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-sm-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .flex-sm-fill {
        flex: 1 1 auto !important
    }

    .flex-sm-grow-0 {
        flex-grow: 0 !important
    }

    .flex-sm-grow-1 {
        flex-grow: 1 !important
    }

    .flex-sm-shrink-0 {
        flex-shrink: 0 !important
    }

    .flex-sm-shrink-1 {
        flex-shrink: 1 !important
    }

    .justify-content-sm-start {
        justify-content: flex-start !important
    }

    .justify-content-sm-end {
        justify-content: flex-end !important
    }

    .justify-content-sm-center {
        justify-content: center !important
    }

    .justify-content-sm-between {
        justify-content: space-between !important
    }

    .justify-content-sm-around {
        justify-content: space-around !important
    }

    .align-items-sm-start {
        align-items: flex-start !important
    }

    .align-items-sm-end {
        align-items: flex-end !important
    }

    .align-items-sm-center {
        align-items: center !important
    }

    .align-items-sm-baseline {
        align-items: baseline !important
    }

    .align-items-sm-stretch {
        align-items: stretch !important
    }

    .align-content-sm-start {
        align-content: flex-start !important
    }

    .align-content-sm-end {
        align-content: flex-end !important
    }

    .align-content-sm-center {
        align-content: center !important
    }

    .align-content-sm-between {
        align-content: space-between !important
    }

    .align-content-sm-around {
        align-content: space-around !important
    }

    .align-content-sm-stretch {
        align-content: stretch !important
    }

    .align-self-sm-auto {
        align-self: auto !important
    }

    .align-self-sm-start {
        align-self: flex-start !important
    }

    .align-self-sm-end {
        align-self: flex-end !important
    }

    .align-self-sm-center {
        align-self: center !important
    }

    .align-self-sm-baseline {
        align-self: baseline !important
    }

    .align-self-sm-stretch {
        align-self: stretch !important
    }
}

@media (min-width: 768px) {
    .flex-md-row {
        flex-direction: row !important
    }

    .flex-md-column {
        flex-direction: column !important
    }

    .flex-md-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-md-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-md-wrap {
        flex-wrap: wrap !important
    }

    .flex-md-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-md-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .flex-md-fill {
        flex: 1 1 auto !important
    }

    .flex-md-grow-0 {
        flex-grow: 0 !important
    }

    .flex-md-grow-1 {
        flex-grow: 1 !important
    }

    .flex-md-shrink-0 {
        flex-shrink: 0 !important
    }

    .flex-md-shrink-1 {
        flex-shrink: 1 !important
    }

    .justify-content-md-start {
        justify-content: flex-start !important
    }

    .justify-content-md-end {
        justify-content: flex-end !important
    }

    .justify-content-md-center {
        justify-content: center !important
    }

    .justify-content-md-between {
        justify-content: space-between !important
    }

    .justify-content-md-around {
        justify-content: space-around !important
    }

    .align-items-md-start {
        align-items: flex-start !important
    }

    .align-items-md-end {
        align-items: flex-end !important
    }

    .align-items-md-center {
        align-items: center !important
    }

    .align-items-md-baseline {
        align-items: baseline !important
    }

    .align-items-md-stretch {
        align-items: stretch !important
    }

    .align-content-md-start {
        align-content: flex-start !important
    }

    .align-content-md-end {
        align-content: flex-end !important
    }

    .align-content-md-center {
        align-content: center !important
    }

    .align-content-md-between {
        align-content: space-between !important
    }

    .align-content-md-around {
        align-content: space-around !important
    }

    .align-content-md-stretch {
        align-content: stretch !important
    }

    .align-self-md-auto {
        align-self: auto !important
    }

    .align-self-md-start {
        align-self: flex-start !important
    }

    .align-self-md-end {
        align-self: flex-end !important
    }

    .align-self-md-center {
        align-self: center !important
    }

    .align-self-md-baseline {
        align-self: baseline !important
    }

    .align-self-md-stretch {
        align-self: stretch !important
    }
}

@media (min-width: 992px) {
    .flex-lg-row {
        flex-direction: row !important
    }

    .flex-lg-column {
        flex-direction: column !important
    }

    .flex-lg-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-lg-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-lg-wrap {
        flex-wrap: wrap !important
    }

    .flex-lg-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-lg-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .flex-lg-fill {
        flex: 1 1 auto !important
    }

    .flex-lg-grow-0 {
        flex-grow: 0 !important
    }

    .flex-lg-grow-1 {
        flex-grow: 1 !important
    }

    .flex-lg-shrink-0 {
        flex-shrink: 0 !important
    }

    .flex-lg-shrink-1 {
        flex-shrink: 1 !important
    }

    .justify-content-lg-start {
        justify-content: flex-start !important
    }

    .justify-content-lg-end {
        justify-content: flex-end !important
    }

    .justify-content-lg-center {
        justify-content: center !important
    }

    .justify-content-lg-between {
        justify-content: space-between !important
    }

    .justify-content-lg-around {
        justify-content: space-around !important
    }

    .align-items-lg-start {
        align-items: flex-start !important
    }

    .align-items-lg-end {
        align-items: flex-end !important
    }

    .align-items-lg-center {
        align-items: center !important
    }

    .align-items-lg-baseline {
        align-items: baseline !important
    }

    .align-items-lg-stretch {
        align-items: stretch !important
    }

    .align-content-lg-start {
        align-content: flex-start !important
    }

    .align-content-lg-end {
        align-content: flex-end !important
    }

    .align-content-lg-center {
        align-content: center !important
    }

    .align-content-lg-between {
        align-content: space-between !important
    }

    .align-content-lg-around {
        align-content: space-around !important
    }

    .align-content-lg-stretch {
        align-content: stretch !important
    }

    .align-self-lg-auto {
        align-self: auto !important
    }

    .align-self-lg-start {
        align-self: flex-start !important
    }

    .align-self-lg-end {
        align-self: flex-end !important
    }

    .align-self-lg-center {
        align-self: center !important
    }

    .align-self-lg-baseline {
        align-self: baseline !important
    }

    .align-self-lg-stretch {
        align-self: stretch !important
    }
}

@media (min-width: 1200px) {
    .flex-xl-row {
        flex-direction: row !important
    }

    .flex-xl-column {
        flex-direction: column !important
    }

    .flex-xl-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-xl-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-xl-wrap {
        flex-wrap: wrap !important
    }

    .flex-xl-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-xl-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .flex-xl-fill {
        flex: 1 1 auto !important
    }

    .flex-xl-grow-0 {
        flex-grow: 0 !important
    }

    .flex-xl-grow-1 {
        flex-grow: 1 !important
    }

    .flex-xl-shrink-0 {
        flex-shrink: 0 !important
    }

    .flex-xl-shrink-1 {
        flex-shrink: 1 !important
    }

    .justify-content-xl-start {
        justify-content: flex-start !important
    }

    .justify-content-xl-end {
        justify-content: flex-end !important
    }

    .justify-content-xl-center {
        justify-content: center !important
    }

    .justify-content-xl-between {
        justify-content: space-between !important
    }

    .justify-content-xl-around {
        justify-content: space-around !important
    }

    .align-items-xl-start {
        align-items: flex-start !important
    }

    .align-items-xl-end {
        align-items: flex-end !important
    }

    .align-items-xl-center {
        align-items: center !important
    }

    .align-items-xl-baseline {
        align-items: baseline !important
    }

    .align-items-xl-stretch {
        align-items: stretch !important
    }

    .align-content-xl-start {
        align-content: flex-start !important
    }

    .align-content-xl-end {
        align-content: flex-end !important
    }

    .align-content-xl-center {
        align-content: center !important
    }

    .align-content-xl-between {
        align-content: space-between !important
    }

    .align-content-xl-around {
        align-content: space-around !important
    }

    .align-content-xl-stretch {
        align-content: stretch !important
    }

    .align-self-xl-auto {
        align-self: auto !important
    }

    .align-self-xl-start {
        align-self: flex-start !important
    }

    .align-self-xl-end {
        align-self: flex-end !important
    }

    .align-self-xl-center {
        align-self: center !important
    }

    .align-self-xl-baseline {
        align-self: baseline !important
    }

    .align-self-xl-stretch {
        align-self: stretch !important
    }
}

@media (min-width: 1366px) {
    .flex-xxl-row {
        flex-direction: row !important
    }

    .flex-xxl-column {
        flex-direction: column !important
    }

    .flex-xxl-row-reverse {
        flex-direction: row-reverse !important
    }

    .flex-xxl-column-reverse {
        flex-direction: column-reverse !important
    }

    .flex-xxl-wrap {
        flex-wrap: wrap !important
    }

    .flex-xxl-nowrap {
        flex-wrap: nowrap !important
    }

    .flex-xxl-wrap-reverse {
        flex-wrap: wrap-reverse !important
    }

    .flex-xxl-fill {
        flex: 1 1 auto !important
    }

    .flex-xxl-grow-0 {
        flex-grow: 0 !important
    }

    .flex-xxl-grow-1 {
        flex-grow: 1 !important
    }

    .flex-xxl-shrink-0 {
        flex-shrink: 0 !important
    }

    .flex-xxl-shrink-1 {
        flex-shrink: 1 !important
    }

    .justify-content-xxl-start {
        justify-content: flex-start !important
    }

    .justify-content-xxl-end {
        justify-content: flex-end !important
    }

    .justify-content-xxl-center {
        justify-content: center !important
    }

    .justify-content-xxl-between {
        justify-content: space-between !important
    }

    .justify-content-xxl-around {
        justify-content: space-around !important
    }

    .align-items-xxl-start {
        align-items: flex-start !important
    }

    .align-items-xxl-end {
        align-items: flex-end !important
    }

    .align-items-xxl-center {
        align-items: center !important
    }

    .align-items-xxl-baseline {
        align-items: baseline !important
    }

    .align-items-xxl-stretch {
        align-items: stretch !important
    }

    .align-content-xxl-start {
        align-content: flex-start !important
    }

    .align-content-xxl-end {
        align-content: flex-end !important
    }

    .align-content-xxl-center {
        align-content: center !important
    }

    .align-content-xxl-between {
        align-content: space-between !important
    }

    .align-content-xxl-around {
        align-content: space-around !important
    }

    .align-content-xxl-stretch {
        align-content: stretch !important
    }

    .align-self-xxl-auto {
        align-self: auto !important
    }

    .align-self-xxl-start {
        align-self: flex-start !important
    }

    .align-self-xxl-end {
        align-self: flex-end !important
    }

    .align-self-xxl-center {
        align-self: center !important
    }

    .align-self-xxl-baseline {
        align-self: baseline !important
    }

    .align-self-xxl-stretch {
        align-self: stretch !important
    }
}

[dir=ltr] .float-left {
    float: left !important
}

[dir=ltr] .float-right, [dir=rtl] .float-left {
    float: right !important
}

[dir] .float-none {
    float: none !important
}

@media (min-width: 576px) {
    [dir=ltr] .float-sm-left {
        float: left !important
    }

    [dir=ltr] .float-sm-right {
        float: right !important
    }

    [dir] .float-sm-none {
        float: none !important
    }
}

@media (min-width: 768px) {
    [dir=ltr] .float-md-left {
        float: left !important
    }

    [dir=ltr] .float-md-right {
        float: right !important
    }

    [dir] .float-md-none {
        float: none !important
    }
}

@media (min-width: 992px) {
    [dir=ltr] .float-lg-left {
        float: left !important
    }

    [dir=ltr] .float-lg-right {
        float: right !important
    }

    [dir] .float-lg-none {
        float: none !important
    }
}

@media (min-width: 1200px) {
    [dir=ltr] .float-xl-left {
        float: left !important
    }

    [dir=ltr] .float-xl-right {
        float: right !important
    }

    [dir] .float-xl-none {
        float: none !important
    }
}

@media (min-width: 1366px) {
    [dir=ltr] .float-xxl-left {
        float: left !important
    }

    [dir=ltr] .float-xxl-right {
        float: right !important
    }

    [dir] .float-xxl-none {
        float: none !important
    }
}

.user-select-all {
    -webkit-user-select: all !important;
    -moz-user-select: all !important;
    -ms-user-select: all !important;
    user-select: all !important
}

.user-select-auto {
    -webkit-user-select: auto !important;
    -moz-user-select: auto !important;
    -ms-user-select: auto !important;
    user-select: auto !important
}

.user-select-none {
    -webkit-user-select: none !important;
    -moz-user-select: none !important;
    -ms-user-select: none !important;
    user-select: none !important
}

.overflow-auto {
    overflow: auto !important
}

.overflow-hidden {
    overflow: hidden !important
}

.position-static {
    position: static !important
}

.position-relative {
    position: relative !important
}

.position-absolute {
    position: absolute !important
}

.position-fixed {
    position: fixed !important
}

.position-sticky {
    position: -webkit-sticky !important;
    position: sticky !important
}

.fixed-top {
    position: fixed;
    top: 0;
    z-index: 1030
}

.fixed-bottom {
    position: fixed;
    bottom: 0;
    z-index: 1030
}

[dir=ltr] .fixed-bottom {
    right: 0;
    left: 0
}

@supports ((position:-webkit-sticky) or (position:sticky)) {
    .sticky-top {
        position: -webkit-sticky;
        position: sticky;
        top: 0;
        z-index: 1020
    }
}

.sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    white-space: nowrap
}

[dir] .sr-only {
    padding: 0;
    margin: -1px;
    border: 0
}

.sr-only-focusable:active, .sr-only-focusable:focus {
    position: static;
    width: auto;
    height: auto;
    overflow: visible;
    clip: auto;
    white-space: normal
}

[dir] .shadow-sm {
    box-shadow: 0 .125rem .25rem rgba(39, 44, 51, .075) !important
}

[dir] .shadow {
    box-shadow: 0 .5rem 1rem rgba(39, 44, 51, .15) !important
}

[dir] .shadow-lg {
    box-shadow: 0 1rem 3rem rgba(39, 44, 51, .175) !important
}

[dir] .shadow-none {
    box-shadow: none !important
}

.w-25 {
    width: 25% !important
}

.w-50 {
    width: 50% !important
}

.w-75 {
    width: 75% !important
}

.w-100 {
    width: 100% !important
}

.w-auto {
    width: auto !important
}

.w-64 {
    width: 64px !important
}

.h-25 {
    height: 25% !important
}

.h-50 {
    height: 50% !important
}

.h-75 {
    height: 75% !important
}

.h-100 {
    height: 100% !important
}

.h-auto {
    height: auto !important
}

.h-64 {
    height: 64px !important
}

.mw-100 {
    max-width: 100% !important
}

.mh-100 {
    max-height: 100% !important
}

.min-vw-100 {
    min-width: 100vw !important
}

.min-vh-100 {
    min-height: 100vh !important
}

.vw-100 {
    width: 100vw !important
}

.vh-100 {
    height: 100vh !important
}

[dir] .m-0 {
    margin: 0 !important
}

[dir] .mt-0, [dir] .my-0 {
    margin-top: 0 !important
}

[dir=ltr] .mr-0, [dir=ltr] .mx-0 {
    margin-right: 0 !important
}

[dir] .mb-0, [dir] .my-0 {
    margin-bottom: 0 !important
}

[dir=ltr] .ml-0, [dir=ltr] .mx-0 {
    margin-left: 0 !important
}

[dir] .m-1 {
    margin: .25rem !important
}

[dir] .mt-1, [dir] .my-1 {
    margin-top: .25rem !important
}

[dir=ltr] .mr-1, [dir=ltr] .mx-1 {
    margin-right: .25rem !important
}

[dir] .mb-1, [dir] .my-1 {
    margin-bottom: .25rem !important
}

[dir=ltr] .ml-1, [dir=ltr] .mx-1 {
    margin-left: .25rem !important
}

[dir] .m-2 {
    margin: .5rem !important
}

[dir] .mt-2, [dir] .my-2 {
    margin-top: .5rem !important
}

[dir=ltr] .mr-2, [dir=ltr] .mx-2 {
    margin-right: .5rem !important
}

[dir] .mb-2, [dir] .my-2 {
    margin-bottom: .5rem !important
}

[dir=ltr] .ml-2, [dir=ltr] .mx-2 {
    margin-left: .5rem !important
}

[dir] .m-3 {
    margin: 1rem !important
}

[dir] .mt-3, [dir] .my-3 {
    margin-top: 1rem !important
}

[dir=ltr] .mr-3, [dir=ltr] .mx-3 {
    margin-right: 1rem !important
}

[dir] .mb-3, [dir] .my-3 {
    margin-bottom: 1rem !important
}

[dir=ltr] .ml-3, [dir=ltr] .mx-3 {
    margin-left: 1rem !important
}

[dir] .m-4 {
    margin: 1.5rem !important
}

[dir] .mt-4, [dir] .my-4 {
    margin-top: 1.5rem !important
}

[dir=ltr] .mr-4, [dir=ltr] .mx-4 {
    margin-right: 1.5rem !important
}

[dir] .mb-4, [dir] .my-4 {
    margin-bottom: 1.5rem !important
}

[dir=ltr] .ml-4, [dir=ltr] .mx-4 {
    margin-left: 1.5rem !important
}

[dir] .m-5 {
    margin: 3rem !important
}

[dir] .mt-5, [dir] .my-5 {
    margin-top: 3rem !important
}

[dir=ltr] .mr-5, [dir=ltr] .mx-5 {
    margin-right: 3rem !important
}

[dir] .mb-5, [dir] .my-5 {
    margin-bottom: 3rem !important
}

[dir=ltr] .ml-5, [dir=ltr] .mx-5 {
    margin-left: 3rem !important
}

[dir] .m-4pt {
    margin: .25rem !important
}

[dir] .mt-4pt, [dir] .my-4pt {
    margin-top: .25rem !important
}

[dir=ltr] .mr-4pt, [dir=ltr] .mx-4pt {
    margin-right: .25rem !important
}

[dir] .mb-4pt, [dir] .my-4pt {
    margin-bottom: .25rem !important
}

[dir=ltr] .ml-4pt, [dir=ltr] .mx-4pt {
    margin-left: .25rem !important
}

[dir] .m-8pt {
    margin: .5rem !important
}

[dir] .mt-8pt, [dir] .my-8pt {
    margin-top: .5rem !important
}

[dir=ltr] .mr-8pt, [dir=ltr] .mx-8pt {
    margin-right: .5rem !important
}

[dir] .mb-8pt, [dir] .my-8pt {
    margin-bottom: .5rem !important
}

[dir=ltr] .ml-8pt, [dir=ltr] .mx-8pt {
    margin-left: .5rem !important
}

[dir] .m-12pt {
    margin: .75rem !important
}

[dir] .mt-12pt, [dir] .my-12pt {
    margin-top: .75rem !important
}

[dir=ltr] .mr-12pt, [dir=ltr] .mx-12pt {
    margin-right: .75rem !important
}

[dir] .mb-12pt, [dir] .my-12pt {
    margin-bottom: .75rem !important
}

[dir=ltr] .ml-12pt, [dir=ltr] .mx-12pt {
    margin-left: .75rem !important
}

[dir] .m-16pt {
    margin: 1rem !important
}

[dir] .mt-16pt, [dir] .my-16pt {
    margin-top: 1rem !important
}

[dir=ltr] .mr-16pt, [dir=ltr] .mx-16pt {
    margin-right: 1rem !important
}

[dir] .mb-16pt, [dir] .my-16pt {
    margin-bottom: 1rem !important
}

[dir=ltr] .ml-16pt, [dir=ltr] .mx-16pt {
    margin-left: 1rem !important
}

[dir] .m-20pt {
    margin: 1.25rem !important
}

[dir] .mt-20pt, [dir] .my-20pt {
    margin-top: 1.25rem !important
}

[dir=ltr] .mr-20pt, [dir=ltr] .mx-20pt {
    margin-right: 1.25rem !important
}

[dir] .mb-20pt, [dir] .my-20pt {
    margin-bottom: 1.25rem !important
}

[dir=ltr] .ml-20pt, [dir=ltr] .mx-20pt {
    margin-left: 1.25rem !important
}

[dir] .m-24pt {
    margin: 1.5rem !important
}

[dir] .mt-24pt, [dir] .my-24pt {
    margin-top: 1.5rem !important
}

[dir=ltr] .mr-24pt, [dir=ltr] .mx-24pt {
    margin-right: 1.5rem !important
}

[dir] .mb-24pt, [dir] .my-24pt {
    margin-bottom: 1.5rem !important
}

[dir=ltr] .ml-24pt, [dir=ltr] .mx-24pt {
    margin-left: 1.5rem !important
}

[dir] .m-32pt {
    margin: 2rem !important
}

[dir] .mt-32pt, [dir] .my-32pt {
    margin-top: 2rem !important
}

[dir=ltr] .mr-32pt, [dir=ltr] .mx-32pt {
    margin-right: 2rem !important
}

[dir] .mb-32pt, [dir] .my-32pt {
    margin-bottom: 2rem !important
}

[dir=ltr] .ml-32pt, [dir=ltr] .mx-32pt {
    margin-left: 2rem !important
}

[dir] .m-40pt {
    margin: 2.5rem !important
}

[dir] .mt-40pt, [dir] .my-40pt {
    margin-top: 2.5rem !important
}

[dir=ltr] .mr-40pt, [dir=ltr] .mx-40pt {
    margin-right: 2.5rem !important
}

[dir] .mb-40pt, [dir] .my-40pt {
    margin-bottom: 2.5rem !important
}

[dir=ltr] .ml-40pt, [dir=ltr] .mx-40pt {
    margin-left: 2.5rem !important
}

[dir] .m-44pt {
    margin: 2.75rem !important
}

[dir] .mt-44pt, [dir] .my-44pt {
    margin-top: 2.75rem !important
}

[dir=ltr] .mr-44pt, [dir=ltr] .mx-44pt {
    margin-right: 2.75rem !important
}

[dir] .mb-44pt, [dir] .my-44pt {
    margin-bottom: 2.75rem !important
}

[dir=ltr] .ml-44pt, [dir=ltr] .mx-44pt {
    margin-left: 2.75rem !important
}

[dir] .m-48pt {
    margin: 3rem !important
}

[dir] .mt-48pt, [dir] .my-48pt {
    margin-top: 3rem !important
}

[dir=ltr] .mr-48pt, [dir=ltr] .mx-48pt {
    margin-right: 3rem !important
}

[dir] .mb-48pt, [dir] .my-48pt {
    margin-bottom: 3rem !important
}

[dir=ltr] .ml-48pt, [dir=ltr] .mx-48pt {
    margin-left: 3rem !important
}

[dir] .m-64pt {
    margin: 4rem !important
}

[dir] .mt-64pt, [dir] .my-64pt {
    margin-top: 4rem !important
}

[dir=ltr] .mr-64pt, [dir=ltr] .mx-64pt {
    margin-right: 4rem !important
}

[dir] .mb-64pt, [dir] .my-64pt {
    margin-bottom: 4rem !important
}

[dir=ltr] .ml-64pt, [dir=ltr] .mx-64pt {
    margin-left: 4rem !important
}

[dir] .m-112pt {
    margin: 7rem !important
}

[dir] .mt-112pt, [dir] .my-112pt {
    margin-top: 7rem !important
}

[dir=ltr] .mr-112pt, [dir=ltr] .mx-112pt {
    margin-right: 7rem !important
}

[dir] .mb-112pt, [dir] .my-112pt {
    margin-bottom: 7rem !important
}

[dir=ltr] .ml-112pt, [dir=ltr] .mx-112pt {
    margin-left: 7rem !important
}

[dir] .m-128pt {
    margin: 8rem !important
}

[dir] .mt-128pt, [dir] .my-128pt {
    margin-top: 8rem !important
}

[dir=ltr] .mr-128pt, [dir=ltr] .mx-128pt {
    margin-right: 8rem !important
}

[dir] .mb-128pt, [dir] .my-128pt {
    margin-bottom: 8rem !important
}

[dir=ltr] .ml-128pt, [dir=ltr] .mx-128pt {
    margin-left: 8rem !important
}

[dir] .p-0 {
    padding: 0 !important
}

[dir] .pt-0, [dir] .py-0 {
    padding-top: 0 !important
}

[dir=ltr] .pr-0, [dir=ltr] .px-0 {
    padding-right: 0 !important
}

[dir] .pb-0, [dir] .py-0 {
    padding-bottom: 0 !important
}

[dir=ltr] .pl-0, [dir=ltr] .px-0 {
    padding-left: 0 !important
}

[dir] .p-1 {
    padding: .25rem !important
}

[dir] .pt-1, [dir] .py-1 {
    padding-top: .25rem !important
}

[dir=ltr] .pr-1, [dir=ltr] .px-1 {
    padding-right: .25rem !important
}

[dir] .pb-1, [dir] .py-1 {
    padding-bottom: .25rem !important
}

[dir=ltr] .pl-1, [dir=ltr] .px-1 {
    padding-left: .25rem !important
}

[dir] .p-2 {
    padding: .5rem !important
}

[dir] .pt-2, [dir] .py-2 {
    padding-top: .5rem !important
}

[dir=ltr] .pr-2, [dir=ltr] .px-2 {
    padding-right: .5rem !important
}

[dir] .pb-2, [dir] .py-2 {
    padding-bottom: .5rem !important
}

[dir=ltr] .pl-2, [dir=ltr] .px-2 {
    padding-left: .5rem !important
}

[dir] .p-3 {
    padding: 1rem !important
}

[dir] .pt-3, [dir] .py-3 {
    padding-top: 1rem !important
}

[dir=ltr] .pr-3, [dir=ltr] .px-3 {
    padding-right: 1rem !important
}

[dir] .pb-3, [dir] .py-3 {
    padding-bottom: 1rem !important
}

[dir=ltr] .pl-3, [dir=ltr] .px-3 {
    padding-left: 1rem !important
}

[dir] .p-4 {
    padding: 1.5rem !important
}

[dir] .pt-4, [dir] .py-4 {
    padding-top: 1.5rem !important
}

[dir=ltr] .pr-4, [dir=ltr] .px-4 {
    padding-right: 1.5rem !important
}

[dir] .pb-4, [dir] .py-4 {
    padding-bottom: 1.5rem !important
}

[dir=ltr] .pl-4, [dir=ltr] .px-4 {
    padding-left: 1.5rem !important
}

[dir] .p-5 {
    padding: 3rem !important
}

[dir] .pt-5, [dir] .py-5 {
    padding-top: 3rem !important
}

[dir=ltr] .pr-5, [dir=ltr] .px-5 {
    padding-right: 3rem !important
}

[dir] .pb-5, [dir] .py-5 {
    padding-bottom: 3rem !important
}

[dir=ltr] .pl-5, [dir=ltr] .px-5 {
    padding-left: 3rem !important
}

[dir] .p-4pt {
    padding: .25rem !important
}

[dir] .pt-4pt, [dir] .py-4pt {
    padding-top: .25rem !important
}

[dir=ltr] .pr-4pt, [dir=ltr] .px-4pt {
    padding-right: .25rem !important
}

[dir] .pb-4pt, [dir] .py-4pt {
    padding-bottom: .25rem !important
}

[dir=ltr] .pl-4pt, [dir=ltr] .px-4pt {
    padding-left: .25rem !important
}

[dir] .p-8pt {
    padding: .5rem !important
}

[dir] .pt-8pt, [dir] .py-8pt {
    padding-top: .5rem !important
}

[dir=ltr] .pr-8pt, [dir=ltr] .px-8pt {
    padding-right: .5rem !important
}

[dir] .pb-8pt, [dir] .py-8pt {
    padding-bottom: .5rem !important
}

[dir=ltr] .pl-8pt, [dir=ltr] .px-8pt {
    padding-left: .5rem !important
}

[dir] .p-12pt {
    padding: .75rem !important
}

[dir] .pt-12pt, [dir] .py-12pt {
    padding-top: .75rem !important
}

[dir=ltr] .pr-12pt, [dir=ltr] .px-12pt {
    padding-right: .75rem !important
}

[dir] .pb-12pt, [dir] .py-12pt {
    padding-bottom: .75rem !important
}

[dir=ltr] .pl-12pt, [dir=ltr] .px-12pt {
    padding-left: .75rem !important
}

[dir=ltr] .pl-14pt, [dir=ltr] .px-14pt {
    padding-left: .90rem !important
}

[dir] .p-16pt {
    padding: 1rem !important
}

[dir] .pt-16pt, [dir] .py-16pt {
    padding-top: 1rem !important
}

[dir=ltr] .pr-16pt, [dir=ltr] .px-16pt {
    padding-right: 1rem !important
}

[dir] .pb-16pt, [dir] .py-16pt {
    padding-bottom: 1rem !important
}

[dir=ltr] .pl-16pt, [dir=ltr] .px-16pt {
    padding-left: 1rem !important
}

[dir] .p-20pt {
    padding: 1.25rem !important
}

[dir] .pt-20pt, [dir] .py-20pt {
    padding-top: 1.25rem !important
}

[dir=ltr] .pr-20pt, [dir=ltr] .px-20pt {
    padding-right: 1.25rem !important
}

[dir] .pb-20pt, [dir] .py-20pt {
    padding-bottom: 1.25rem !important
}

[dir=ltr] .pl-20pt, [dir=ltr] .px-20pt {
    padding-left: 1.25rem !important
}

[dir] .p-24pt {
    padding: 1.5rem !important
}

[dir] .pt-24pt, [dir] .py-24pt {
    padding-top: 1.5rem !important
}

[dir=ltr] .pr-24pt, [dir=ltr] .px-24pt {
    padding-right: 1.5rem !important
}

[dir] .pb-24pt, [dir] .py-24pt {
    padding-bottom: 1.5rem !important
}

[dir=ltr] .pl-24pt, [dir=ltr] .px-24pt {
    padding-left: 1.5rem !important
}

[dir] .p-32pt {
    padding: 2rem !important
}

[dir] .pt-32pt, [dir] .py-32pt {
    padding-top: 2rem !important
}

[dir=ltr] .pr-32pt, [dir=ltr] .px-32pt {
    padding-right: 2rem !important
}

[dir] .pb-32pt, [dir] .py-32pt {
    padding-bottom: 2rem !important
}

[dir=ltr] .pl-32pt, [dir=ltr] .px-32pt {
    padding-left: 2rem !important
}

[dir] .p-40pt {
    padding: 2.5rem !important
}

[dir] .pt-40pt, [dir] .py-40pt {
    padding-top: 2.5rem !important
}

[dir=ltr] .pr-40pt, [dir=ltr] .px-40pt {
    padding-right: 2.5rem !important
}

[dir] .pb-40pt, [dir] .py-40pt {
    padding-bottom: 2.5rem !important
}

[dir=ltr] .pl-40pt, [dir=ltr] .px-40pt {
    padding-left: 2.5rem !important
}

[dir] .p-44pt {
    padding: 2.75rem !important
}

[dir] .pt-44pt, [dir] .py-44pt {
    padding-top: 2.75rem !important
}

[dir=ltr] .pr-44pt, [dir=ltr] .px-44pt {
    padding-right: 2.75rem !important
}

[dir] .pb-44pt, [dir] .py-44pt {
    padding-bottom: 2.75rem !important
}

[dir=ltr] .pl-44pt, [dir=ltr] .px-44pt {
    padding-left: 2.75rem !important
}

[dir] .p-48pt {
    padding: 3rem !important
}

[dir] .pt-48pt, [dir] .py-48pt {
    padding-top: 3rem !important
}

[dir=ltr] .pr-48pt, [dir=ltr] .px-48pt {
    padding-right: 3rem !important
}

[dir] .pb-48pt, [dir] .py-48pt {
    padding-bottom: 3rem !important
}

[dir=ltr] .pl-48pt, [dir=ltr] .px-48pt {
    padding-left: 3rem !important
}

[dir] .p-64pt {
    padding: 4rem !important
}

[dir] .pt-64pt, [dir] .py-64pt {
    padding-top: 4rem !important
}

[dir=ltr] .pr-64pt, [dir=ltr] .px-64pt {
    padding-right: 4rem !important
}

[dir] .pb-64pt, [dir] .py-64pt {
    padding-bottom: 4rem !important
}

[dir=ltr] .pl-64pt, [dir=ltr] .px-64pt {
    padding-left: 4rem !important
}

[dir] .p-112pt {
    padding: 7rem !important
}

[dir] .pt-112pt, [dir] .py-112pt {
    padding-top: 7rem !important
}

[dir=ltr] .pr-112pt, [dir=ltr] .px-112pt {
    padding-right: 7rem !important
}

[dir] .pb-112pt, [dir] .py-112pt {
    padding-bottom: 7rem !important
}

[dir=ltr] .pl-112pt, [dir=ltr] .px-112pt {
    padding-left: 7rem !important
}

[dir] .m-n1 {
    margin: -.25rem !important
}

[dir] .mt-n1, [dir] .my-n1 {
    margin-top: -.25rem !important
}

[dir=ltr] .mr-n1, [dir=ltr] .mx-n1 {
    margin-right: -.25rem !important
}

[dir] .mb-n1, [dir] .my-n1 {
    margin-bottom: -.25rem !important
}

[dir=ltr] .ml-n1, [dir=ltr] .mx-n1 {
    margin-left: -.25rem !important
}

[dir] .m-n2 {
    margin: -.5rem !important
}

[dir] .mt-n2, [dir] .my-n2 {
    margin-top: -.5rem !important
}

[dir=ltr] .mr-n2, [dir=ltr] .mx-n2 {
    margin-right: -.5rem !important
}

[dir] .mb-n2, [dir] .my-n2 {
    margin-bottom: -.5rem !important
}

[dir=ltr] .ml-n2, [dir=ltr] .mx-n2 {
    margin-left: -.5rem !important
}

[dir] .m-n3 {
    margin: -1rem !important
}

[dir] .mt-n3, [dir] .my-n3 {
    margin-top: -1rem !important
}

[dir=ltr] .mr-n3, [dir=ltr] .mx-n3 {
    margin-right: -1rem !important
}

[dir] .mb-n3, [dir] .my-n3 {
    margin-bottom: -1rem !important
}

[dir=ltr] .ml-n3, [dir=ltr] .mx-n3 {
    margin-left: -1rem !important
}

[dir] .m-n4 {
    margin: -1.5rem !important
}

[dir] .mt-n4, [dir] .my-n4 {
    margin-top: -1.5rem !important
}

[dir=ltr] .mr-n4, [dir=ltr] .mx-n4 {
    margin-right: -1.5rem !important
}

[dir] .mb-n4, [dir] .my-n4 {
    margin-bottom: -1.5rem !important
}

[dir=ltr] .ml-n4, [dir=ltr] .mx-n4 {
    margin-left: -1.5rem !important
}

[dir] .m-n5 {
    margin: -3rem !important
}

[dir] .mt-n5, [dir] .my-n5 {
    margin-top: -3rem !important
}

[dir=ltr] .mr-n5, [dir=ltr] .mx-n5 {
    margin-right: -3rem !important
}

[dir] .mb-n5, [dir] .my-n5 {
    margin-bottom: -3rem !important
}

[dir=ltr] .ml-n5, [dir=ltr] .mx-n5 {
    margin-left: -3rem !important
}

[dir] .m-n4pt {
    margin: -.25rem !important
}

[dir] .mt-n4pt, [dir] .my-n4pt {
    margin-top: -.25rem !important
}

[dir=ltr] .mr-n4pt, [dir=ltr] .mx-n4pt {
    margin-right: -.25rem !important
}

[dir] .mb-n4pt, [dir] .my-n4pt {
    margin-bottom: -.25rem !important
}

[dir=ltr] .ml-n4pt, [dir=ltr] .mx-n4pt {
    margin-left: -.25rem !important
}

[dir] .m-n8pt {
    margin: -.5rem !important
}

[dir] .mt-n8pt, [dir] .my-n8pt {
    margin-top: -.5rem !important
}

[dir=ltr] .mr-n8pt, [dir=ltr] .mx-n8pt {
    margin-right: -.5rem !important
}

[dir] .mb-n8pt, [dir] .my-n8pt {
    margin-bottom: -.5rem !important
}

[dir=ltr] .ml-n8pt, [dir=ltr] .mx-n8pt {
    margin-left: -.5rem !important
}

[dir] .m-n12pt {
    margin: -.75rem !important
}

[dir] .mt-n12pt, [dir] .my-n12pt {
    margin-top: -.75rem !important
}

[dir=ltr] .mr-n12pt, [dir=ltr] .mx-n12pt {
    margin-right: -.75rem !important
}

[dir] .mb-n12pt, [dir] .my-n12pt {
    margin-bottom: -.75rem !important
}

[dir=ltr] .ml-n12pt, [dir=ltr] .mx-n12pt {
    margin-left: -.75rem !important
}

[dir] .m-n16pt {
    margin: -1rem !important
}

[dir] .mt-n16pt, [dir] .my-n16pt {
    margin-top: -1rem !important
}

[dir=ltr] .mr-n16pt, [dir=ltr] .mx-n16pt {
    margin-right: -1rem !important
}

[dir] .mb-n16pt, [dir] .my-n16pt {
    margin-bottom: -1rem !important
}

[dir=ltr] .ml-n16pt, [dir=ltr] .mx-n16pt {
    margin-left: -1rem !important
}

[dir] .m-n20pt {
    margin: -1.25rem !important
}

[dir] .mt-n20pt, [dir] .my-n20pt {
    margin-top: -1.25rem !important
}

[dir=ltr] .mr-n20pt, [dir=ltr] .mx-n20pt {
    margin-right: -1.25rem !important
}

[dir] .mb-n20pt, [dir] .my-n20pt {
    margin-bottom: -1.25rem !important
}

[dir=ltr] .ml-n20pt, [dir=ltr] .mx-n20pt {
    margin-left: -1.25rem !important
}

[dir] .m-n24pt {
    margin: -1.5rem !important
}

[dir] .mt-n24pt, [dir] .my-n24pt {
    margin-top: -1.5rem !important
}

[dir=ltr] .mr-n24pt, [dir=ltr] .mx-n24pt {
    margin-right: -1.5rem !important
}

[dir] .mb-n24pt, [dir] .my-n24pt {
    margin-bottom: -1.5rem !important
}

[dir=ltr] .ml-n24pt, [dir=ltr] .mx-n24pt {
    margin-left: -1.5rem !important
}

[dir] .m-n32pt {
    margin: -2rem !important
}

[dir] .mt-n32pt, [dir] .my-n32pt {
    margin-top: -2rem !important
}

[dir=ltr] .mr-n32pt, [dir=ltr] .mx-n32pt {
    margin-right: -2rem !important
}

[dir] .mb-n32pt, [dir] .my-n32pt {
    margin-bottom: -2rem !important
}

[dir=ltr] .ml-n32pt, [dir=ltr] .mx-n32pt {
    margin-left: -2rem !important
}

[dir] .m-n40pt {
    margin: -2.5rem !important
}

[dir] .mt-n40pt, [dir] .my-n40pt {
    margin-top: -2.5rem !important
}

[dir=ltr] .mr-n40pt, [dir=ltr] .mx-n40pt {
    margin-right: -2.5rem !important
}

[dir] .mb-n40pt, [dir] .my-n40pt {
    margin-bottom: -2.5rem !important
}

[dir=ltr] .ml-n40pt, [dir=ltr] .mx-n40pt {
    margin-left: -2.5rem !important
}

[dir] .m-n44pt {
    margin: -2.75rem !important
}

[dir] .mt-n44pt, [dir] .my-n44pt {
    margin-top: -2.75rem !important
}

[dir=ltr] .mr-n44pt, [dir=ltr] .mx-n44pt {
    margin-right: -2.75rem !important
}

[dir] .mb-n44pt, [dir] .my-n44pt {
    margin-bottom: -2.75rem !important
}

[dir=ltr] .ml-n44pt, [dir=ltr] .mx-n44pt {
    margin-left: -2.75rem !important
}

[dir] .m-n48pt {
    margin: -3rem !important
}

[dir] .mt-n48pt, [dir] .my-n48pt {
    margin-top: -3rem !important
}

[dir=ltr] .mr-n48pt, [dir=ltr] .mx-n48pt {
    margin-right: -3rem !important
}

[dir] .mb-n48pt, [dir] .my-n48pt {
    margin-bottom: -3rem !important
}

[dir=ltr] .ml-n48pt, [dir=ltr] .mx-n48pt {
    margin-left: -3rem !important
}

[dir] .m-n64pt {
    margin: -4rem !important
}

[dir] .mt-n64pt, [dir] .my-n64pt {
    margin-top: -4rem !important
}

[dir=ltr] .mr-n64pt, [dir=ltr] .mx-n64pt {
    margin-right: -4rem !important
}

[dir] .m-auto {
    margin: auto !important
}

[dir] .mt-auto, [dir] .my-auto {
    margin-top: auto !important
}

[dir=ltr] .mr-auto, [dir=ltr] .mx-auto {
    margin-right: auto !important
}

[dir] .mb-auto, [dir] .my-auto {
    margin-bottom: auto !important
}

[dir=ltr] .ml-auto, [dir=ltr] .mx-auto {
    margin-left: auto !important
}

@media (min-width: 576px) {
    [dir] .m-sm-0 {
        margin: 0 !important
    }

    [dir] .mt-sm-0, [dir] .my-sm-0 {
        margin-top: 0 !important
    }

    [dir=ltr] .mr-sm-0, [dir=ltr] .mx-sm-0 {
        margin-right: 0 !important
    }

    [dir] .mb-sm-0, [dir] .my-sm-0 {
        margin-bottom: 0 !important
    }

    [dir=ltr] .ml-sm-0, [dir=ltr] .mx-sm-0 {
        margin-left: 0 !important
    }

    [dir] .m-sm-1 {
        margin: .25rem !important
    }

    [dir] .mt-sm-1, [dir] .my-sm-1 {
        margin-top: .25rem !important
    }

    [dir=ltr] .mr-sm-1, [dir=ltr] .mx-sm-1 {
        margin-right: .25rem !important
    }

    [dir] .mb-sm-1, [dir] .my-sm-1 {
        margin-bottom: .25rem !important
    }

    [dir=ltr] .ml-sm-1, [dir=ltr] .mx-sm-1 {
        margin-left: .25rem !important
    }

    [dir] .m-sm-2 {
        margin: .5rem !important
    }

    [dir] .mt-sm-2, [dir] .my-sm-2 {
        margin-top: .5rem !important
    }

    [dir=ltr] .mr-sm-2, [dir=ltr] .mx-sm-2 {
        margin-right: .5rem !important
    }

    [dir] .mb-sm-2, [dir] .my-sm-2 {
        margin-bottom: .5rem !important
    }

    [dir=ltr] .ml-sm-2, [dir=ltr] .mx-sm-2 {
        margin-left: .5rem !important
    }

    [dir] .m-sm-3 {
        margin: 1rem !important
    }

    [dir] .mt-sm-3, [dir] .my-sm-3 {
        margin-top: 1rem !important
    }

    [dir=ltr] .mr-sm-3, [dir=ltr] .mx-sm-3 {
        margin-right: 1rem !important
    }

    [dir] .mb-sm-3, [dir] .my-sm-3 {
        margin-bottom: 1rem !important
    }

    [dir=ltr] .ml-sm-3, [dir=ltr] .mx-sm-3 {
        margin-left: 1rem !important
    }

    [dir] .m-sm-4 {
        margin: 1.5rem !important
    }

    [dir] .mt-sm-4, [dir] .my-sm-4 {
        margin-top: 1.5rem !important
    }

    [dir=ltr] .mr-sm-4, [dir=ltr] .mx-sm-4 {
        margin-right: 1.5rem !important
    }

    [dir] .mb-sm-4, [dir] .my-sm-4 {
        margin-bottom: 1.5rem !important
    }

    [dir=ltr] .ml-sm-4, [dir=ltr] .mx-sm-4 {
        margin-left: 1.5rem !important
    }

    [dir] .m-sm-5 {
        margin: 3rem !important
    }

    [dir] .mt-sm-5, [dir] .my-sm-5 {
        margin-top: 3rem !important
    }

    [dir=ltr] .mr-sm-5, [dir=ltr] .mx-sm-5 {
        margin-right: 3rem !important
    }

    [dir] .mb-sm-5, [dir] .my-sm-5 {
        margin-bottom: 3rem !important
    }

    [dir=ltr] .ml-sm-5, [dir=ltr] .mx-sm-5 {
        margin-left: 3rem !important
    }

    [dir] .m-sm-4pt {
        margin: .25rem !important
    }

    [dir] .mt-sm-4pt, [dir] .my-sm-4pt {
        margin-top: .25rem !important
    }

    [dir=ltr] .mr-sm-4pt, [dir=ltr] .mx-sm-4pt {
        margin-right: .25rem !important
    }

    [dir] .mb-sm-4pt, [dir] .my-sm-4pt {
        margin-bottom: .25rem !important
    }

    [dir=ltr] .ml-sm-4pt, [dir=ltr] .mx-sm-4pt {
        margin-left: .25rem !important
    }

    [dir] .m-sm-8pt {
        margin: .5rem !important
    }

    [dir] .mt-sm-8pt, [dir] .my-sm-8pt {
        margin-top: .5rem !important
    }

    [dir=ltr] .mr-sm-8pt, [dir=ltr] .mx-sm-8pt {
        margin-right: .5rem !important
    }

    [dir] .mb-sm-8pt, [dir] .my-sm-8pt {
        margin-bottom: .5rem !important
    }

    [dir=ltr] .ml-sm-8pt, [dir=ltr] .mx-sm-8pt {
        margin-left: .5rem !important
    }

    [dir] .m-sm-12pt {
        margin: .75rem !important
    }

    [dir] .mt-sm-12pt, [dir] .my-sm-12pt {
        margin-top: .75rem !important
    }

    [dir=ltr] .mr-sm-12pt, [dir=ltr] .mx-sm-12pt {
        margin-right: .75rem !important
    }

    [dir] .mb-sm-12pt, [dir] .my-sm-12pt {
        margin-bottom: .75rem !important
    }

    [dir=ltr] .ml-sm-12pt, [dir=ltr] .mx-sm-12pt {
        margin-left: .75rem !important
    }

    [dir] .m-sm-16pt {
        margin: 1rem !important
    }

    [dir] .mt-sm-16pt, [dir] .my-sm-16pt {
        margin-top: 1rem !important
    }

    [dir=ltr] .mr-sm-16pt, [dir=ltr] .mx-sm-16pt {
        margin-right: 1rem !important
    }

    [dir] .mb-sm-16pt, [dir] .my-sm-16pt {
        margin-bottom: 1rem !important
    }

    [dir=ltr] .ml-sm-16pt, [dir=ltr] .mx-sm-16pt {
        margin-left: 1rem !important
    }

    [dir] .m-sm-20pt {
        margin: 1.25rem !important
    }

    [dir] .mt-sm-20pt, [dir] .my-sm-20pt {
        margin-top: 1.25rem !important
    }

    [dir=ltr] .mr-sm-20pt, [dir=ltr] .mx-sm-20pt {
        margin-right: 1.25rem !important
    }

    [dir] .mb-sm-20pt, [dir] .my-sm-20pt {
        margin-bottom: 1.25rem !important
    }

    [dir=ltr] .ml-sm-20pt, [dir=ltr] .mx-sm-20pt {
        margin-left: 1.25rem !important
    }

    [dir] .m-sm-24pt {
        margin: 1.5rem !important
    }

    [dir] .mt-sm-24pt, [dir] .my-sm-24pt {
        margin-top: 1.5rem !important
    }

    [dir=ltr] .mr-sm-24pt, [dir=ltr] .mx-sm-24pt {
        margin-right: 1.5rem !important
    }

    [dir] .mb-sm-24pt, [dir] .my-sm-24pt {
        margin-bottom: 1.5rem !important
    }

    [dir=ltr] .ml-sm-24pt, [dir=ltr] .mx-sm-24pt {
        margin-left: 1.5rem !important
    }

    [dir] .m-sm-32pt {
        margin: 2rem !important
    }

    [dir] .mt-sm-32pt, [dir] .my-sm-32pt {
        margin-top: 2rem !important
    }

    [dir=ltr] .mr-sm-32pt, [dir=ltr] .mx-sm-32pt {
        margin-right: 2rem !important
    }

    [dir] .mb-sm-32pt, [dir] .my-sm-32pt {
        margin-bottom: 2rem !important
    }

    [dir=ltr] .ml-sm-32pt, [dir=ltr] .mx-sm-32pt {
        margin-left: 2rem !important
    }

    [dir] .m-sm-40pt {
        margin: 2.5rem !important
    }

    [dir] .mt-sm-40pt, [dir] .my-sm-40pt {
        margin-top: 2.5rem !important
    }

    [dir=ltr] .mr-sm-40pt, [dir=ltr] .mx-sm-40pt {
        margin-right: 2.5rem !important
    }

    [dir] .mb-sm-40pt, [dir] .my-sm-40pt {
        margin-bottom: 2.5rem !important
    }

    [dir=ltr] .ml-sm-40pt, [dir=ltr] .mx-sm-40pt {
        margin-left: 2.5rem !important
    }

    [dir=ltr] .pr-lg-3, [dir=ltr] .px-lg-3 {
        padding-right: 1rem !important
    }

    [dir=rtl] .pr-lg-3, [dir=rtl] .px-lg-3 {
        padding-left: 1rem !important
    }

    [dir] .pb-lg-3, [dir] .py-lg-3 {
        padding-bottom: 1rem !important
    }

    [dir=ltr] .pl-lg-3, [dir=ltr] .px-lg-3 {
        padding-left: 1rem !important
    }

    [dir=rtl] .pl-lg-3, [dir=rtl] .px-lg-3 {
        padding-right: 1rem !important
    }

    [dir] .p-lg-4 {
        padding: 1.5rem !important
    }

    [dir] .pt-lg-4, [dir] .py-lg-4 {
        padding-top: 1.5rem !important
    }

    [dir=ltr] .pr-lg-4, [dir=ltr] .px-lg-4 {
        padding-right: 1.5rem !important
    }

    [dir=rtl] .pr-lg-4, [dir=rtl] .px-lg-4 {
        padding-left: 1.5rem !important
    }

    [dir] .pb-lg-4, [dir] .py-lg-4 {
        padding-bottom: 1.5rem !important
    }

    [dir=ltr] .pl-lg-4, [dir=ltr] .px-lg-4 {
        padding-left: 1.5rem !important
    }

    [dir=rtl] .pl-lg-4, [dir=rtl] .px-lg-4 {
        padding-right: 1.5rem !important
    }

    [dir] .p-lg-5 {
        padding: 3rem !important
    }

    [dir] .pt-lg-5, [dir] .py-lg-5 {
        padding-top: 3rem !important
    }

    [dir=ltr] .pr-lg-5, [dir=ltr] .px-lg-5 {
        padding-right: 3rem !important
    }

    [dir=rtl] .pr-lg-5, [dir=rtl] .px-lg-5 {
        padding-left: 3rem !important
    }

    [dir] .pb-lg-5, [dir] .py-lg-5 {
        padding-bottom: 3rem !important
    }

    [dir=ltr] .pl-lg-5, [dir=ltr] .px-lg-5 {
        padding-left: 3rem !important
    }

    [dir=rtl] .pl-lg-5, [dir=rtl] .px-lg-5 {
        padding-right: 3rem !important
    }

    [dir] .p-lg-4pt {
        padding: .25rem !important
    }

    [dir] .pt-lg-4pt, [dir] .py-lg-4pt {
        padding-top: .25rem !important
    }

    [dir=ltr] .pr-lg-4pt, [dir=ltr] .px-lg-4pt {
        padding-right: .25rem !important
    }

    [dir=rtl] .pr-lg-4pt, [dir=rtl] .px-lg-4pt {
        padding-left: .25rem !important
    }

    [dir] .pb-lg-4pt, [dir] .py-lg-4pt {
        padding-bottom: .25rem !important
    }

    [dir=ltr] .pl-lg-4pt, [dir=ltr] .px-lg-4pt {
        padding-left: .25rem !important
    }

    [dir=rtl] .pl-lg-4pt, [dir=rtl] .px-lg-4pt {
        padding-right: .25rem !important
    }

    [dir] .p-lg-8pt {
        padding: .5rem !important
    }

    [dir] .pt-lg-8pt, [dir] .py-lg-8pt {
        padding-top: .5rem !important
    }

    [dir=ltr] .pr-lg-8pt, [dir=ltr] .px-lg-8pt {
        padding-right: .5rem !important
    }

    [dir=rtl] .pr-lg-8pt, [dir=rtl] .px-lg-8pt {
        padding-left: .5rem !important
    }

    [dir] .pb-lg-8pt, [dir] .py-lg-8pt {
        padding-bottom: .5rem !important
    }

    [dir=ltr] .pl-lg-8pt, [dir=ltr] .px-lg-8pt {
        padding-left: .5rem !important
    }

    [dir=rtl] .pl-lg-8pt, [dir=rtl] .px-lg-8pt {
        padding-right: .5rem !important
    }

    [dir] .p-lg-12pt {
        padding: .75rem !important
    }

    [dir] .pt-lg-12pt, [dir] .py-lg-12pt {
        padding-top: .75rem !important
    }

    [dir=ltr] .pr-lg-12pt, [dir=ltr] .px-lg-12pt {
        padding-right: .75rem !important
    }

    [dir=rtl] .pr-lg-12pt, [dir=rtl] .px-lg-12pt {
        padding-left: .75rem !important
    }

    [dir] .pb-lg-12pt, [dir] .py-lg-12pt {
        padding-bottom: .75rem !important
    }

    [dir=ltr] .pl-lg-12pt, [dir=ltr] .px-lg-12pt {
        padding-left: .75rem !important
    }

    [dir=rtl] .pl-lg-12pt, [dir=rtl] .px-lg-12pt {
        padding-right: .75rem !important
    }

    [dir] .p-lg-16pt {
        padding: 1rem !important
    }

    [dir] .pt-lg-16pt, [dir] .py-lg-16pt {
        padding-top: 1rem !important
    }

    [dir=ltr] .pr-lg-16pt, [dir=ltr] .px-lg-16pt {
        padding-right: 1rem !important
    }

    [dir=rtl] .pr-lg-16pt, [dir=rtl] .px-lg-16pt {
        padding-left: 1rem !important
    }

    [dir] .pb-lg-16pt, [dir] .py-lg-16pt {
        padding-bottom: 1rem !important
    }

    [dir=ltr] .pl-lg-16pt, [dir=ltr] .px-lg-16pt {
        padding-left: 1rem !important
    }

    [dir=rtl] .pl-lg-16pt, [dir=rtl] .px-lg-16pt {
        padding-right: 1rem !important
    }

    [dir] .m-lg-n1 {
        margin: -.25rem !important
    }

    [dir] .mt-lg-n1, [dir] .my-lg-n1 {
        margin-top: -.25rem !important
    }

    [dir=ltr] .mr-lg-n1, [dir=ltr] .mx-lg-n1 {
        margin-right: -.25rem !important
    }

    [dir=rtl] .mr-lg-n1, [dir=rtl] .mx-lg-n1 {
        margin-left: -.25rem !important
    }

    [dir] .mb-lg-n1, [dir] .my-lg-n1 {
        margin-bottom: -.25rem !important
    }

    [dir=ltr] .ml-lg-n1, [dir=ltr] .mx-lg-n1 {
        margin-left: -.25rem !important
    }

    [dir=rtl] .ml-lg-n1, [dir=rtl] .mx-lg-n1 {
        margin-right: -.25rem !important
    }

    [dir] .m-lg-n2 {
        margin: -.5rem !important
    }

    [dir] .mt-lg-n2, [dir] .my-lg-n2 {
        margin-top: -.5rem !important
    }

    [dir=ltr] .mr-lg-n2, [dir=ltr] .mx-lg-n2 {
        margin-right: -.5rem !important
    }

    [dir=rtl] .mr-lg-n2, [dir=rtl] .mx-lg-n2 {
        margin-left: -.5rem !important
    }

    [dir] .mb-lg-n2, [dir] .my-lg-n2 {
        margin-bottom: -.5rem !important
    }

    [dir=ltr] .ml-lg-n2, [dir=ltr] .mx-lg-n2 {
        margin-left: -.5rem !important
    }

    [dir=rtl] .ml-lg-n2, [dir=rtl] .mx-lg-n2 {
        margin-right: -.5rem !important
    }

    [dir] .m-lg-n3 {
        margin: -1rem !important
    }

    [dir] .mt-lg-n3, [dir] .my-lg-n3 {
        margin-top: -1rem !important
    }

    [dir=ltr] .mr-lg-n3, [dir=ltr] .mx-lg-n3 {
        margin-right: -1rem !important
    }

    [dir=rtl] .mr-lg-n3, [dir=rtl] .mx-lg-n3 {
        margin-left: -1rem !important
    }

    [dir] .mb-lg-n3, [dir] .my-lg-n3 {
        margin-bottom: -1rem !important
    }

    [dir=ltr] .ml-lg-n3, [dir=ltr] .mx-lg-n3 {
        margin-left: -1rem !important
    }

    [dir=rtl] .ml-lg-n3, [dir=rtl] .mx-lg-n3 {
        margin-right: -1rem !important
    }

    [dir] .m-lg-n4 {
        margin: -1.5rem !important
    }

    [dir] .mt-lg-n4, [dir] .my-lg-n4 {
        margin-top: -1.5rem !important
    }

    [dir=ltr] .mr-lg-n4, [dir=ltr] .mx-lg-n4 {
        margin-right: -1.5rem !important
    }

    [dir=rtl] .mr-lg-n4, [dir=rtl] .mx-lg-n4 {
        margin-left: -1.5rem !important
    }

    [dir] .mb-lg-n4, [dir] .my-lg-n4 {
        margin-bottom: -1.5rem !important
    }

    [dir=ltr] .ml-lg-n4, [dir=ltr] .mx-lg-n4 {
        margin-left: -1.5rem !important
    }

    [dir=rtl] .ml-lg-n4, [dir=rtl] .mx-lg-n4 {
        margin-right: -1.5rem !important
    }

    [dir] .m-lg-n5 {
        margin: -3rem !important
    }

    [dir] .mt-lg-n5, [dir] .my-lg-n5 {
        margin-top: -3rem !important
    }

    [dir=ltr] .mr-lg-n5, [dir=ltr] .mx-lg-n5 {
        margin-right: -3rem !important
    }

    [dir=rtl] .mr-lg-n5, [dir=rtl] .mx-lg-n5 {
        margin-left: -3rem !important
    }

    [dir] .mb-lg-n5, [dir] .my-lg-n5 {
        margin-bottom: -3rem !important
    }

    [dir=ltr] .ml-lg-n5, [dir=ltr] .mx-lg-n5 {
        margin-left: -3rem !important
    }

    [dir=rtl] .ml-lg-n5, [dir=rtl] .mx-lg-n5 {
        margin-right: -3rem !important
    }

    [dir] .m-lg-n4pt {
        margin: -.25rem !important
    }

    [dir] .mt-lg-n4pt, [dir] .my-lg-n4pt {
        margin-top: -.25rem !important
    }

    [dir=ltr] .mr-lg-n4pt, [dir=ltr] .mx-lg-n4pt {
        margin-right: -.25rem !important
    }

    [dir=rtl] .mr-lg-n4pt, [dir=rtl] .mx-lg-n4pt {
        margin-left: -.25rem !important
    }

    [dir] .mb-lg-n4pt, [dir] .my-lg-n4pt {
        margin-bottom: -.25rem !important
    }

    [dir=ltr] .ml-lg-n4pt, [dir=ltr] .mx-lg-n4pt {
        margin-left: -.25rem !important
    }

    [dir=rtl] .ml-lg-n4pt, [dir=rtl] .mx-lg-n4pt {
        margin-right: -.25rem !important
    }

    [dir] .m-lg-auto {
        margin: auto !important
    }

    [dir] .mt-lg-auto, [dir] .my-lg-auto {
        margin-top: auto !important
    }

    [dir=ltr] .mr-lg-auto, [dir=ltr] .mx-lg-auto {
        margin-right: auto !important
    }

    [dir=rtl] .mr-lg-auto, [dir=rtl] .mx-lg-auto {
        margin-left: auto !important
    }

    [dir] .mb-lg-auto, [dir] .my-lg-auto {
        margin-bottom: auto !important
    }

    [dir=ltr] .ml-lg-auto, [dir=ltr] .mx-lg-auto {
        margin-left: auto !important
    }

    [dir=rtl] .ml-lg-auto, [dir=rtl] .mx-lg-auto {
        margin-right: auto !important
    }
}

[dir] .text-justify {
    text-align: justify !important
}

.text-wrap {
    white-space: normal !important
}

.text-nowrap {
    white-space: nowrap !important
}

.text-truncate {
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap
}

[dir=ltr] .text-left {
    text-align: left !important
}

[dir=ltr] .text-right, [dir=rtl] .text-left {
    text-align: right !important
}

[dir=rtl] .text-right {
    text-align: left !important
}

[dir] .text-center {
    text-align: center !important
}

@media (min-width: 576px) {
    [dir=ltr] .text-sm-left {
        text-align: left !important
    }

    [dir=ltr] .text-sm-right, [dir=rtl] .text-sm-left {
        text-align: right !important
    }

    [dir=rtl] .text-sm-right {
        text-align: left !important
    }

    [dir] .text-sm-center {
        text-align: center !important
    }
}

.text-lowercase {
    text-transform: lowercase !important
}

.text-uppercase {
    text-transform: uppercase !important
}

.text-capitalize {
    text-transform: capitalize !important
}

.font-weight-light {
    font-weight: 300 !important
}

.font-weight-lighter {
    font-weight: lighter !important
}

.font-weight-normal {
    font-weight: 400 !important
}

.font-weight-bold {
    font-weight: 500 !important;
    color: #303840 !important;
}

.font-weight-bolder {
    font-weight: bolder !important;
    color: #303840 !important;
}

.font-italic {
    font-style: italic !important;
    color: #303840;
}

.text-white {
    color: #fff !important
}

.text-primary {
    color: var(--primary) !important
}

a.text-primary:focus, a.text-primary:hover {
    color: #0923ff !important
}

.text-secondary {
    color: #868e96 !important
}

a.text-secondary:focus, a.text-secondary:hover {
    color: #60686f !important
}

.text-success {
    color: #04A759 !important
}

a.text-success:focus, a.text-success:hover {
    color: #538628 !important
}

.text-info {
    color: #17a2b8 !important
}

a.text-info:focus, a.text-info:hover {
    color: #0f6674 !important
}

.text-warning {
    color: #e4a93c !important
}

a.text-warning:focus, a.text-warning:hover {
    color: #ba821a !important
}

.text-danger {
    color: #ED4B48 !important
}

a.text-danger:focus, a.text-danger:hover {
    color: #b52b27 !important
}

.text-light {
    color: #f8f9fa !important
}

a.text-light:focus, a.text-light:hover {
    color: #cbd3da !important
}

.text-dark {
    color: #303840 !important
}

a.text-dark:focus, a.text-dark:hover {
    color: #0f1214 !important
}

.text-black {
    color: #272c33 !important
}

a.text-black:focus, a.text-black:hover {
    color: #060708 !important
}

.text-accent {
    color: #ed0b4c !important
}

a.text-accent:focus, a.text-accent:hover {
    color: #a40835 !important
}

.text-accent-red {
    color: #ed0b4c !important
}

a.text-accent-red:focus, a.text-accent-red:hover {
    color: #a40835 !important
}

.text-accent-yellow {
    color: #e4a93c !important
}

a.text-accent-yellow:focus, a.text-accent-yellow:hover {
    color: #ba821a !important
}

.text-accent-dodger-blue {
    color: var(--primary) !important
}

a.text-accent-dodger-blue:focus, a.text-accent-dodger-blue:hover {
    color: #0923ff !important
}

.text-accent-pickled-bluewood {
    color: #303956 !important
}

.text-primary-red {
    color: #ed0b4c !important
}

a.text-primary-red:focus, a.text-primary-red:hover {
    color: #a40835 !important
}

.text-primary-yellow {
    color: #e4a93c !important
}

a.text-primary-yellow:focus, a.text-primary-yellow:hover {
    color: #ba821a !important
}

.text-body {
    color: #272c33 !important
}

.text-black-50, .text-muted {
    color: rgba(39, 44, 51, .5) !important
}

.text-decoration-none {
    text-decoration: none !important
}

.text-break {
    word-break: break-word !important;
    word-wrap: break-word !important
}

.text-reset {
    color: inherit !important
}

.visible {
    visibility: visible !important
}

.invisible {
    visibility: hidden !important
}

@media print {
    [dir] *, [dir] :after, [dir] :before {
        text-shadow: none !important;
        box-shadow: none !important
    }

    a:not(.btn) {
        text-decoration: underline
    }

    abbr[title]:after {
        content: " (" attr(title) ")"
    }

    blockquote, pre {
        page-break-inside: avoid
    }

    [dir] blockquote, [dir] pre {
        border: 1px solid #adb5bd
    }

    thead {
        display: table-header-group
    }

    img, tr {
        page-break-inside: avoid
    }

    h2, h3, p {
        orphans: 3;
        widows: 3
    }

    h2, h3 {
        page-break-after: avoid
    }

    @page {
        size: a3
    }

    .container, body {
        min-width: 992px !important
    }

    .navbar {
        display: none
    }

    [dir] .badge {
        border: 1px solid #272c33
    }

    .table {
        border-collapse: collapse !important
    }

    [dir] .table td, [dir] .table th {
        background-color: #fff !important
    }

    [dir] .table-bordered td, [dir] .table-bordered th {
        border: 1px solid #dee2e6 !important
    }

    .table-dark {
        color: inherit
    }

    [dir] .table-dark tbody + tbody, [dir] .table-dark td, [dir] .table-dark th, [dir] .table-dark thead th {
        border-color: #e9edf2
    }

    .table .thead-dark th {
        color: inherit
    }

    [dir] .table .thead-dark th {
        border-color: #e9edf2
    }
}

.custom-checkbox-toggle {
    width: 3rem;
    height: 1.5rem
}

[dir=ltr] .custom-checkbox-toggle {
    padding-left: 0
}

[dir] .flatpickr-input[readonly] {
    background-color: #fff
}

.flatpickr-wrapper {
    display: block
}

.flatpickr-hidden-input {
    visibility: hidden;
    height: 0
}

[dir=ltr] .flatpickr-calendar-right .flatpickr-calendar {
    right: -1.5rem
}

[dir=rtl] .flatpickr-calendar-right .flatpickr-calendar {
    left: -1.5rem
}

@media (min-width: 576px) {
    [dir=ltr] .flatpickr-calendar-right .flatpickr-calendar {
        right: 0
    }

    [dir=rtl] .flatpickr-calendar-right .flatpickr-calendar {
        left: 0
    }
}

[dir] .flatpickr-calendar.inline {
    box-shadow: none;
    border: none
}

.flatpickr-calendar.inline:after, .flatpickr-calendar.inline:before {
    display: none
}

.flatpickr-calendar.inline, .flatpickr-calendar.inline .dayContainer, .flatpickr-calendar.inline .flatpickr-days {
    width: 100%;
    max-width: none
}

.flatpickr-wrapper .flatpickr-input {
    height: 0;
    visibility: hidden
}

[dir] .flatpickr-wrapper .flatpickr-input {
    padding: 0
}

.chart {
    position: relative;
    height: 300px
}

.chart-legend {
    display: flex;
    white-space: nowrap;
    justify-content: center
}

[dir] .chart-legend {
    margin-top: 2.5rem
}

.chart-legend-item {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: .8125rem;
    color: rgba(39, 44, 51, .5)
}

[dir] .chart-legend-item {
    padding: .25rem .5rem;
    border: 1px solid #e9edf2;
    border-radius: .2rem
}

.chart-legend-indicator {
    display: block;
    width: .5rem;
    height: .5rem
}

[dir] .chart-legend-indicator {
    border-radius: 50%
}

[dir=ltr] .chart-legend-indicator {
    margin-right: .25rem
}

[dir=rtl] .chart-legend-indicator {
    margin-left: .25rem
}

[dir=ltr] .chart-legend:not(.chart-legend--vertical) .chart-legend-item + .chart-legend-item {
    margin-left: .5rem
}

[dir=rtl] .chart-legend:not(.chart-legend--vertical) .chart-legend-item + .chart-legend-item {
    margin-right: .5rem
}

.chart-legend--vertical {
    flex-direction: column
}

[dir] .chart-legend--vertical .chart-legend-item + .chart-legend-item {
    margin-top: .5rem
}

#chart-tooltip {
    z-index: 0;
    pointer-events: none
}

[dir] #chart-tooltip .popover-header {
    border-bottom: 0
}

#chart-tooltip .popover-body {
    text-transform: uppercase;
    font-size: .707rem;
    color: rgba(39, 44, 51, .7)
}

[dir] #chart-tooltip .popover-body + .popover-body {
    padding-top: 0
}

[dir=ltr] #chart-tooltip .popover-body-label, [dir=ltr] #chart-tooltip .popover-body-value {
    margin-left: .25rem
}

[dir=ltr] #chart-tooltip .popover-body-indicator, [dir=rtl] #chart-tooltip .popover-body-label, [dir=rtl] #chart-tooltip .popover-body-value {
    margin-right: .25rem
}

[dir=rtl] #chart-tooltip .popover-body-indicator {
    margin-left: .25rem
}

#chart-tooltip .arrow {
    top: 100%
}

[dir=ltr] #chart-tooltip .arrow {
    left: 50%;
    transform: translateX(-50%) translateX(-.5rem)
}

[dir=rtl] #chart-tooltip .arrow {
    right: 50%;
    transform: translateX(50%) translateX(.5rem)
}

#chart-tooltip .popover-body-indicator {
    display: inline-block;
    width: .5rem;
    height: .5rem
}

[dir] #chart-tooltip .popover-body-indicator {
    border-radius: 50%
}

.avatar {
    font-size: 1rem;
    display: inline-block;
    width: 3rem;
    height: 3rem;
    position: relative;
    z-index: 0
}

.avatar-img {
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover
}

.avatar-title {
    display: flex;
    width: 100%;
    height: 100%;
    align-items: center;
    justify-content: center
}

.avatar-xl, .avatar-xxl {
    font-size: 1.70833rem;
    width: 5.125rem;
}

.avatar-lg {
    font-size: 1.33333rem;
    width: 4rem;
    height: 4rem
}

.avatar-sm {
    width: 2.5rem;
    height: 2.5rem
}

.avatar-xs {
    font-size: .54167rem;
    width: 1.625rem;
    height: 1.625rem
}

@media (min-width: 768px) {
    .avatar-xxl {
        font-size: 2.66667rem;
        width: 8rem;
    }
}

.avatar-group {
    display: inline-flex
}

.avatar-group .avatar:hover {
    z-index: 1
}

[dir] .avatar-group .avatar-img, [dir] .avatar-group .avatar-title {
    border: 2px solid #fff
}

[dir=ltr] .avatar-group .avatar + .avatar {
    margin-left: -.75rem
}

[dir=rtl] .avatar-group .avatar + .avatar {
    margin-right: -.75rem
}

[dir=ltr] .avatar-group .avatar-lg + .avatar-lg {
    margin-left: -1rem
}

[dir=rtl] .avatar-group .avatar-lg + .avatar-lg {
    margin-right: -1rem
}

[dir=ltr] .avatar-group .avatar-xl + .avatar-xl {
    margin-left: -1.28125rem
}

[dir=rtl] .avatar-group .avatar-xl + .avatar-xl {
    margin-right: -1.28125rem
}

[dir=ltr] .avatar-group .avatar-xxl + .avatar-xxl {
    margin-left: -2rem
}

[dir=rtl] .avatar-group .avatar-xxl + .avatar-xxl {
    margin-right: -2rem
}

[dir=ltr] .avatar-group .avatar-sm + .avatar-sm {
    margin-left: -.625rem
}

[dir=rtl] .avatar-group .avatar-sm + .avatar-sm {
    margin-right: -.625rem
}

[dir=ltr] .avatar-group .avatar-xs + .avatar-xs {
    margin-left: -.40625rem
}

[dir=rtl] .avatar-group .avatar-xs + .avatar-xs {
    margin-right: -.40625rem
}

[dir] .avatar-list > .avatar, [dir] .avatar-list > .avatar-group {
    margin-bottom: .75rem
}

[dir=ltr] .avatar-list > .avatar, [dir=ltr] .avatar-list > .avatar-group {
    margin-left: .5rem
}

[dir=rtl] .avatar-list > .avatar, [dir=rtl] .avatar-list > .avatar-group {
    margin-right: .5rem
}

.is-loading {
    position: relative;
    color: transparent !important
}

.is-loading:after, .loader {
    display: block;
    width: 1.5rem;
    height: 1.5rem
}

[dir] .is-loading:after, [dir] .loader {
    border: 3px solid #272c33;
    border-bottom-color: transparent;
    border-radius: 50%;
    background: transparent
}

[dir=ltr] .is-loading:after, [dir=ltr] .loader {
    -webkit-animation: is-loading-ltr 1s linear infinite;
    animation: is-loading-ltr 1s linear infinite
}

[dir=rtl] .is-loading:after, [dir=rtl] .loader {
    -webkit-animation: is-loading-rtl 1s linear infinite;
    animation: is-loading-rtl 1s linear infinite
}

.is-loading-sm:after, .loader-sm {
    width: 1rem;
    height: 1rem
}

[dir] .is-loading-sm:after, [dir] .loader-sm {
    border-width: 2px
}

.is-loading-lg:after, .loader-lg {
    width: 2rem;
    height: 2rem
}

[dir] .is-loading-lg:after, [dir] .loader-lg {
    border-width: 5px
}

.is-loading > * {
    opacity: 0 !important
}

.is-loading:after {
    position: absolute;
    top: calc(50% - .75rem);
    content: ""
}

[dir=ltr] .is-loading:after {
    left: calc(50% - .75rem)
}

.is-loading-sm:after {
    top: calc(50% - .5rem)
}

[dir=ltr] .is-loading-sm:after {
    left: calc(50% - .5rem)
}

.is-loading-lg:after {
    top: calc(50% - 1rem)
}

[dir=ltr] .is-loading-lg:after {
    left: calc(50% - 1rem)
}

[dir] .btn-outline-primary.is-loading:after, [dir] .is-loading-primary:after, [dir] .loader-primary {
    border-color: var(--primary) var(--primary) transparent
}

[dir] .btn-primary.is-loading:after {
    border-color: #fff #fff transparent
}

[dir] .btn-outline-secondary.is-loading:after, [dir] .is-loading-secondary:after, [dir] .loader-secondary {
    border-color: #868e96 #868e96 transparent
}

[dir] .btn-secondary.is-loading:after {
    border-color: #fff #fff transparent
}

[dir] .btn-outline-success.is-loading:after, [dir] .is-loading-success:after, [dir] .loader-success {
    border-color: #77c13a #77c13a transparent
}

[dir] .btn-success.is-loading:after {
    border-color: #212529 #212529 transparent
}

[dir] .btn-outline-info.is-loading:after, [dir] .is-loading-info:after, [dir] .loader-info {
    border-color: #17a2b8 #17a2b8 transparent
}

[dir] .btn-info.is-loading:after {
    border-color: #fff #fff transparent
}

[dir] .btn-outline-warning.is-loading:after, [dir] .is-loading-warning:after, [dir] .loader-warning {
    border-color: #e4a93c #e4a93c transparent
}

[dir] .btn-warning.is-loading:after {
    border-color: #212529 #212529 transparent
}

[dir] .btn-outline-danger.is-loading:after, [dir] .is-loading-danger:after, [dir] .loader-danger {
    border-color: #d9534f #d9534f transparent
}

[dir] .btn-danger.is-loading:after {
    border-color: #fff #fff transparent
}

[dir] .btn-outline-light.is-loading:after, [dir] .is-loading-light:after, [dir] .loader-light {
    border-color: #f8f9fa #f8f9fa transparent
}

[dir] .btn-light.is-loading:after, [dir] .navbar-light .is-loading.navbar-btn:after {
    border-color: #212529 #212529 transparent
}

[dir] .btn-outline-dark.is-loading:after, [dir] .is-loading-dark:after, [dir] .loader-dark {
    border-color: #303840 #303840 transparent
}

[dir] .btn-dark.is-loading:after {
    border-color: #fff #fff transparent
}

[dir] .btn-outline-black.is-loading:after, [dir] .is-loading-black:after, [dir] .loader-black {
    border-color: #272c33 #272c33 transparent
}

[dir] .btn-black.is-loading:after, [dir] .navbar-dark .is-loading.navbar-btn:after {
    border-color: #fff #fff transparent
}

[dir] .btn-outline-accent.is-loading:after, [dir] .is-loading-accent:after, [dir] .loader-accent {
    border-color: #ed0b4c #ed0b4c transparent
}

[dir] .btn-accent.is-loading:after {
    border-color: #fff #fff transparent
}

[dir] .btn-outline-accent-red.is-loading:after, [dir] .is-loading-accent-red:after, [dir] .loader-accent-red {
    border-color: #ed0b4c #ed0b4c transparent
}

[dir] .btn-accent-red.is-loading:after {
    border-color: #fff #fff transparent
}

[dir] .btn-outline-accent-yellow.is-loading:after, [dir] .is-loading-accent-yellow:after, [dir] .loader-accent-yellow {
    border-color: #e4a93c #e4a93c transparent
}

[dir] .btn-accent-yellow.is-loading:after {
    border-color: #212529 #212529 transparent
}

[dir] .btn-outline-accent-dodger-blue.is-loading:after, [dir] .is-loading-accent-dodger-blue:after, [dir] .loader-accent-dodger-blue {
    border-color: var(--primary) var(--primary) transparent
}

[dir] .btn-accent-dodger-blue.is-loading:after {
    border-color: #fff #fff transparent
}

[dir] .btn-outline-accent-pickled-bluewood.is-loading:after, [dir] .is-loading-accent-pickled-bluewood:after, [dir] .loader-accent-pickled-bluewood {
    border-color: #303956 #303956 transparent
}

[dir] .btn-accent-pickled-bluewood.is-loading:after {
    border-color: #fff #fff transparent
}

[dir] .btn-outline-accent-electric-violet.is-loading:after, [dir] .is-loading-accent-electric-violet:after, [dir] .loader-accent-electric-violet {
    border-color: #9c42ff #9c42ff transparent
}

[dir] .btn-accent-electric-violet.is-loading:after {
    border-color: #fff #fff transparent
}

[dir] .btn-outline-primary-purple.is-loading:after, [dir] .is-loading-primary-purple:after, [dir] .loader-primary-purple {
    border-color: #824ee1 #824ee1 transparent
}

[dir] .btn-primary-purple.is-loading:after {
    border-color: #fff #fff transparent
}

[dir] .btn-outline-primary-red.is-loading:after, [dir] .is-loading-primary-red:after, [dir] .loader-primary-red {
    border-color: #ed0b4c #ed0b4c transparent
}

[dir] .btn-primary-red.is-loading:after {
    border-color: #fff #fff transparent
}

[dir] .btn-outline-primary-yellow.is-loading:after, [dir] .is-loading-primary-yellow:after, [dir] .loader-primary-yellow {
    border-color: #e4a93c #e4a93c transparent
}

[dir] .btn-primary-yellow.is-loading:after {
    border-color: #212529 #212529 transparent
}

[dir] .btn-outline-primary-light.is-loading:after, [dir] .is-loading-primary-light:after, [dir] .loader-primary-light {
    border-color: #9cf #9cf transparent
}

[dir] .btn-primary-light.is-loading:after {
    border-color: #212529 #212529 transparent
}

[dir] .btn-outline-primary-dodger-blue.is-loading:after, [dir] .is-loading-primary-dodger-blue:after, [dir] .loader-primary-dodger-blue {
    border-color: var(--primary) var(--primary) transparent
}

[dir] .btn-primary-dodger-blue.is-loading:after {
    border-color: #fff #fff transparent
}

[dir] .btn-outline-primary-pickled-bluewood.is-loading:after, [dir] .is-loading-primary-pickled-bluewood:after, [dir] .loader-primary-pickled-bluewood {
    border-color: #303956 #303956 transparent
}

[dir] .btn-primary-pickled-bluewood.is-loading:after {
    border-color: #fff #fff transparent
}

@-webkit-keyframes is-loading-ltr {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
}

@keyframes is-loading-ltr {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(1turn)
    }
}

@-webkit-keyframes is-loading-rtl {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(-1turn)
    }
}

@keyframes is-loading-rtl {
    0% {
        transform: rotate(0deg)
    }
    to {
        transform: rotate(-1turn)
    }
}

.loader-list {
    display: flex;
    align-items: center
}

[dir] .loader-list > .loader {
    margin-bottom: .75rem
}

[dir=ltr] .loader-list > .loader {
    margin-left: .5rem
}

.corner-ribbon {
    white-space: nowrap;
    position: absolute;
    overflow: hidden;
    font-weight: 500;
    box-sizing: border-box
}

[dir] .corner-ribbon {
    margin: 0;
    text-align: center
}

.corner-ribbon--default-left-top {
    height: 30px;
    width: 100px;
    line-height: 30px;
    top: -30px
}

[dir=ltr] .corner-ribbon--default-left-top {
    transform-origin: 100% 100%;
    transform: rotate(-45deg);
    padding-right: -30px;
    padding-left: -29px;
    left: -30px
}

.corner-ribbon--default-left-bottom {
    height: 30px;
    width: 100px;
    line-height: 30px;
    bottom: -30px
}

[dir=ltr] .corner-ribbon--default-left-bottom {
    transform-origin: 100% 0;
    transform: rotate(45deg);
    padding-right: 31px;
    padding-left: 30px;
    left: -30px
}

.corner-ribbon--default-right-top {
    height: 30px;
    width: 100px;
    line-height: 30px;
    top: -30px
}

[dir=ltr] .corner-ribbon--default-right-top {
    transform-origin: 0 100%;
    transform: rotate(45deg);
    padding-right: 31px;
    padding-left: 30px;
    right: -30px
}

.corner-ribbon--default-right-bottom {
    height: 30px;
    width: 100px;
    line-height: 30px;
    bottom: -30px
}

[dir=ltr] .corner-ribbon--default-right-bottom {
    transform-origin: 0 0;
    transform: rotate(-45deg);
    padding-right: -30px;
    padding-left: -29px;
    right: -30px
}

.corner-ribbon--fixed {
    position: fixed
}

[dir] .corner-ribbon--shadow {
    box-shadow: 0 0 3px rgba(0, 0, 0, .3)
}

.corner-ribbon--primary {
    color: #fff
}

[dir] .corner-ribbon--primary {
    background-color: #2c84dd
}

.corner-ribbon--success {
    color: #fff
}

[dir] .corner-ribbon--success {
    background-color: #77c13a
}

.mdk-header {
    position: relative;
    z-index: 1;
    height: 200px
}

[dir] .mdk-header {
    margin-bottom: 1.25rem
}

[dir] .mdk-header :last-child {
    margin-bottom: 0
}

.mdk-header[data-condenses], .mdk-header[data-reveals] {
    will-change: transform
}

.mdk-header [data-fx-condenses] {
    opacity: 0
}

[dir=ltr] .mdk-header [data-fx-condenses] {
    transform-origin: left top
}

.mdk-header [data-fx-condenses][data-fx-id] {
    opacity: 1
}

.mdk-header [data-primary] {
    z-index: 1
}

.mdk-header__bg {
    position: absolute;
    top: 0;
    bottom: 0;
    overflow: hidden
}

[dir=ltr] .mdk-header__bg {
    right: 0;
    left: 0
}

[data-effects*=parallax-background] .mdk-header__bg {
    visibility: hidden
}

.mdk-header__bg-front, .mdk-header__bg-rear {
    position: absolute;
    top: 0;
    bottom: 0;
    pointer-events: none
}

[dir] .mdk-header__bg-front, [dir] .mdk-header__bg-rear {
    background-size: cover
}

[dir=ltr] .mdk-header__bg-front, [dir=ltr] .mdk-header__bg-rear {
    right: 0;
    left: 0
}

.mdk-header__bg-rear {
    opacity: 0
}

.mdk-header__content {
    position: relative;
    width: 100%;
    height: 100%
}

.mdk-header[data-condenses] .mdk-header__content {
    z-index: 0
}

.mdk-header[data-condenses] [data-primary] {
    z-index: 1
}

.mdk-header--shadow:after {
    position: absolute;
    bottom: -5px;
    width: 100%;
    height: 5px;
    content: "";
    pointer-events: none;
    opacity: 0;
    will-change: opacity;
    z-index: -1
}

[dir] .mdk-header--shadow:after {
    box-shadow: inset 0 5px 6px -3px rgba(0, 0, 0, .4)
}

[dir=ltr] .mdk-header--shadow:after {
    right: 0;
    left: 0
}

.mdk-header--shadow-show:after {
    opacity: 1
}

.mdk-header--fixed {
    position: fixed;
    top: 0;
    width: 100%
}

[dir] .mdk-header--fixed {
    margin-bottom: 0
}

[dir=ltr] .mdk-header--fixed {
    left: 0
}

.mdk-header[disabled], .mdk-header[disabled] .mdk-header__bg-front, .mdk-header[disabled] .mdk-header__bg-rear, .mdk-header[disabled] .mdk-header__content > [data-primary], .mdk-header[disabled]:after {
    transition: none !important
}

.mdk-header-layout {
    height: 100%;
    position: relative;
    overflow: hidden;
    z-index: 0
}

.mdk-header-layout--fullbleed {
    position: absolute;
    top: 0;
    bottom: 0
}

[dir=ltr] .mdk-header-layout--fullbleed {
    left: 0;
    right: 0
}

.mdk-header-layout > .mdk-header {
    position: fixed;
    top: 0;
    z-index: 1
}

[dir=ltr] .mdk-header-layout > .mdk-header {
    left: 0;
    right: 0
}

.mdk-header-layout[data-fullbleed] > .mdk-header, .mdk-header-layout[data-has-scrolling-region] > .mdk-header {
    position: absolute;
    top: 0
}

[dir=ltr] .mdk-header-layout[data-fullbleed] > .mdk-header, [dir=ltr] .mdk-header-layout[data-has-scrolling-region] > .mdk-header, [dir=rtl] .mdk-header-layout[data-fullbleed] > .mdk-header, [dir=rtl] .mdk-header-layout[data-has-scrolling-region] > .mdk-header {
    left: 0;
    right: 0
}

.mdk-header-layout__content {
    position: relative;
    z-index: 0
}

.mdk-header-layout__content--fullbleed {
    position: absolute;
    top: 0;
    bottom: 0
}

[dir=ltr] .mdk-header-layout__content--fullbleed {
    left: 0;
    right: 0
}

.mdk-header-layout__content--scrollable {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch
}

.mdk-box {
    position: relative
}

[dir] .mdk-box {
    margin-bottom: 1.25rem
}

.mdk-box__bg {
    position: absolute;
    top: 0;
    bottom: 0;
    overflow: hidden;
    height: 100%
}

[dir=ltr] .mdk-box__bg {
    right: 0;
    left: 0
}

[data-effects*=parallax-background] .mdk-box__bg {
    visibility: hidden
}

.mdk-box__bg-front, .mdk-box__bg-rear {
    position: absolute;
    top: 0;
    bottom: 0;
    pointer-events: none
}

[dir] .mdk-box__bg-front, [dir] .mdk-box__bg-rear {
    background-size: cover
}

[dir=ltr] .mdk-box__bg-front, [dir=ltr] .mdk-box__bg-rear {
    right: 0;
    left: 0
}

.mdk-box__bg-rear {
    opacity: 0
}

.mdk-box__content {
    position: relative;
    width: 100%;
    height: 100%
}

.mdk-box[disabled], .mdk-box[disabled] .mdk-box__bg-front, .mdk-box[disabled] .mdk-box__bg-rear, .mdk-box[disabled] .mdk-box__content > [data-primary], .mdk-box[disabled]:after {
    transition: none !important
}

.mdk-drawer {
    position: relative;
    visibility: hidden
}

.mdk-drawer[data-closing], .mdk-drawer[data-opened] {
    visibility: visible
}

.mdk-drawer[data-persistent] {
    width: 256px
}

.mdk-drawer[data-persistent][data-position=left] {
    right: auto
}

.mdk-drawer[data-persistent][data-position=right] {
    left: auto
}

.mdk-drawer__content {
    position: fixed;
    top: 0;
    bottom: 0;
    width: 256px;
    transition: transform .2s ease;
    left: 0;
    transform: translate3d(-100%, 0, 0)
}

[dir] .mdk-drawer__content {
    background-color: #fff
}

[data-position=right] > .mdk-drawer__content {
    right: 0;
    left: auto;
    transform: translate3d(100%, 0, 0)
}

[dir] [data-opened] > .mdk-drawer__content {
    transform: translateZ(0)
}

.mdk-drawer__scrim {
    position: fixed;
    top: 0;
    bottom: 0;
    transition: opacity .2s ease;
    opacity: 0
}

[dir] .mdk-drawer__scrim {
    transform: translateZ(0);
    background-color: rgba(0, 0, 0, .5)
}

[dir=ltr] .mdk-drawer__scrim {
    left: 0;
    right: 0
}

[data-opened] > .mdk-drawer__scrim {
    opacity: 1
}

[data-persistent] > .mdk-drawer__scrim {
    visibility: hidden;
    opacity: 0
}

.mdk-drawer-layout {
    height: 100%;
    position: relative;
    overflow: hidden;
    display: flex
}

.mdk-drawer-layout--fullbleed {
    position: absolute;
    top: 0;
    bottom: 0
}

[dir=ltr] .mdk-drawer-layout--fullbleed {
    left: 0;
    right: 0
}

.mdk-drawer-layout .mdk-drawer {
    position: relative
}

.mdk-drawer-layout .mdk-drawer[data-persistent] {
    width: 256px
}

[dir=ltr] .mdk-drawer-layout .mdk-drawer[data-persistent][data-position=left] {
    order: 0
}

[dir=ltr] .mdk-drawer-layout .mdk-drawer[data-persistent][data-position=right] {
    order: 2
}

.mdk-drawer-layout__content {
    position: relative;
    height: 100%;
    transition: transform .2s;
    min-width: 320px;
    z-index: 0;
    order: 1;
    flex: 1 1 0;
    margin: 2em;
}

.mdk-drawer-layout__content--scrollable {
    overflow-y: auto;
    -webkit-overflow-scrolling: touch
}

.mdk-reveal {
    z-index: 0;
    position: relative;
    overflow: hidden;
    display: block
}

.mdk-reveal--hover:hover .mdk-reveal__partial, .mdk-reveal[data-opened] .mdk-reveal__partial {
    opacity: 0
}

.mdk-reveal__content {
    transition: transform .2s;
    position: relative;
    z-index: 0
}

[dir] .mdk-reveal__content {
    background-color: #fff
}

.mdk-reveal__partial {
    position: absolute;
    top: 0;
    width: 100%;
    content: "";
    opacity: 1;
    z-index: 1;
    transition: opacity .2s;
    pointer-events: none
}

[dir] .mdk-reveal__partial {
    background: linear-gradient(180deg, hsla(0, 0%, 100%, 0) 60%, hsla(0, 0%, 100%, .95) 80%, #fff)
}

[dir=ltr] .mdk-reveal__partial {
    left: 0
}

.mdk-carousel__content {
    transition: transform .4s;
    touch-action: pan-x
}

[dir] .mdk-carousel__content {
    transform: translateZ(0)
}

.mdk-carousel__item {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    -webkit-touch-callout: none;
    width: 100%
}

[dir] .mdk-carousel__item {
    cursor: move;
    cursor: grab
}

[dir=ltr] .mdk-carousel__item {
    float: left
}

.mdk-tooltip {
    transition: transform .2s;
    will-change: transform;
    display: inline-block;
    color: #fff;
    font-size: 13px;
    z-index: 999;
    font-weight: 500;
    position: fixed;
    line-height: 1;
    max-width: 200px;
    pointer-events: none
}

[dir] .mdk-tooltip {
    transform: scale(0);
    transform-origin: top center;
    background: rgba(0, 0, 0, .7);
    text-align: center;
    padding: .4rem;
    border-radius: .25rem
}

[dir] .mdk-tooltip[data-opened] {
    transform: scale(1)
}

.flex {
    flex: 1 1 0% !important
}

.flex-0, .flex-none {
    flex: none !important
}

.flex-grow {
    flex: 1 1 auto !important
}

.flex-shrink-0 {
    flex-shrink: 0 !important
}

@media (min-width: 576px) {
    .flex-sm {
        flex: 1 1 0% !important
    }

    .flex-sm-0, .flex-sm-none {
        flex: none !important
    }

    .flex-sm-grow {
        flex: 1 1 auto !important
    }

    .flex-sm-shrink-0 {
        flex-shrink: 0 !important
    }
}

@media (min-width: 768px) {
    .flex-md {
        flex: 1 1 0% !important
    }

    .flex-md-0, .flex-md-none {
        flex: none !important
    }

    .flex-md-grow {
        flex: 1 1 auto !important
    }

    .flex-md-shrink-0 {
        flex-shrink: 0 !important
    }
}

@media (min-width: 992px) {
    .flex-lg {
        flex: 1 1 0% !important
    }

    .flex-lg-0, .flex-lg-none {
        flex: none !important
    }

    .flex-lg-grow {
        flex: 1 1 auto !important
    }

    .flex-lg-shrink-0 {
        flex-shrink: 0 !important
    }
}

@media (min-width: 1200px) {
    .flex-xl {
        flex: 1 1 0% !important
    }

    .flex-xl-0, .flex-xl-none {
        flex: none !important
    }

    .flex-xl-grow {
        flex: 1 1 auto !important
    }

    .flex-xl-shrink-0 {
        flex-shrink: 0 !important
    }
}

@media (min-width: 1366px) {
    .flex-xxl {
        flex: 1 1 0% !important
    }

    .flex-xxl-0, .flex-xxl-none {
        flex: none !important
    }

    .flex-xxl-grow {
        flex: 1 1 auto !important
    }

    .flex-xxl-shrink-0 {
        flex-shrink: 0 !important
    }
}

.text-underline {
    text-decoration: underline
}

.hover-text-decoration-0:focus, .hover-text-decoration-0:hover {
    text-decoration: none
}

.lh-1 {
    line-height: 1rem
}

.text-20, .text-black-20 {
    color: rgba(39, 44, 51, .2) !important
}

.text-50, .text-black-50 {
    color: rgba(39, 44, 51, .5) !important
}

.text-70, .text-black-70 {
    color: rgba(39, 44, 51, .7) !important
}

.text-100, .text-black-100 {
    color: #272c33 !important
}

.text-white-20 {
    color: hsla(0, 0%, 100%, .2) !important
}

.text-white-50 {
    color: hsla(0, 0%, 100%, .5) !important
}

.text-white-60 {
    color: hsla(0, 0%, 100%, .6) !important
}

.text-white-70 {
    color: hsla(0, 0%, 100%, .7) !important
}

[dir] .mb-heading {
    margin-bottom: 1rem !important
}

[dir] .mb-grid-gutter {
    margin-bottom: 24px !important
}

[dir] .mb-card-y {
    margin-bottom: 1rem
}

@media (max-width: 575.98px) {
    [dir] .p-0-xs {
        padding: 0 !important
    }
}

[dir=ltr] .col, [dir=rtl] .col {
    padding-left: 12px;
    padding-right: 12px
}

[dir=ltr] .icon--left {
    margin-right: .5rem
}

[dir=ltr] .icon--right, [dir=rtl] .icon--left {
    margin-left: .5rem
}

[dir=rtl] .icon--right {
    margin-right: .5rem
}

.fullbleed {
    position: absolute;
    top: 0;
    bottom: 0
}

[dir=ltr] .fullbleed, [dir=rtl] .fullbleed {
    left: 0;
    right: 0
}

.absolute-top, .position-absolute-top {
    position: absolute;
    top: 0
}

[dir=ltr] .absolute-top, [dir=ltr] .position-absolute-top {
    left: 0
}

[dir=rtl] .absolute-top, [dir=rtl] .position-absolute-top {
    right: 0
}

.top-0 {
    top: 0
}

[dir=ltr] .right-0 {
    right: 0
}

[dir=rtl] .right-0 {
    left: 0
}

.o-hidden {
    overflow: hidden !important
}

@media (min-width: 576px) and (max-width: 767.98px) {
    .hidden-sm {
        display: none
    }
}

.z-0 {
    z-index: 0
}

.z-1 {
    z-index: 1
}

.z-2 {
    z-index: 2
}

[dir] .bg-primary {
    background-color: var(--primary) !important
}

[dir] .bg-secondary {
    background-color: #868e96 !important
}

[dir] .bg-success {
    background-color: #77c13a !important
}

[dir] .bg-info {
    background-color: #17a2b8 !important
}

[dir] .bg-warning {
    background-color: #e4a93c !important
}

[dir] .bg-danger {
    background-color: #d9534f !important
}

[dir] .bg-light {
    background-color: #f8f9fa !important
}

[dir] .bg-dark {
    background-color: #303840 !important
}

[dir] .bg-aie-blue {
    background-color: var(--aie-blue) !important
}

[dir] .text-aie-blue {
    color: var(--aie-blue) !important
}

[dir] .bg-black {
    background-color: #272c33 !important
}

[dir] .bg-accent, [dir] .bg-accent-red {
    background-color: #ed0b4c !important
}

[dir] .bg-accent-pickled-bluewood {
    background-color: #303956 !important
}

[dir] .bg-primary-red {
    background-color: #ed0b4c !important
}

[dir] .bg-white {
    background-color: #fff !important
}

[dir] .bg-alt {
    background: #fff !important
}

[dir] .bg-body {
    background-color: #f5f7fa !important
}

[dir] .bg-transparent {
    background: transparent !important
}

[dir] .bg-darker {
    background-color: #293037 !important
}

[dir] .bg-dark-gray {
    background: #dddddd !important
}

[dir] .border-1 {
    border: 1px solid #e9edf2 !important
}

[dir=ltr] .border-left-1 {
    border-left: 1px solid #e9edf2 !important
}

[dir=ltr] .border-right-1 {
    border-right: 1px solid #e9edf2 !important
}

[dir] .border-top-1 {
    border-top: 1px solid #e9edf2 !important
}

[dir] .border-bottom-1 {
    border-bottom: 1px solid #e9edf2 !important
}

[dir] .border-2 {
    border: 2px solid #e9edf2 !important
}

[dir=ltr] .border-left-2 {
    border-left: 2px solid #e9edf2 !important
}

[dir=ltr] .border-right-2 {
    border-right: 2px solid #e9edf2 !important
}

[dir] .border-top-2 {
    border-top: 2px solid #e9edf2 !important
}

[dir] .border-bottom-2 {
    border-bottom: 2px solid #e9edf2 !important
}

[dir] .border-3 {
    border: 3px solid #e9edf2 !important
}

[dir=ltr] .border-left-3 {
    border-left: 3px solid #e9edf2 !important
}

[dir=ltr] .border-right-3 {
    border-right: 3px solid #e9edf2 !important
}

[dir] .border-top-3 {
    border-top: 3px solid #e9edf2 !important
}

[dir] .border-bottom-3 {
    border-bottom: 3px solid #e9edf2 !important
}

[dir] .border-4 {
    border: 4px solid #e9edf2 !important
}

[dir=ltr] .border-left-4 {
    border-left: 4px solid #e9edf2 !important
}

[dir=ltr] .border-right-4 {
    border-right: 4px solid #e9edf2 !important
}

[dir] .border-top-4 {
    border-top: 4px solid #e9edf2 !important
}

[dir] .border-bottom-4 {
    border-bottom: 4px solid #e9edf2 !important
}

[dir] .border {
    border: 1px solid #e9edf2 !important
}

[dir] .border-top {
    border-top: 1px solid #e9edf2 !important
}

[dir=ltr] .border-right {
    border-right: 1px solid #e9edf2 !important
}

[dir] .border-bottom {
    border-bottom: 1px solid #e9edf2 !important
}

[dir=ltr] .border-left {
    border-left: 1px solid #e9edf2 !important
}

[dir] .border-0 {
    border: 0 !important
}

[dir] .border-top-0 {
    border-top: 0 !important
}

[dir=ltr] .border-right-0 {
    border-right: 0 !important
}

[dir] .border-bottom-0 {
    border-bottom: 0 !important
}

[dir=ltr] .border-left-0 {
    border-left: 0 !important
}

[dir] .border-success {
    border-color: #77c13a !important
}

[dir=ltr] .border-left-success {
    border-left-color: #77c13a !important
}

[dir=ltr] .border-right-success {
    border-right-color: #77c13a !important
}

[dir] .border-top-success {
    border-top-color: #77c13a !important
}

[dir] .border-bottom-success {
    border-bottom-color: #77c13a !important
}

[dir] .border-info {
    border-color: #17a2b8 !important
}

[dir=ltr] .border-left-info {
    border-left-color: #17a2b8 !important
}

[dir=ltr] .border-right-info {
    border-right-color: #17a2b8 !important
}

[dir] .border-top-info {
    border-top-color: #17a2b8 !important
}

[dir] .border-bottom-info {
    border-bottom-color: #17a2b8 !important
}

[dir] .border-warning {
    border-color: #e4a93c !important
}

[dir=ltr] .border-left-warning {
    border-left-color: #e4a93c !important
}

[dir=ltr] .border-right-warning {
    border-right-color: #e4a93c !important
}

[dir] .border-top-warning {
    border-top-color: #e4a93c !important
}

[dir] .border-bottom-warning {
    border-bottom-color: #e4a93c !important
}

[dir] .border-danger {
    border-color: #d9534f !important
}

[dir=ltr] .border-left-danger {
    border-left-color: #d9534f !important
}

[dir=ltr] .border-right-danger {
    border-right-color: #d9534f !important
}

[dir] .border-top-danger {
    border-top-color: #d9534f !important
}

[dir] .border-bottom-danger {
    border-bottom-color: #d9534f !important
}

[dir] .border-light {
    border-color: #f8f9fa !important
}

[dir=ltr] .border-left-light {
    border-left-color: #f8f9fa !important
}

[dir=ltr] .border-right-light {
    border-right-color: #f8f9fa !important
}

[dir] .border-top-light {
    border-top-color: #f8f9fa !important
}

[dir] .border-bottom-light {
    border-bottom-color: #f8f9fa !important
}

[dir] .border-dark {
    border-color: #303840 !important
}

[dir=ltr] .border-left-dark {
    border-left-color: #303840 !important
}

[dir=ltr] .border-right-dark {
    border-right-color: #303840 !important
}

[dir] .border-top-dark {
    border-top-color: #303840 !important
}

[dir] .border-bottom-dark {
    border-bottom-color: #303840 !important
}

[dir] .border-black {
    border-color: #272c33 !important
}

[dir=ltr] .border-left-black {
    border-left-color: #272c33 !important
}

[dir=ltr] .border-right-black {
    border-right-color: #272c33 !important
}

[dir] .border-top-black {
    border-top-color: #272c33 !important
}

[dir] .border-bottom-black {
    border-bottom-color: #272c33 !important
}

.order-0 {
    order: 0 !important
}

.order-1 {
    order: 1 !important
}

.order-2 {
    order: 2 !important
}

.text-white-100 {
    color: #fff !important
}

[dir] .text-shadow {
    text-shadow: rgba(39, 44, 51, .2) 0 9px 10px
}

.text-ellipsis {
    overflow: hidden;
    text-overflow: ellipsis;
    position: relative;
    white-space: nowrap
}

.lh-24pt {
    line-height: 1.5rem
}

[dir] .bg-dark-blue {
    background-color: #0c2440
}

[dir] .bg-black, [dir] .bg-black-100 {
    background-color: #272c33 !important
}

[dir] .bg-black-50 {
    background-color: rgba(39, 44, 51, .5) !important
}

[dir] .bg-black-20 {
    background-color: rgba(39, 44, 51, .2) !important
}

[dir] .p-card-header {
    padding: 1rem
}

.link-date {
    color: rgba(39, 44, 51, .7);
    position: relative;
    overflow: hidden
}

[dir] .link-date {
    background: rgba(85, 103, 255, .05);
    padding: 2px 4px;
    border-radius: 2px
}

.link-date:after {
    content: "................................................................";
    color: rgba(39, 44, 51, .4);
    font-size: .75rem;
    position: absolute;
    overflow: hidden;
    width: 100%;
    top: .525rem;
    white-space: nowrap
}

[dir=ltr] .link-date:after {
    left: 0
}

.link-date:hover {
    text-decoration: none
}

.material-icons {
    vertical-align: middle
}

.icon-16pt {
    font-size: 1rem !important
}

.icon-24pt {
    font-size: 1.5rem !important
}

.icon-32pt {
    font-size: 2rem !important
}

.icon-40pt {
    font-size: 2.5rem !important
}

.icon-48pt {
    font-size: 3rem !important
}

.icon-64pt {
    font-size: 4rem !important
}

.icon-holder {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 56px;
    height: 56px
}

[dir] .icon-holder {
    padding: 0
}

.icon-holder[href]:hover {
    text-decoration: none
}

.icon-holder--small {
    width: 30px;
    height: 30px
}

[dir] .icon-holder--default {
    background-color: #e9ecef
}

.icon-holder--primary {
    color: #fff
}

[dir] .icon-holder--primary {
    background-image: none;
    background-color: var(--primary);
    border: 1px solid var(--primary)
}

.icon-holder--primary:hover {
    color: var(--primary)
}

[dir] .icon-holder--primary:hover {
    background-image: none;
    background-color: transparent;
    border: 1px solid var(--primary)
}

.icon-holder--primary:hover:hover {
    color: #fff
}

[dir] .icon-holder--primary:hover:hover {
    background-color: var(--primary);
    border-color: var(--primary)
}

.icon-holder--outline-primary {
    color: var(--primary)
}

[dir] .icon-holder--outline-primary {
    background-image: none;
    background-color: transparent;
    border: 1px solid var(--primary)
}

.icon-holder--outline-primary:hover {
    color: #fff
}

[dir] .icon-holder--outline-primary:hover {
    background-color: var(--primary);
    border-color: var(--primary)
}

.icon-holder--secondary {
    color: #fff
}

[dir] .icon-holder--secondary {
    background-image: none;
    background-color: #868e96;
    border: 1px solid #868e96
}

.icon-holder--secondary:hover {
    color: #868e96
}

[dir] .icon-holder--secondary:hover {
    background-image: none;
    background-color: transparent;
    border: 1px solid #868e96
}

.icon-holder--secondary:hover:hover {
    color: #fff
}

[dir] .icon-holder--secondary:hover:hover {
    background-color: #868e96;
    border-color: #868e96
}

.icon-holder--outline-secondary {
    color: #868e96
}

[dir] .icon-holder--outline-secondary {
    background-image: none;
    background-color: transparent;
    border: 1px solid #868e96
}

.icon-holder--outline-secondary:hover {
    color: #fff
}

[dir] .icon-holder--outline-secondary:hover {
    background-color: #868e96;
    border-color: #868e96
}

.icon-holder--success {
    color: #fff
}

[dir] .icon-holder--success {
    background-image: none;
    background-color: #77c13a;
    border: 1px solid #77c13a
}

.icon-holder--success:hover {
    color: #77c13a
}

[dir] .icon-holder--success:hover {
    background-image: none;
    background-color: transparent;
    border: 1px solid #77c13a
}

.icon-holder--success:hover:hover {
    color: #fff
}

[dir] .icon-holder--success:hover:hover {
    background-color: #77c13a;
    border-color: #77c13a
}

.icon-holder--outline-success {
    color: #77c13a
}

[dir] .icon-holder--outline-success {
    background-image: none;
    background-color: transparent;
    border: 1px solid #77c13a
}

.icon-holder--outline-success:hover {
    color: #fff
}

[dir] .icon-holder--outline-success:hover {
    background-color: #77c13a;
    border-color: #77c13a
}

.icon-holder--info {
    color: #fff
}

[dir] .icon-holder--info {
    background-image: none;
    background-color: #17a2b8;
    border: 1px solid #17a2b8
}

.icon-holder--info:hover {
    color: #17a2b8
}

[dir] .icon-holder--info:hover {
    background-image: none;
    background-color: transparent;
    border: 1px solid #17a2b8
}

.icon-holder--info:hover:hover {
    color: #fff
}

[dir] .icon-holder--info:hover:hover {
    background-color: #17a2b8;
    border-color: #17a2b8
}

.icon-holder--outline-info {
    color: #17a2b8
}

[dir] .icon-holder--outline-info {
    background-image: none;
    background-color: transparent;
    border: 1px solid #17a2b8
}

.icon-holder--outline-info:hover {
    color: #fff
}

[dir] .icon-holder--outline-info:hover {
    background-color: #17a2b8;
    border-color: #17a2b8
}

.icon-holder--warning {
    color: #fff
}

[dir] .icon-holder--warning {
    background-image: none;
    background-color: #e4a93c;
    border: 1px solid #e4a93c
}

.icon-holder--warning:hover {
    color: #e4a93c
}

[dir] .icon-holder--warning:hover {
    background-image: none;
    background-color: transparent;
    border: 1px solid #e4a93c
}

.icon-holder--warning:hover:hover {
    color: #fff
}

[dir] .icon-holder--warning:hover:hover {
    background-color: #e4a93c;
    border-color: #e4a93c
}

.icon-holder--outline-warning {
    color: #e4a93c
}

[dir] .icon-holder--outline-warning {
    background-image: none;
    background-color: transparent;
    border: 1px solid #e4a93c
}

.icon-holder--outline-warning:hover {
    color: #fff
}

[dir] .icon-holder--outline-warning:hover {
    background-color: #e4a93c;
    border-color: #e4a93c
}

.icon-holder--danger {
    color: #fff
}

[dir] .icon-holder--danger {
    background-image: none;
    background-color: #d9534f;
    border: 1px solid #d9534f
}

.icon-holder--danger:hover {
    color: #d9534f
}

[dir] .icon-holder--danger:hover {
    background-image: none;
    background-color: transparent;
    border: 1px solid #d9534f
}

.icon-holder--danger:hover:hover {
    color: #fff
}

[dir] .icon-holder--danger:hover:hover {
    background-color: #d9534f;
    border-color: #d9534f
}

.icon-holder--outline-danger {
    color: #d9534f
}

[dir] .icon-holder--outline-danger {
    background-image: none;
    background-color: transparent;
    border: 1px solid #d9534f
}

.icon-holder--outline-danger:hover {
    color: #fff
}

[dir] .icon-holder--outline-danger:hover {
    background-color: #d9534f;
    border-color: #d9534f
}

.icon-holder--light {
    color: #fff
}

[dir] .icon-holder--light {
    background-image: none;
    background-color: #f8f9fa;
    border: 1px solid #f8f9fa
}

.icon-holder--light:hover {
    color: #f8f9fa
}

[dir] .icon-holder--light:hover {
    background-image: none;
    background-color: transparent;
    border: 1px solid #f8f9fa
}

.icon-holder--light:hover:hover {
    color: #fff
}

[dir] .icon-holder--light:hover:hover {
    background-color: #f8f9fa;
    border-color: #f8f9fa
}

.icon-holder--outline-light {
    color: #f8f9fa
}

[dir] .icon-holder--outline-light {
    background-image: none;
    background-color: transparent;
    border: 1px solid #f8f9fa
}

.icon-holder--outline-light:hover {
    color: #fff
}

[dir] .icon-holder--outline-light:hover {
    background-color: #f8f9fa;
    border-color: #f8f9fa
}

.icon-holder--dark {
    color: #fff
}

[dir] .icon-holder--dark {
    background-image: none;
    background-color: #303840;
    border: 1px solid #303840
}

.icon-holder--dark:hover {
    color: #303840
}

[dir] .icon-holder--dark:hover {
    background-image: none;
    background-color: transparent;
    border: 1px solid #303840
}

.icon-holder--dark:hover:hover {
    color: #fff
}

[dir] .icon-holder--dark:hover:hover {
    background-color: #303840;
    border-color: #303840
}

.icon-holder--outline-dark {
    color: #303840
}

[dir] .icon-holder--outline-dark {
    background-image: none;
    background-color: transparent;
    border: 1px solid #303840
}

.icon-holder--outline-dark:hover {
    color: #fff
}

[dir] .icon-holder--outline-dark:hover {
    background-color: #303840;
    border-color: #303840
}

.icon-holder--black {
    color: #fff
}

[dir] .icon-holder--black {
    background-image: none;
    background-color: #272c33;
    border: 1px solid #272c33
}

.icon-holder--black:hover {
    color: #272c33
}

[dir] .icon-holder--black:hover {
    background-image: none;
    background-color: transparent;
    border: 1px solid #272c33
}

.icon-holder--black:hover:hover {
    color: #fff
}

[dir] .icon-holder--black:hover:hover {
    background-color: #272c33;
    border-color: #272c33
}

.icon-holder--outline-black {
    color: #272c33
}

[dir] .icon-holder--outline-black {
    background-image: none;
    background-color: transparent;
    border: 1px solid #272c33
}

.icon-holder--outline-black:hover {
    color: #fff
}

[dir] .icon-holder--outline-black:hover {
    background-color: #272c33;
    border-color: #272c33
}

.icon-holder--accent {
    color: #fff
}

html {
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale
}

b, strong {
    font-weight: 500
}

.h1, .h2, h1, h2 {
    line-height: 1.5
}

.h3, .h4, h3, h4 {
    line-height: 1.414
}

.h5, h5 {
    line-height: 1.5;
    text-transform: uppercase;
    color: #303840
}

.lead {
    line-height: 1.556
}

[dir] .lead:last-child {
    margin-bottom: 0
}

[dir] .blockquote {
    padding: .5rem 1rem
}

[dir=ltr] .blockquote {
    border-left: .25rem solid #e9edf2
}

[dir=ltr] .blockquote--reverse {
    border-right: .25rem solid #e9edf2
}

[dir=ltr] .blockquote--reverse {
    border-left: none
}

.small, small {
    line-height: 1.5
}

.font-size-12pt {
    font-size: .85rem !important
}

.font-size-16pt {
    font-size: 1rem !important
}

.font-size-20pt {
    font-size: 1.25rem !important
}

.font-size-24pt {
    font-size: 1.5rem !important
}

.font-size-32pt {
    font-size: 2rem !important
}

.font-size-48pt {
    font-size: 3rem !important
}

.font-size-56pt {
    font-size: 3.5rem !important
}

.font-size-64pt {
    font-size: 4rem !important
}

.measure-paragraph {
    max-width: 456px
}

.measure-lead, .measure-paragraph-max {
    max-width: 536px
}

.measure-lead-max {
    max-width: 616px
}

.measure-hero-lead {
    max-width: 696px
}

[dir=ltr] .container, [dir=ltr] .container-fluid, [dir=ltr] .container-lg, [dir=ltr] .container-md, [dir=ltr] .container-sm, [dir=ltr] .container-xl, [dir=ltr] .page__container, [dir=rtl] .container, [dir=rtl] .container-fluid, [dir=rtl] .container-lg, [dir=rtl] .container-md, [dir=rtl] .container-sm, [dir=rtl] .container-xl, [dir=rtl] .page__container {
    padding-right: 1.5rem !important;
    padding-left: 1.5rem !important
}

@media (max-width: 767.98px) {
    .has-drawer-opened .mdk-header-layout__content {
        z-index: 2
    }
}

.mdk-header-layout {
    overflow: visible
}

@media (min-width: 768px) {
    .mdk-drawer-layout .container, .mdk-drawer-layout .container-fluid, .mdk-drawer-layout .container-lg, .mdk-drawer-layout .container-md, .mdk-drawer-layout .container-sm, .mdk-drawer-layout .container-xl {
        max-width: 768px
    }
}

@media (min-width: 992px) {
    .mdk-drawer-layout .container, .mdk-drawer-layout .container-fluid, .mdk-drawer-layout .container-lg, .mdk-drawer-layout .container-md, .mdk-drawer-layout .container-sm, .mdk-drawer-layout .container-xl {
        max-width: 960px
    }
}

.sub-layout .mdk-header-layout__content {
    height: 100%
}

.sub-layout .page-content {
    display: flex;
    flex-direction: column
}

.sub-layout #page-content {
    height: 100%
}

@media (min-width: 992px) {
    .sub-layout-drawer .mdk-drawer__content {
        position: absolute
    }
}

body, html {
    min-height: 100vh
}

.mdk-header-layout, .page-content, body {
    display: flex;
    flex-direction: column;
}

.mdk-drawer-layout, .mdk-header-layout, .page-content {
    flex: 1 1 0%
}

.mdk-drawer-layout__content {
    min-height: 100vh
}

.mdk-header-layout .mdk-drawer-layout__content {
    min-height: calc(100vh - 64px)
}

.sidebar {
    display: flex;
    flex-direction: column
}

.sidebar-brand {
    flex-shrink: 0
}

.badge {
    display: inline-flex;
    align-items: center;
    font-weight: 400
}

.badge .material-icons {
    font-size: inherit
}

[dir=ltr] .badge > .material-icons {
    margin-right: 5px
}

.badge-notifications {
    line-height: 1rem;
    font-weight: 700
}

[dir] .badge-notifications {
    border-radius: 100px;
    padding: 0 .3rem
}

.badge-filter a {
    color: inherit;
    opacity: .7
}

[dir=ltr] .badge-filter a {
    padding: 0 2px 0 0
}

.badge-filter a:hover {
    opacity: 1
}

.badge-filter_name {
    opacity: .7
}

[dir=ltr] .badge-filter_name {
    padding-right: 5px
}

[dir] .badge-lg {
    padding: 6px 12px
}

.badge-success, .badge-warning {
    color: #fff
}

.breadcrumb {
    align-items: center;
    font-size: .8125rem;
}

[dir] .container > .breadcrumb:first-of-type {
    padding-top: 1rem
}

.text-breadcrumb {
    font-size: .8125rem;
    font-weight: 600;
    text-transform: uppercase;
    letter-spacing: 2px;
    color: rgba(39, 44, 51, .5)
}

.breadcrumb-item {
    display: flex;
    align-items: center;
    color: var(--global-color)
}

.breadcrumb-item-custom {
    display: flex;
    align-items: center;
    color: var(--global-color)
}

.breadcrumb-item > a {
    color: inherit;
    display: flex;
    align-items: center
}

.breadcrumb-item + .breadcrumb-item:before {
    font-family: Material Icons;
    font-weight: 400;
    font-style: normal;
    font-size: 16px;
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: "liga";
    content: "keyboard_arrow_right"
}

.breadcrumb-item-custom + .breadcrumb-item-custom:before {
    font-weight: 400;
    font-style: normal;
    font-size: 24px;
    display: inline-block;
    line-height: 1;
    text-transform: none;
    letter-spacing: normal;
    word-wrap: normal;
    white-space: nowrap;
    -webkit-font-smoothing: antialiased;
    text-rendering: optimizeLegibility;
    -moz-osx-font-smoothing: grayscale;
    font-feature-settings: "liga";
    content: ">";
    padding-left: 15px;
    padding-right: 15px;
}

[dir=ltr] .breadcrumb-item + .breadcrumb-item:before {
    direction: ltr
}

.alert-soft-success {
    color: #77c13a
}

[dir] .alert-soft-success {
    background-color: rgba(119, 193, 58, .05);
    border-color: #d9eec8
}

[dir] .alert-soft-success hr {
    border-top-color: #cce8b5
}

.alert-soft-success .alert-link {
    color: #5f9a2e
}

.alert-soft-info {
    color: #17a2b8
}

[dir] .alert-soft-info {
    background-color: rgba(23, 162, 184, .05);
    border-color: #bee5eb
}

[dir] .alert-soft-info hr {
    border-top-color: #abdde5
}

.alert-soft-info .alert-link {
    color: #117a8b
}

.alert-soft-warning {
    color: #e4a93c
}

[dir] .alert-soft-warning {
    background-color: rgba(228, 169, 60, .05);
    border-color: #f7e7c8
}

[dir] .alert-soft-warning hr {
    border-top-color: #f4ddb2
}

.alert-soft-warning .alert-link {
    color: #d0911d
}

.alert-soft-danger {
    color: #d9534f
}

[dir] .alert-soft-danger {
    background-color: rgba(217, 83, 79, .05);
    border-color: #f4cfce
}

[dir] .alert-soft-danger hr {
    border-top-color: #efbbb9
}

.alert-soft-danger .alert-link {
    color: #c9302c
}

[dir] .btn--raised {
    box-shadow: 0 6px 6px -3px rgba(39, 44, 51, .1), 0 10px 14px 1px rgba(39, 44, 51, .04), 0 4px 18px 3px rgba(39, 44, 51, .02)
}

[dir] .button-list > .btn, [dir] .button-list > .btn-group {
    margin-bottom: .75rem
}

[dir=ltr] .button-list > .btn, [dir=ltr] .button-list > .btn-group {
    margin-left: .5rem
}

.btn-flush {
    line-height: 1;
    color: inherit
}

[dir] .btn-flush {
    background: none;
    box-shadow: none;
    border: none;
    padding: 0
}

.dropdown-toggle:after {
    align-self: center
}

.btn .material-icons {
    font-size: inherit;
    line-height: inherit
}

[dir] .btn-rounded {
    border-radius: 100px
}

.dropdown-toggle[data-caret=false]:after {
    display: none
}

.btn__secondary-text {
    display: block;
    font-size: .925rem
}

[dir] .btn__secondary-text {
    margin-top: -2px
}

.btn-success:not(:hover), .btn-warning:not(:hover) {
    color: #fff
}

.btn-outline-light {
    color: #212529
}

[dir] .btn-outline-light {
    border-color: #212529
}

.btn-outline-success:hover, .btn-outline-warning:hover, .btn-primary--dark {
    color: #fff
}

[dir] .btn-primary--dark {
    background-color: #2239ff;
    border-color: #2239ff;
    box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, .15), 0 1px 1px rgba(39, 44, 51, .075)
}

.btn-primary--dark:hover {
    color: #fff
}

[dir] .btn-primary--dark:hover {
    background-color: #001bfb;
    border-color: #0019ee
}

.btn-primary--dark.focus, .btn-primary--dark:focus {
    color: #fff
}

[dir] .btn-primary--dark.focus, [dir] .btn-primary--dark:focus {
    background-color: #001bfb;
    border-color: #0019ee;
    box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, .15), 0 1px 1px rgba(39, 44, 51, .075), 0 0 0 1px rgba(67, 87, 255, .5)
}

.btn-primary--dark.disabled, .btn-primary--dark:disabled {
    color: #fff
}

[dir] .btn-primary--dark.disabled, [dir] .btn-primary--dark:disabled {
    background-color: #2239ff;
    border-color: #2239ff
}

.btn-primary--dark:not(:disabled):not(.disabled).active, .btn-primary--dark:not(:disabled):not(.disabled):active, .show > .btn-primary--dark.dropdown-toggle {
    color: #fff
}

[dir] .btn-primary--dark:not(:disabled):not(.disabled).active, [dir] .btn-primary--dark:not(:disabled):not(.disabled):active, [dir] .show > .btn-primary--dark.dropdown-toggle {
    background-color: #0019ee;
    border-color: #0018e1
}

[dir] .btn-primary--dark:not(:disabled):not(.disabled).active:focus, [dir] .btn-primary--dark:not(:disabled):not(.disabled):active:focus, [dir] .show > .btn-primary--dark.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(39, 44, 51, .125), 0 0 0 1px rgba(67, 87, 255, .5)
}

.btn-primary--dark .btn__secondary-text, .btn-primary .btn__secondary-text {
    color: hsla(0, 0%, 100%, .5)
}

.btn-black, .navbar-dark .navbar-btn {
    color: #fff
}

[dir] .btn-black, [dir] .navbar-dark .navbar-btn {
    background-color: #272c33;
    border-color: #272c33;
    box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, .15), 0 1px 1px rgba(39, 44, 51, .075)
}

.btn-black:hover, .navbar-dark .navbar-btn:hover {
    color: #fff
}

[dir] .btn-black:hover, [dir] .navbar-dark .navbar-btn:hover {
    background-color: #16191d;
    border-color: #111316
}

.btn-black.focus, .btn-black:focus, .navbar-dark .focus.navbar-btn, .navbar-dark .navbar-btn:focus {
    color: #fff
}

[dir] .btn-black.focus, [dir] .btn-black:focus, [dir] .navbar-dark .focus.navbar-btn, [dir] .navbar-dark .navbar-btn:focus {
    background-color: #16191d;
    border-color: #111316;
    box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, .15), 0 1px 1px rgba(39, 44, 51, .075), 0 0 0 1px rgba(71, 76, 82, .5)
}

.btn-black.disabled, .btn-black:disabled, .navbar-dark .disabled.navbar-btn, .navbar-dark .navbar-btn:disabled {
    color: #fff
}

[dir] .btn-black.disabled, [dir] .btn-black:disabled, [dir] .navbar-dark .disabled.navbar-btn, [dir] .navbar-dark .navbar-btn:disabled {
    background-color: #272c33;
    border-color: #272c33
}

.btn-black:not(:disabled):not(.disabled).active, .btn-black:not(:disabled):not(.disabled):active, .navbar-dark .navbar-btn:not(:disabled):not(.disabled).active, .navbar-dark .navbar-btn:not(:disabled):not(.disabled):active, .navbar-dark .show > .dropdown-toggle.navbar-btn, .show > .btn-black.dropdown-toggle {
    color: #fff
}

[dir] .btn-black:not(:disabled):not(.disabled).active, [dir] .btn-black:not(:disabled):not(.disabled):active, [dir] .navbar-dark .navbar-btn:not(:disabled):not(.disabled).active, [dir] .navbar-dark .navbar-btn:not(:disabled):not(.disabled):active, [dir] .navbar-dark .show > .dropdown-toggle.navbar-btn, [dir] .show > .btn-black.dropdown-toggle {
    background-color: #111316;
    border-color: #0b0d0f
}

[dir] .btn-black:not(:disabled):not(.disabled).active:focus, [dir] .btn-black:not(:disabled):not(.disabled):active:focus, [dir] .navbar-dark .navbar-btn:not(:disabled):not(.disabled).active:focus, [dir] .navbar-dark .navbar-btn:not(:disabled):not(.disabled):active:focus, [dir] .navbar-dark .show > .dropdown-toggle.navbar-btn:focus, [dir] .show > .btn-black.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(39, 44, 51, .125), 0 0 0 1px rgba(71, 76, 82, .5)
}

.btn-white {
    color: #212529
}

[dir] .btn-white {
    background-color: #fff;
    border-color: #fff;
    box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, .15), 0 1px 1px rgba(39, 44, 51, .075)
}

.btn-white:hover {
    color: #212529
}

[dir] .btn-white:hover {
    background-color: #ececec;
    border-color: #e6e6e6
}

.btn-white.focus, .btn-white:focus {
    color: #212529
}

[dir] .btn-white.focus, [dir] .btn-white:focus {
    background-color: #ececec;
    border-color: #e6e6e6;
    box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, .15), 0 1px 1px rgba(39, 44, 51, .075), 0 0 0 1px rgba(222, 222, 223, .5)
}

.btn-white.disabled, .btn-white:disabled {
    color: #212529
}

[dir] .btn-white.disabled, [dir] .btn-white:disabled {
    background-color: #fff;
    border-color: #fff
}

.btn-white:not(:disabled):not(.disabled).active, .btn-white:not(:disabled):not(.disabled):active, .show > .btn-white.dropdown-toggle {
    color: #212529
}

[dir] .btn-white:not(:disabled):not(.disabled).active, [dir] .btn-white:not(:disabled):not(.disabled):active, [dir] .show > .btn-white.dropdown-toggle {
    background-color: #e6e6e6;
    border-color: #dfdfdf
}

[dir] .btn-white:not(:disabled):not(.disabled).active:focus, [dir] .btn-white:not(:disabled):not(.disabled):active:focus, [dir] .show > .btn-white.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(39, 44, 51, .125), 0 0 0 1px rgba(222, 222, 223, .5)
}

.btn-white .btn__secondary-text {
    color: rgba(0, 0, 0, .3)
}

.btn-outline-white {
    color: #fff
}

[dir] .btn-outline-white {
    border-color: #fff
}

.btn-outline-white:hover {
    color: var(--primary)
}

[dir] .btn-outline-white:hover {
    background-color: #fff;
    border-color: #fff
}

[dir] .btn-outline-white.focus, [dir] .btn-outline-white:focus {
    box-shadow: 0 0 0 1px hsla(0, 0%, 100%, .5)
}

.btn-outline-white.disabled, .btn-outline-white:disabled {
    color: #fff
}

[dir] .btn-outline-white.disabled, [dir] .btn-outline-white:disabled {
    background-color: transparent
}

.btn-outline-white:not(:disabled):not(.disabled).active, .btn-outline-white:not(:disabled):not(.disabled):active, .show > .btn-outline-white.dropdown-toggle {
    color: #212529
}

[dir] .btn-outline-white:not(:disabled):not(.disabled).active, [dir] .btn-outline-white:not(:disabled):not(.disabled):active, [dir] .show > .btn-outline-white.dropdown-toggle {
    background-color: #fff;
    border-color: #fff
}

[dir] .btn-outline-white:not(:disabled):not(.disabled).active:focus, [dir] .btn-outline-white:not(:disabled):not(.disabled):active:focus, [dir] .show > .btn-outline-white.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(39, 44, 51, .125), 0 0 0 1px hsla(0, 0%, 100%, .5)
}

.btn-outline-white .btn__secondary-text {
    color: hsla(0, 0%, 100%, .54)
}

.btn-outline-white:focus .btn__secondary-text, .btn-outline-white:hover .btn__secondary-text {
    color: rgba(39, 44, 51, .5)
}

@media (max-width: 575.98px) {
    .btn-block-xs {
        display: block;
        width: 100%
    }

    [dir] .btn-block-xs:not(:last-child) {
        margin-bottom: 5px
    }
}

@media (max-width: 575.98px) {
    .btn-group-xs > .btn {
        font-size: .7109375rem;
        line-height: 1.5
    }

    [dir] .btn-group-xs > .btn {
        padding: .25rem .5rem;
        border-radius: .2rem
    }
}

.btn-light, .navbar-light .navbar-btn {
    color: #212529
}

[dir] .btn-light, [dir] .navbar-light .navbar-btn {
    background-color: #edf0f2;
    border-color: #edf0f2;
}

.btn-light:hover, .navbar-light .navbar-btn:hover {
    color: #212529
}

[dir] .btn-light:hover, [dir] .navbar-light .navbar-btn:hover {
    background-color: #d7dde2;
    border-color: #cfd7dd
}

.btn-light.focus, .btn-light:focus, .navbar-light .focus.navbar-btn, .navbar-light .navbar-btn:focus {
    color: #212529
}

[dir] .btn-light.focus, [dir] .btn-light:focus, [dir] .navbar-light .focus.navbar-btn, [dir] .navbar-light .navbar-btn:focus {
    background-color: #d7dde2;
    border-color: #cfd7dd;
}

.btn-light.disabled, .btn-light:disabled, .navbar-light .disabled.navbar-btn, .navbar-light .navbar-btn:disabled {
    color: #212529
}

[dir] .btn-light.disabled, [dir] .btn-light:disabled, [dir] .navbar-light .disabled.navbar-btn, [dir] .navbar-light .navbar-btn:disabled {
    background-color: #edf0f2;
    border-color: #edf0f2
}

.btn-light:not(:disabled):not(.disabled).active, .btn-light:not(:disabled):not(.disabled):active, .navbar-light .navbar-btn:not(:disabled):not(.disabled).active, .navbar-light .navbar-btn:not(:disabled):not(.disabled):active, .navbar-light .show > .dropdown-toggle.navbar-btn, .show > .btn-light.dropdown-toggle {
    color: #212529
}

[dir] .btn-light:not(:disabled):not(.disabled).active, [dir] .btn-light:not(:disabled):not(.disabled):active, [dir] .navbar-light .navbar-btn:not(:disabled):not(.disabled).active, [dir] .navbar-light .navbar-btn:not(:disabled):not(.disabled):active, [dir] .navbar-light .show > .dropdown-toggle.navbar-btn, [dir] .show > .btn-light.dropdown-toggle {
    background-color: #cfd7dd;
    border-color: #c8d1d7
}

[dir] .btn-light:not(:disabled):not(.disabled).active:focus, [dir] .btn-light:not(:disabled):not(.disabled):active:focus, [dir] .navbar-light .navbar-btn:not(:disabled):not(.disabled).active:focus, [dir] .navbar-light .navbar-btn:not(:disabled):not(.disabled):active:focus, [dir] .navbar-light .show > .dropdown-toggle.navbar-btn:focus, [dir] .show > .btn-light.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(39, 44, 51, .125), 0 0 0 1px rgba(206, 210, 212, .5)
}

.badge-accent-yellow, .btn-accent-yellow, .btn-accent-yellow:active, .btn-accent-yellow:focus, .btn-accent-yellow:hover {
    color: #fff
}

[dir] .card, [dir] .card-group, [dir] .card-nav .tab-content {
    margin-bottom: 1.5rem
}

.card, .card-nav .tab-content {
    transition: box-shadow .28s cubic-bezier(.4, 0, .2, 1);
    will-change: box-shadow
}

[dir] .card, [dir] .card-nav .tab-content {
    box-shadow: 0 3px 3px -2px rgba(39, 44, 51, .1), 0 3px 4px 0 rgba(39, 44, 51, .04), 0 1px 8px 0 rgba(39, 44, 51, .02)
}

[dir] .card--elevated:hover {
    box-shadow: 0 3px 5px -1px rgba(39, 44, 51, .1), 0 5px 8px 0 rgba(39, 44, 51, .04), 0 1px 14px 0 rgba(39, 44, 51, .02)
}

[dir] .card--raised {
    box-shadow: 0 6px 6px -3px rgba(39, 44, 51, .1), 0 10px 14px 1px rgba(39, 44, 51, .04), 0 4px 18px 3px rgba(39, 44, 51, .02)
}

[dir] .card--scaled {
    transform: scale(1.05)
}

[dir] .card--transparent {
    background-color: hsla(0, 0%, 100%, .9)
}

.card--connect {
    position: relative
}

.card--connect:after {
    content: "";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 4px;
    z-index: -1
}

[dir] .card--connect:after {
    background-color: #e9edf2
}

[dir=ltr] .card--connect:after {
    left: 50%;
    margin-left: -2px
}

[dir] .card-nav .card-flush.tab-content, [dir] .card.card-flush {
    box-shadow: none;
    border: none
}

[dir] .card-nav .card-flush.tab-content, [dir] .card-nav .card-flush.tab-content .card-img, [dir] .card-nav .card-flush.tab-content .card-img-top, [dir] .card.card-flush, [dir] .card.card-flush .card-img, [dir] .card.card-flush .card-img-top {
    border-radius: 0
}

[dir] .card-nav .card-flush-y.tab-content, [dir] .card.card-flush-y {
    box-shadow: none;
    border-radius: 0
}

[dir=ltr] .card-nav .card-flush-y.tab-content, [dir=ltr] .card.card-flush-y {
    border-left: none;
    border-right: none
}

[dir] .card-2by1 .card-body {
    padding: .5rem 1rem
}

[dir] .card-sm .card-body, [dir] .card-sm .card-footer {
    padding: .625rem .75rem
}

[dir] .card-lg .card-body, [dir] .card-lg .card-footer {
    padding: 1.25rem
}

.card-title {
    font-size: 1rem;
    font-weight: 600;
    color: #5a5a5a;
    line-height: 1.25
}

[dir] .card-title {
    margin-bottom: 0
}

.card-subtitle {
    font-size: .8125rem;
    font-weight: 400
}

[dir] .card-subtitle {
    margin-top: 0
}

@media (min-width: 576px) {
    [dir] .card-group > .card, [dir] .card-nav .card-group > .tab-content {
        margin-bottom: 0
    }
}

@media (max-width: 991.98px) {
    .card-group--lg-up .card, .card-group--lg-up .card-nav .tab-content, .card-nav .card-group--lg-up .tab-content {
        flex: 0 0 100% !important
    }

    [dir] .card-group--lg-up .card, [dir] .card-group--lg-up .card-nav .tab-content, [dir] .card-nav .card-group--lg-up .tab-content {
        margin-bottom: 1rem
    }
}

.card-group-row {
    display: flex;
    flex-flow: row wrap
}

.card-group-row__col {
    display: flex;
    min-width: 0
}

.card-group-row__card {
    flex: 1 0 0
}

[dir] .card-group-flush > .card, [dir] .card-nav .card-group-flush > .tab-content {
    box-shadow: none;
    border-bottom: 0;
    margin-bottom: 0;
    background: transparent
}

[dir] .card-group-flush > .card, [dir] .card-group-flush > .card .card-img, [dir] .card-group-flush > .card .card-img-top, [dir] .card-nav .card-group-flush > .tab-content, [dir] .card-nav .card-group-flush > .tab-content .card-img, [dir] .card-nav .card-group-flush > .tab-content .card-img-top {
    border-radius: 0
}

[dir=ltr] .card-group-flush > .card:first-child, [dir=ltr] .card-nav .card-group-flush > .tab-content:first-child {
    border-left: none
}

[dir=ltr] .card-group-flush > .card:last-child, [dir=ltr] .card-nav .card-group-flush > .tab-content:last-child, [dir=rtl] .card-group-flush > .card:first-child, [dir=rtl] .card-nav .card-group-flush > .tab-content:first-child {
    border-right: none
}

@media (max-width: 575.98px) {
    [dir=ltr] .card-group-flush > .card, [dir=ltr] .card-nav .card-group-flush > .tab-content {
        border-left: none;
        border-right: none
    }
}

@media (min-width: 576px) {
    .card-columns--2 {
        -moz-column-count: 2;
        column-count: 2
    }
}

.card-column {
    display: inline-block;
    width: 100%
}

[dir] .card-body + .card-body {
    border-top: 1px solid #dfe2e6
}

.card-deck-row {
    display: flex;
    flex-flow: row wrap
}

.card-deck-row > [class*=col-] {
    display: flex
}

.card-deck-row .card, .card-deck-row .card-nav .tab-content, .card-nav .card-deck-row .tab-content {
    flex: 1 1 0%
}

.card-title {
    font-weight: 500
}

.card-title[href], .card-title a {
    color: inherit;
    display: flex;
    text-decoration: none
}

[dir] .card-title:last-child {
    margin-bottom: 0
}

.card-header {
    position: relative
}

[dir] .card-header {
    border-bottom: 1px solid #dfe2e6
}

[dir] .card-footer {
    border-top: 1px solid #dfe2e6;
    padding: .75rem
}

[dir=ltr] .card-footer:last-child {
    border-bottom-right-radius: .5rem;
    border-bottom-left-radius: .5rem
}

[dir] .card-nav .tab-content > .fullbleed, [dir] .card > .fullbleed {
    border-radius: .5rem
}

.card-img-cover {
    position: relative;
    height: 200px;
    -o-object-fit: cover;
    object-fit: cover
}

.card-feedback:after, .card-feedback:before {
    content: "";
    display: block;
    position: absolute;
    width: 0;
    height: 0;
    z-index: -1;
    bottom: -20px
}

[dir] .card-feedback:after, [dir] .card-feedback:before {
    border: 10px solid transparent;
    border-top-color: #dfe2e6
}

[dir=ltr] .card-feedback:after, [dir=ltr] .card-feedback:before {
    left: 24px
}

.card-feedback:after {
    bottom: -19px;
    z-index: 2
}

[dir] .card-feedback:after {
    border-top-color: #fff
}

.card-header-tabs-basic {
    display: flex
}

[dir] .card-header-tabs-basic {
    padding-top: 0;
    padding-bottom: 0
}

.card-header-tabs-basic > div {
    display: flex
}

.card-header-tabs-basic a {
    color: rgba(39, 44, 51, .54);
    font-weight: 400
}

[dir] .card-header-tabs-basic a {
    padding: .5rem .75rem
}

.card-header-tabs-basic a:hover {
    text-decoration: none;
    color: rgba(39, 44, 51, .84)
}

.card-header-tabs-basic .active {
    color: #303840;
    font-weight: 600
}

[dir] .card-header-tabs-basic .active {
    border-bottom: 2px solid var(--primary)
}

[dir] .popover-lg {
    box-shadow: 0 6px 6px -3px rgba(39, 44, 51, .1), 0 10px 14px 1px rgba(39, 44, 51, .04), 0 4px 18px 3px rgba(39, 44, 51, .02);
    border: none
}

[dir] .popover-lg .popover-body {
    padding: 1.5rem
}

[dir] .popover-lg .bs-popover-auto[x-placement^=top] .arrow:before, [dir] .popover-lg .bs-popover-top .arrow:before {
    border-top-color: transparent
}

[dir] .bs-popover-auto[x-placement^=top], [dir] .bs-popover-top {
    margin-bottom: 1rem
}

[dir=ltr] .bs-popover-auto[x-placement^=right], [dir=ltr] .bs-popover-right {
    margin-left: 1rem
}

[dir] .bs-popover-auto[x-placement^=bottom], [dir] .bs-popover-bottom {
    margin-top: 1rem
}

[dir=ltr] .bs-popover-auto[x-placement^=left], [dir=ltr] .bs-popover-left {
    margin-right: 1rem
}

.img-fluid {
    width: 100%;
    max-width: none
}

.img-fluid-max {
    max-width: 100%;
    height: auto
}

.page-link {
    text-transform: uppercase;
    font-weight: 500;
    font-size: 1rem;
    display: flex;
    align-items: center;
    line-height: 1.25 !important
}

[dir] .page-link {
    border-top: none;
    border-bottom: none
}

[dir=ltr] .page-link {
    border-left: none
}

.page-link:focus, .page-link:hover {
    text-decoration: none
}

[dir=ltr] .page-item:last-of-type .page-link {
    border-right: none
}

.page-link .material-icons {
    line-height: 1rem
}

.pagination {
    position: relative;
    z-index: 0
}

[dir] .pagination:last-child {
    margin-bottom: 0
}

.pagination-xsm .page-link {
    font-size: .8125rem;
    line-height: 24px
}

[dir] .pagination-xsm .page-link {
    padding: 0 .5rem
}

[dir=ltr] .pagination-xsm .page-item:first-child .page-link {
    border-top-left-radius: .2rem;
    border-bottom-left-radius: .2rem
}

[dir=ltr] .pagination-xsm .page-item:last-child .page-link {
    border-top-right-radius: .2rem;
    border-bottom-right-radius: .2rem
}

.pagination-xsm .page-link {
    line-height: 24px !important
}

.pagination-xsm .material-icons {
    font-size: 1rem
}

.table-nowrap td, .table-nowrap th {
    white-space: nowrap
}

.table .sort {
    color: inherit;
    display: inline-flex;
    align-items: center;
    text-decoration: none
}

.table .sort:after {
    content: url("data:image/svg+xml;utf8,<svg width='6' height='10' viewBox='0 0 6 10' fill='none' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' clip-rule='evenodd' d='M3 0L6 4H0L3 0ZM3 10L0 6H6L3 10Z' fill='rgba(39, 44, 51, 0.54)'/></svg>")
}

[dir=ltr] .table .sort:after {
    margin-left: .5rem
}

.table .sort.asc, .table .sort.desc {
    text-decoration: underline;
    font-weight: 600
}

[dir=ltr] .table .sort + .sort {
    margin-left: 1rem
}

[dir] .table--elevated {
    box-shadow: 0 3px 3px -2px rgba(39, 44, 51, .1), 0 3px 4px 0 rgba(39, 44, 51, .04), 0 1px 8px 0 rgba(39, 44, 51, .02)
}

[dir] .table-flush {
    margin-bottom: 0
}

[dir] .table-flush thead th {
    border-top: 0
}

[dir] .table-responsive {
    margin-bottom: 0
}

.table thead th {
    font-weight: 500;
}

[dir] .table tbody .selected > td {
    background-color: rgba(245, 247, 250, .5)
}

[dir] .thead-border-top-0 thead th {
    border-top: 0
}

[dir] .table-flush {
    border-bottom: 0
}

[dir=ltr] .table-flush tbody td:first-of-type, [dir=ltr] .table-flush thead th:first-of-type {
    border-left: 0
}

[dir=ltr] .table-flush tbody td:last-of-type, [dir=ltr] .table-flush thead th:last-of-type {
    border-right: 0
}

[dir] .list-group {
    box-shadow: 0 3px 3px -2px rgba(39, 44, 51, .1), 0 3px 4px 0 rgba(39, 44, 51, .04), 0 1px 8px 0 rgba(39, 44, 51, .02);
    border-radius: .25rem;
    margin-bottom: 1rem
}

[dir] .list-group-flush {
    box-shadow: none;
    border-radius: 0;
    margin-bottom: 0
}

[dir] .list-group-flush > .list-group-item:not(.active) {
    background: none
}

[dir] .list-group-flush > .list-group-item:first-child {
    border-top: 0
}

[dir] .list-group-flush > .list-group-item:last-child {
    border-bottom: 0
}

.list-group-item.active a {
    color: inherit
}

.media-left, .media-right {
    display: flex;
    flex-direction: column
}

[dir=ltr] .media-left {
    margin-right: .5rem
}

[dir=ltr] .media-right {
    margin-left: .5rem
}

.media-body {
    flex: 1 1 0%
}

.media {
    display: flex;
    flex-flow: row wrap
}

@media (max-width: 575.98px) {
    .media-stack-xs {
        flex-direction: column
    }

    .media-stack-xs .media-left {
        width: 100% !important
    }

    [dir] .media-stack-xs .media-left {
        margin: 0 0 1rem
    }
}

@media (max-width: 767.98px) {
    .media-stack-sm {
        flex-direction: column
    }

    .media-stack-sm .media-left {
        width: 100% !important
    }

    [dir] .media-stack-sm .media-left {
        margin: 0 0 1rem
    }
}

@media (max-width: 991.98px) {
    .media-stack-md {
        flex-direction: column
    }

    .media-stack-md .media-left {
        width: 100% !important
    }

    [dir] .media-stack-md .media-left {
        margin: 0 0 1rem
    }
}

@media (max-width: 1199.98px) {
    .media-stack-lg {
        flex-direction: column
    }

    .media-stack-lg .media-left {
        width: 100% !important
    }

    [dir] .media-stack-lg .media-left {
        margin: 0 0 1rem
    }
}

@media (max-width: 1365.98px) {
    .media-stack-xl {
        flex-direction: column
    }

    .media-stack-xl .media-left {
        width: 100% !important
    }

    [dir] .media-stack-xl .media-left {
        margin: 0 0 1rem
    }
}

@media (min-width: 576px) {
    .media__thumbnail {
        width: 150px
    }
}

.nav-link__secondary-text {
    display: block;
    font-size: .825rem
}

[dir] .nav-link__secondary-text {
    margin-top: -2px
}

.nav-link {
    color: rgba(39, 44, 51, .5);
    display: flex
}

[dir] .nav--flush .nav-link {
    padding: 0
}

.active .nav-link, .nav-link.active {
    font-weight: 500;
    color: #272c33
}

[dir] .nav-tabs--flush .nav-link {
    border: none
}

[dir] .nav-tabs--flush .nav-link:focus, [dir] .nav-tabs--flush .nav-link:hover {
    border-color: transparent
}

[dir] .nav-tabs--flush .nav-item.show .nav-link, [dir] .nav-tabs--flush .nav-link.active {
    background-color: transparent
}

.nav-tabs--active-bg .nav-item.show .nav-link, .nav-tabs--active-bg .nav-link.active {
    color: #fff
}

.card-nav {
    z-index: 0
}

.card-nav .nav-tabs {
    z-index: 2;
    position: relative
}

[dir] .card-nav .nav-tabs {
    border-bottom: none
}

[dir] .card-nav .nav-tabs .nav-item {
    margin-bottom: 0
}

[dir] .card-nav .nav-tabs .nav-link:not(.active):hover {
    border-color: transparent
}

@media (min-width: 576px) {
    [dir] .card-nav:not(.card-nav--vertical) .nav-tabs {
        padding: 0 1rem
    }

    [dir] .card-nav:not(.card-nav--vertical) .tab-content {
        margin-top: -1px
    }
}

.card-nav--vertical .nav-tabs {
    flex-direction: column
}

@media (max-width: 991.98px) {
    [dir] .card-nav--vertical .nav-tabs .active {
        border-color: #dfe2e6
    }

    [dir] .card-nav--vertical .nav-tabs {
        margin-bottom: 1rem
    }

    [dir] .card-nav--vertical .nav-tabs .nav-link {
        border-radius: .25rem
    }
}

@media (min-width: 992px) {
    .card-nav--vertical {
        display: flex;
        flex-direction: row
    }

    [dir] .card-nav--vertical {
        margin-bottom: 1rem
    }

    .card-nav--vertical .tab-content {
        overflow: hidden;
        flex: 1
    }

    [dir] .card-nav--vertical .tab-content {
        margin-bottom: 0
    }

    [dir=ltr] .card-nav--vertical .tab-content:last-child {
        margin-left: -1px
    }

    [dir=ltr] .card-nav--vertical .tab-content:first-child {
        margin-right: -1px
    }

    [dir] .card-nav--vertical .nav-tabs {
        padding: 1rem 0
    }

    [dir] .card-nav--vertical .nav-tabs:last-child .nav-item.show .nav-link, [dir] .card-nav--vertical .nav-tabs:last-child .nav-link.active {
        border-color: transparent transparent #f5f7fa
    }

    [dir=ltr] .card-nav--vertical .nav-tabs:last-child .nav-link {
        border-top-right-radius: .25rem;
        border-bottom-right-radius: .25rem;
        border-top-left-radius: 0;
        border-bottom-left-radius: 0
    }

    [dir] .card-nav--vertical .nav-tabs:first-child .nav-item.show .nav-link, [dir] .card-nav--vertical .nav-tabs:first-child .nav-link.active {
        border-color: transparent transparent #f5f7fa #f5f7fa
    }

    [dir=ltr] .card-nav--vertical .nav-tabs:first-child .nav-link {
        border-top-left-radius: .25rem;
        border-bottom-left-radius: .25rem;
        border-top-right-radius: 0;
        border-bottom-right-radius: 0
    }
}

[dir] .nav-tabs--pill {
    border-bottom: none
}

[dir] .nav-tabs--pill .nav-link {
    border-radius: .25rem
}

[dir] .nav-tabs--pill .nav-item {
    margin-bottom: 0
}

[dir] .nav-tabs--pill .nav-item:not(:last-child) {
    margin-bottom: 5px
}

@media (min-width: 576px) {
    [dir] .nav-tabs--pill .nav-item {
        margin-bottom: 0 !important
    }

    [dir=ltr] .nav-tabs--pill .nav-item:not(:last-child) {
        margin-right: 5px
    }
}

.nav-tabs--inactive-dark .nav-link {
    color: #fff
}

.nav-tabs--inactive-dark .nav-link__secondary-text {
    color: hsla(0, 0%, 100%, .5)
}

.nav-tabs--inactive-dark .nav-link.disabled {
    color: hsla(0, 0%, 100%, .7)
}

@media (min-width: 768px) {
    [dir=ltr] .nav-tabs--rounded-top-md-0 .nav-link {
        border-top-left-radius: 0;
        border-top-right-radius: 0
    }
}

@media (min-width: 768px) {
    [dir=ltr] .nav-tabs--rounded-bottom-md-0 .nav-link {
        border-bottom-right-radius: 0;
        border-bottom-left-radius: 0
    }
}

[dir] .nav-tabs--rounded-0 .nav-link, [dir] .nav-tabs-card .nav-link {
    border-radius: 0
}

.nav-tabs-card .nav-link:hover {
    color: #272c33
}

[dir] .nav-tabs-card + .card-footer {
    border-top: none
}

[dir] .card-nav .tab-content > .nav-tabs-card:not(:first-child), [dir] .card > .nav-tabs-card:not(:first-child) {
    border-top: 1px solid #dfe2e6
}

.layout-sticky-subnav .mdk-header-layout {
    overflow: initial
}

.layout-sticky-subnav .page__subnav {
    position: -webkit-sticky;
    position: sticky;
    top: 64px;
    z-index: 2
}

.layout-sticky-subnav .page__container {
    z-index: 0;
    position: relative
}

.avatar-group {
    flex-direction: row
}

.avatar-title {
    font-size: .8125rem;
    font-weight: 500
}

.avatar-32pt {
    width: 2rem;
    height: 2rem
}

.page-heading {
    display: flex;
    flex-direction: column;
    align-items: center
}

[dir] .page-heading {
    margin-bottom: 1rem
}

[dir] .page-heading .h4, [dir] .page-heading h4 {
    margin-bottom: .25rem
}

@media (min-width: 576px) {
    [dir] .page-heading .h4, [dir] .page-heading h4 {
        margin-bottom: 0
    }
}

@media (min-width: 576px) {
    .page-heading {
        flex-direction: row;
        align-items: flex-end
    }
}

.page-headline {
    position: relative
}

[dir] .page-headline {
    padding-bottom: 1.5rem
}

@media (min-width: 992px) {
    [dir] .page-headline {
        padding-bottom: 3rem
    }
}

[dir] .page-headline :last-child {
    margin-bottom: 0
}

.page-headline > :first-child {
    position: relative;
    display: inline-flex
}

[dir] .page-headline > :first-child {
    margin-bottom: calc(1rem + 12px)
}

.page-headline > :first-child:after {
    position: absolute;
    width: 100%;
    height: 4px;
    content: " ";
    bottom: -12px
}

[dir] .page-headline > :first-child:after {
    border-radius: 2px;
    background-color: var(--primary)
}

[dir=ltr] .page-headline > :first-child:after {
    left: 0
}

[dir=rtl] .page-headline > :first-child:after {
    right: 0
}

[dir] .page-headline--title > :first-child {
    margin-bottom: 12px
}

[dir] .page-headline--white > :first-child:after {
    background-color: #fff
}

.page-separator, .page-separator-custom {
    position: relative;
    color: #868e96;
    display: flex;
    align-items: center;
    justify-content: flex-start;
    z-index: 0
}

[dir] .page-separator, [dir] .page-separator-custom {
    margin-bottom: 1rem
}

.page-separator:before {
    content: "";
    height: 1px;
    width: 100%;
    top: 50%;
    position: absolute;
    z-index: -1
}

.page-separator-custom:before {
    content: "";
    height: 1px;
    width: 100%;
    top: 86%;
    position: absolute;
    z-index: -1
}

[dir] .page-separator:before, [dir] .page-separator-custom:before {
    background-color: rgba(0, 0, 0, .1)
}

[dir=ltr] .page-separator:before {
    left: 0
}

.page-separator__text {
    display: inline-flex;
    max-width: 90%;
    font-size: .9375rem;
    color: #303840;
    font-weight: 500;
    letter-spacing: 2px;
}

[dir] .page-separator__text {
    border-radius: .25rem;
    background-color: #f5f7fa
}

[dir] .page-separator-custom .page-separator__text {
    padding-bottom: 10px;
}

[dir=ltr] .page-separator__text {
    padding-right: .5rem
}

[dir=ltr] .justify-content-center .page-separator__text {
    padding-left: .5rem
}

[dir] .bg-alt .page-separator__text {
    background-color: #fff
}

.page-separator__text:hover {
    text-decoration: none
}

.page-separator__bg-bottom {
    content: "";
    height: 50%;
    width: 100%;
    top: 50%;
    position: absolute;
    z-index: -2
}

[dir] .page-separator__bg-bottom {
    background-color: #f5f7fa
}

[dir=ltr] .page-separator__bg-bottom {
    left: 0
}

.page-separator__bg-top {
    content: "";
    height: 50%;
    width: 100%;
    bottom: 50%;
    position: absolute;
    z-index: -2
}

[dir] .page-separator__bg-top {
    background-color: #f5f7fa
}

[dir=ltr] .page-separator__bg-top {
    left: 0
}

.page-separator-mask {
    position: relative;
    overflow: hidden;
    z-index: 0;
    height: 356px
}

.page-separator-mask .page-separator-mask__content {
    position: absolute;
    bottom: 0;
    height: 84px
}

[dir=ltr] .page-separator-mask .page-separator-mask__content {
    left: 0;
    right: 0
}

.page-separator-mask .page-separator {
    position: absolute;
    bottom: 0;
    z-index: 1
}

[dir] .page-separator-mask .page-separator {
    margin: 0
}

[dir=ltr] .page-separator-mask .page-separator {
    left: 0;
    right: 0
}

.page-separator-mask .page-separator-mask__top {
    position: absolute;
    top: 0;
    height: 64px
}

[dir=ltr] .page-separator-mask .page-separator-mask__top {
    background-image: linear-gradient(-180deg, hsla(0, 0%, 100%, .7), hsla(0, 0%, 100%, .8) 17%, hsla(0, 0%, 100%, .9) 32%, #fff 70%);
    left: 0;
    right: 0
}

.page-separator-mask .page-separator-mask__bottom {
    position: absolute;
    bottom: 0;
    height: 20px
}

[dir] .page-separator-mask .page-separator-mask__bottom {
    background: #fff
}

[dir=ltr] .page-separator-mask .page-separator-mask__bottom {
    left: 0;
    right: 0
}

.page-nav {
    z-index: 0;
    position: relative;
    position: -webkit-sticky;
    position: sticky;
    top: 4rem;
    max-height: calc(100vh - 4rem);
    overflow-y: auto;
    display: block
}

[dir=ltr] .page-nav .page-nav__content {
    margin-left: 21px;
    padding-left: 17px
}

.page-nav:before {
    position: absolute;
    width: 2px;
    top: 0;
    bottom: 0;
    content: "";
    z-index: -1
}

[dir] .page-nav:before {
    background-color: #e9edf2
}

[dir=ltr] .page-nav:before {
    left: 33px
}

.page-nav .page-nav__menu {
    display: flex;
    flex-direction: column
}

[dir] .page-nav .page-nav__menu {
    margin-bottom: 1rem
}

[dir=ltr] .page-nav .page-nav__menu {
    margin-left: 21px
}

.page-nav .page-nav__menu a {
    color: rgba(39, 44, 51, .7);
    position: relative
}

[dir] .page-nav .page-nav__menu a {
    margin-bottom: .5rem
}

[dir=ltr] .page-nav .page-nav__menu a {
    padding: 0 0 0 17px
}

.page-nav .page-nav__menu a:before {
    width: 12px;
    height: 12px;
    content: "";
    top: 6px;
    position: absolute
}

[dir] .page-nav .page-nav__menu a:before {
    background-color: #d2d5d9;
    border-radius: 50%;
    border: 2px solid #f5f7fa
}

[dir=ltr] .page-nav .page-nav__menu a:before {
    left: -5px
}

.page-nav .page-nav__menu a.active {
    font-weight: 700
}

[dir] .page-nav .page-nav__menu a.active:before {
    background-color: var(--primary);
    border-color: #fff
}

.page-num {
    width: 2.5rem;
    height: 2.5rem;
    font-weight: 600;
    line-height: 1.5;
    font-size: 1.414rem;
    line-height: 1.414;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #303840
}

[dir] .page-num {
    border-radius: 100%;
    border: 2px solid #e9edf2;
    background-color: #f5f7fa
}

[href] .page-num {
    color: #303840
}

[dir] .page-num-container {
    margin-bottom: 1rem
}

[dir=ltr] .page-num-container {
    margin-left: -3.25rem
}

.page-num-container .page-num {
    flex-shrink: 0
}

[dir=ltr] .page-num-container .page-num {
    margin-right: .75rem
}

[dir] .page-num-container :last-child {
    margin-bottom: 0
}

.page-num-timeline {
    position: relative;
    z-index: 0
}

.page-num-timeline:after {
    content: "";
    width: .3125rem;
    position: absolute;
    top: 0;
    bottom: 0;
    z-index: -1
}

[dir] .page-num-timeline:after {
    background-color: #e9edf2
}

[dir=ltr] .page-num-timeline:after {
    left: 50%;
    margin-left: -.15625rem
}

.page-num-timeline__item {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    position: relative
}

[dir] .page-num-timeline__item {
    margin: 1rem 0
}

.page-num-timeline__item-tip {
    width: .75rem;
    height: .75rem;
    display: block
}

[dir] .page-num-timeline__item-tip {
    border-radius: 100%;
    background-color: #d2d5d9;
    border: 2px solid #f5f7fa;
    margin-bottom: 5px !important
}

[dir] .page-num-timeline__item-current {
    margin-top: .75rem !important
}

.page-num-timeline__item-current .page-num {
    color: #9cf;
    width: 4rem;
    height: 4rem
}

[dir] .page-num-timeline__item-current .page-num {
    background-color: var(--primary);
    border: none
}

.page-num-timeline__item-current .page-num-timeline__item-tip {
    position: absolute;
    top: -.375rem
}

[dir] .page-num-timeline__item-current .page-num-timeline__item-tip {
    border-color: #fff;
    background-color: var(--primary)
}

[dir=ltr] .page-num-timeline__item-current .page-num-timeline__item-tip {
    left: 50%;
    margin-left: -.375rem
}

[dir=rtl] .page-num-timeline__item-current .page-num-timeline__item-tip {
    right: 50%;
    margin-right: -.375rem
}

[dir] .page-num-timeline__item.active .page-num-timeline__item-tip {
    border-color: #9cf;
    background-color: var(--primary)
}

@media (min-width: 576px) {
    [dir] .page-num-timeline {
        margin-top: -.375rem
    }

    [dir] .page-num-timeline__item {
        margin: 0 1rem
    }

    .page-num-timeline:after {
        width: 100%;
        height: .3125rem;
        top: 50%
    }

    [dir] .page-num-timeline:after {
        margin-top: .375rem
    }

    [dir=ltr] .page-num-timeline:after {
        left: 0;
        right: 0;
        margin-left: 0
    }
}

.progression-bar {
    display: flex;
    color: rgba(39, 44, 51, .5);
    flex-direction: column;
    align-items: flex-start;
}

.progression-bar__item {
    display: flex;
    align-items: center;
    flex-direction: column;
    flex: 1 1 0%;
    position: relative;
    z-index: 0;
    color: inherit
}

[dir] .progression-bar__item {
    text-align: center
}

.progression-bar__item-content {
    display: flex;
    align-items: center;
    flex: 1 1 0%
}

.progression-point {
    height: 32px;
    width: 32px;
    background: #c1c1c1;
    border-radius: 50%;
    color: #fff;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
}

.progression-bar__item-content .text {
    padding-left: 10px;
    background: #fff;
    text-align: left;
}

.progression-bar__item--active .progression-point {
    background: #17225a;
}

[dir] .progression-bar__item:not(:last-child) {
    padding-bottom: 15px
}

.progression-bar__item[href]:hover {
    text-decoration: none;
    color: inherit
}

.progression-bar__item:after, .progression-bar__item:before {
    content: "";
    position: absolute;
    z-index: -1;
    width: 2px;
    top: 0;
    height: 100%
}

[dir] .progression-bar__item:after, [dir] .progression-bar__item:before {
    background-color: #f2f2f2
}

[dir=ltr] .progression-bar__item:after, [dir=ltr] .progression-bar__item:before {
    left: 50%;
    margin-left: -1px
}

.progression-bar__item:before {
    display: none
}

.progression-bar__item-icon {
    width: 25px;
    height: 25px;
    color: inherit;
    line-height: 22px;
    font-size: 16px
}

[dir] .progression-bar__item-icon {
    border-radius: 50%;
    border: 2px solid #f2f2f2;
    text-align: center;
    background-color: #fff;
    margin-bottom: 5px
}

.progression-bar__item-text {
    color: inherit
}

[dir] .progression-bar__item-text {
    background-color: #fff
}

[dir] .progression-bar__item-icon:not(.progression-bar__item--disabled), [dir] .progression-bar__item-text:not(.progression-bar__item--disabled) {
    cursor: pointer
}

[dir] .progression-bar__item--complete:after {
    background-color: var(--aie-blue)
}

.progression-bar__item--complete {
    color: rgba(39, 44, 51, .7)
}

[dir] .progression-bar__item--complete .progression-bar__item-icon {
    border-color: rgba(39, 44, 51, .7)
}

.progression-bar__item--active {
    color: #272c33
}

.progression-bar__item--active:not(:last-child):after {
    height: 50%
}

.progression-bar__item--active:not(:last-child):before {
    display: block;
    background-color: var(--aie-blue);
}

.progression-bar--active-primary .progression-bar__item--active .progression-bar__item-icon {
    color: #fff
}

[dir] .progression-bar--active-primary .progression-bar__item--active .progression-bar__item-icon {
    background-color: var(--primary);
    border-color: var(--primary)
}

.progression-bar--active-secondary .progression-bar__item--active .progression-bar__item-icon {
    color: #fff
}

[dir] .progression-bar--active-secondary .progression-bar__item--active .progression-bar__item-icon {
    background-color: #868e96;
    border-color: #868e96
}

.progression-bar--active-success .progression-bar__item--active .progression-bar__item-icon {
    color: #fff
}

[dir] .progression-bar--active-success .progression-bar__item--active .progression-bar__item-icon {
    background-color: #77c13a;
    border-color: #77c13a
}

.progression-bar--active-info .progression-bar__item--active .progression-bar__item-icon {
    color: #fff
}

[dir] .progression-bar--active-info .progression-bar__item--active .progression-bar__item-icon {
    background-color: #17a2b8;
    border-color: #17a2b8
}

.progression-bar--active-warning .progression-bar__item--active .progression-bar__item-icon {
    color: #fff
}

[dir] .progression-bar--active-warning .progression-bar__item--active .progression-bar__item-icon {
    background-color: #e4a93c;
    border-color: #e4a93c
}

.progression-bar--active-danger .progression-bar__item--active .progression-bar__item-icon {
    color: #fff
}

[dir] .progression-bar--active-danger .progression-bar__item--active .progression-bar__item-icon {
    background-color: #d9534f;
    border-color: #d9534f
}

.progression-bar--active-light .progression-bar__item--active .progression-bar__item-icon {
    color: #fff
}

[dir] .progression-bar--active-light .progression-bar__item--active .progression-bar__item-icon {
    background-color: #f8f9fa;
    border-color: #f8f9fa
}

.progression-bar--active-dark .progression-bar__item--active .progression-bar__item-icon {
    color: #fff
}

[dir] .progression-bar--active-dark .progression-bar__item--active .progression-bar__item-icon {
    background-color: #303840;
    border-color: #303840
}

.progression-bar--active-black .progression-bar__item--active .progression-bar__item-icon {
    color: #fff
}

[dir] .progression-bar--active-black .progression-bar__item--active .progression-bar__item-icon {
    background-color: #272c33;
    border-color: #272c33
}

.progression-bar--active-accent .progression-bar__item--active .progression-bar__item-icon {
    color: #fff
}

[dir] .progression-bar--active-accent .progression-bar__item--active .progression-bar__item-icon {
    background-color: var(--aie-blue);
    border-color: var(--aie-blue)
}

.progression-bar--active-accent-red .progression-bar__item--active .progression-bar__item-icon {
    color: #fff
}

[dir] .progression-bar--active-accent-red .progression-bar__item--active .progression-bar__item-icon {
    background-color: #ed0b4c;
    border-color: #ed0b4c
}

@media (min-width: 576px) {
    .progression-bar {
        flex-direction: row;
        align-items: start
    }

    [dir] .progression-bar__item {
        padding-bottom: 0 !important
    }

    .progression-bar__item:after, .progression-bar__item:before {
        top: 50%;
        margin-top: -1px;
        height: 2px !important;
        width: 100%
    }

    [dir=ltr] .progression-bar__item:after, [dir=ltr] .progression-bar__item:before {
        left: 0;
        margin-left: 0
    }

    .progression-bar__item--active:not(:last-child):after {
        width: 50%
    }

    .progression-bar__item--active:not(:last-child):before {
        display: block
    }
}

.tooltip-inner {
    font-size: .9375rem;
    font-weight: 600;
}

[dir] .tooltip-inner {
    box-shadow: 0 2px 1px -1px rgba(39, 44, 51, .1), 0 1px 1px 0 rgba(39, 44, 51, .04), 0 1px 3px 0 rgba(39, 44, 51, .02)
}

.mdk-header {
    height: auto
}

[dir] .mdk-header {
    margin-bottom: 1rem
}

.mdk-header--shadow {
    transition: box-shadow .25s
}

[dir] .mdk-header--shadow-show {
    box-shadow: 0 6px 6px -3px rgba(39, 44, 51, .1), 0 10px 14px 1px rgba(39, 44, 51, .04), 0 4px 18px 3px rgba(39, 44, 51, .02);
    border-color: transparent !important
}

.mdk-header--shadow:after {
    display: none
}

[dir] .mdk-header__bg-front {
    background-position: 50%
}

.mdk-header__bg-front:after {
    display: block;
    content: " ";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%
}

[dir=ltr] .mdk-header__bg-front:after {
    left: 0
}

.mdk-header__content {
    display: flex;
    flex-direction: column
}

.mdk-header--bg-gradient-primary .mdk-header__bg-front:after {
    opacity: .9
}

[dir] .mdk-header--bg-white .mdk-header__bg-front:after {
    background-color: hsla(0, 0%, 100%, .9)
}

[dir] .mdk-header--bg-light .mdk-header__bg-front:after {
    background-color: rgba(248, 249, 250, .9)
}

[dir] .mdk-header--bg-dark .mdk-header__bg-front:after {
    background-color: rgba(48, 56, 64, .9)
}

[dir] .mdk-header--bg-black .mdk-header__bg-front:after {
    background-color: rgba(39, 44, 51, .9)
}

[dir] .mdk-box__bg-front {
    background-position: 50%
}

.mdk-box__bg-front:after {
    display: block;
    content: " ";
    position: absolute;
    top: 0;
    bottom: 0;
    width: 100%
}

[dir=ltr] .mdk-box__bg-front:after {
    left: 0
}

[dir=rtl] .mdk-box__bg-front:after {
    right: 0
}

.mdk-box--bg-gradient-primary .mdk-box__bg-front:after {
    opacity: .9
}

[dir] .mdk-box--bg-secondary .mdk-box__bg-front:after {
    background-color: rgba(134, 142, 150, .9)
}

[dir] .mdk-box--bg-success .mdk-box__bg-front:after {
    background-color: rgba(119, 193, 58, .9)
}

[dir] .mdk-box--bg-info .mdk-box__bg-front:after {
    background-color: rgba(23, 162, 184, .9)
}

[dir] .mdk-box--bg-warning .mdk-box__bg-front:after {
    background-color: rgba(228, 169, 60, .9)
}

[dir] .mdk-box--bg-danger .mdk-box__bg-front:after {
    background-color: rgba(217, 83, 79, .9)
}

.will-shrink-to-hidden {
    will-change: transform;
    transition: transform .2s
}

[dir] .shrink-to-hidden {
    transform: scale3d(0, 0, 0)
}

.will-fade-background {
    transition: background-color .2s
}

.search-form {
    display: flex;
    align-items: center
}

[dir] .search-form {
    background-color: #fff;
    border: 1px solid #edf0f2;
    border-radius: .25rem;
    box-shadow: inset 0 1px 1px rgba(39, 44, 51, .075)
}

[dir] .search-form .form-control {
    background-color: transparent
}

[dir] .search-form .form-control, [dir] .search-form .form-control:focus {
    border-color: transparent;
    box-shadow: none
}

[dir] .search-form .btn {
    padding: 0 .75rem;
    box-shadow: none;
    background-color: transparent
}

[dir=ltr] .search-form .btn + .form-control {
    padding-left: 0
}

[dir=rtl] .search-form .btn + .form-control {
    padding-right: 0
}

[dir] .navbar-dark .navbar-search, [dir] .search-form--black {
    background-color: #272c33;
    border-color: #272c33
}

.navbar-dark .navbar-search .form-control, .search-form--black .form-control {
    color: #fff
}

[dir] .navbar-dark .navbar-search .form-control:focus, [dir] .search-form--black .form-control:focus {
    background-color: #272c33 !important
}

.navbar-dark .navbar-search .btn, .search-form--black .btn {
    color: #868e96
}

[dir] .navbar-dark .navbar-search .btn, [dir] .search-form--black .btn {
    background: transparent
}

[dir] .navbar-light .navbar-search, [dir] .search-form--light {
    background-color: #edf0f2;
    border-color: #edf0f2
}

.navbar-light .navbar-search .form-control, .search-form--light .form-control {
    color: #272c33
}

.navbar-light .navbar-search .form-control::-moz-placeholder, .search-form--light .form-control::-moz-placeholder {
    color: #949595
}

.navbar-light .navbar-search .form-control:-ms-input-placeholder, .search-form--light .form-control:-ms-input-placeholder {
    color: #949595
}

.navbar-light .navbar-search .form-control::placeholder, .search-form--light .form-control::placeholder {
    color: #949595
}

.navbar-light .navbar-search .btn, .search-form--light .btn {
    color: rgba(39, 44, 51, .4)
}

.stack {
    position: relative;
    overflow: initial;
    z-index: 0
}

[dir] .stack {
    margin-bottom: calc(1rem + 10px)
}

.stack:after, .stack:before {
    position: absolute;
    top: 100%;
    transition: transform .2s ease-in-out;
    display: block;
    content: "";
    height: 5px;
    z-index: -1;
    pointer-events: none
}

[dir] .stack:after, [dir] .stack:before {
    border-radius: 0 0 .5rem .5rem;
    box-shadow: 0 2px 0 rgba(0, 0, 0, .07), inset 0 2px 1px -1px #dfe2e6, inset 0 1px 2px 0 rgba(223, 226, 230, .14), inset 0 1px 3px 0 rgba(48, 56, 64, .12);
    background: #fff
}

[dir=ltr] .stack:after, [dir=ltr] .stack:before, [dir=rtl] .stack:after, [dir=rtl] .stack:before {
    left: 10px;
    right: 10px
}

.stack:before {
    height: 10px
}

[dir=ltr] .stack:before, [dir=rtl] .stack:before {
    left: 20px;
    right: 20px
}

[dir] .stack--hidden-hover:hover:after, [dir] .stack--hidden-hover:hover:before, [dir] .stack--hidden:after, [dir] .stack--hidden:before {
    transform: translate3d(0, -12px, 0)
}

.stack--1:before {
    display: none
}

[dir] .footer {
    background-color: #fff
}

label {
    font-weight: 500
}

[dir] .form-control-rounded {
    border-radius: 20rem
}

[dir] .form-control-flush {
    padding: 0;
    border-width: 0
}

[dir] .form-control-flush, [dir] .form-control-flush:focus {
    box-shadow: none;
    background-color: transparent
}

[dir] .list-group-form .list-group-item {
    padding: 1rem 1.5rem
}

[dir] .custom-controls-stacked .custom-control:last-child {
    margin-bottom: 0
}

[dir=ltr] .form-inline label {
    margin-right: .25rem
}

.form-label {
    color: #303840;
    text-transform: uppercase;
    font-size: .8125rem;
    font-weight: 600;
    letter-spacing: 2px
}

[dir] .card-form .card-body {
    padding: 1.6875rem
}

[dir] .card-form .card-body-form-group {
    padding: 1rem 1rem 0
}

[dir] .card-form__body {
    background-color: #f8fafc;
    border-radius: .5rem .5rem 0 0
}

@media (min-width: 576px) {
    [dir=ltr] .card-form__body {
        border-radius: .5rem 0 0 .5rem
    }
}

.card-form__body label:not([class]) {
    color: #303840;
    text-transform: uppercase;
    font-size: .8125rem;
    font-weight: 600;
    letter-spacing: 2px
}

.form-image-group :disabled ~ label:after, .form-image-group [disabled] ~ label:after {
    display: block;
    position: absolute;
    top: 4px;
    bottom: 4px;
    width: auto;
    height: auto;
    content: " ";
    opacity: .4
}

[dir] .form-image-group :disabled ~ label:after, [dir] .form-image-group [disabled] ~ label:after {
    background: #e9ecef;
    border-radius: 0
}

[dir=ltr] .form-image-group :disabled ~ label:after, [dir=ltr] .form-image-group [disabled] ~ label:after, [dir=rtl] .form-image-group :disabled ~ label:after, [dir=rtl] .form-image-group [disabled] ~ label:after {
    left: 4px;
    right: 4px
}

.input-group-text .material-icons {
    font-size: inherit
}

[dir] .input-group.input-group-merge .form-control {
    box-shadow: none
}

.input-group.input-group-merge .form-control:focus ~ [class*=input-group] .input-group-text {
    color: #272c33
}

[dir] .input-group.input-group-merge .form-control:focus ~ [class*=input-group] .input-group-text {
    border-color: #fff;
    background-color: #fff
}

[dir=ltr] .custom-radio-icon {
    padding-left: 1rem
}

[dir=rtl] .custom-radio-icon {
    padding-right: 1rem
}

.custom-radio-icon .custom-control-indicator {
    font-size: 1rem;
    color: #fff
}

[dir] .custom-radio-icon .custom-control-indicator {
    background-color: transparent;
    background-image: none
}

.custom-radio-icon .custom-control-input:checked ~ .custom-control-indicator {
    color: var(--primary)
}

[dir] .custom-radio-icon .custom-control-input:checked ~ .custom-control-indicator {
    background-color: transparent;
    background-image: none
}

.custom-radio-icon .custom-control-input:active ~ .custom-control-indicator {
    color: #eef0ff
}

[dir] .custom-radio-icon .custom-control-input:active ~ .custom-control-indicator {
    background-color: transparent;
    background-image: none
}

.custom-select-icon {
    position: relative;
    z-index: 0
}

[dir=ltr] .custom-select-icon__select {
    padding-left: 1.75rem
}

[dir=rtl] .custom-select-icon__select {
    padding-right: 1.75rem
}

[dir=ltr] .custom-select-icon__select.form-control-lg {
    padding-left: 2rem
}

[dir=rtl] .custom-select-icon__select.form-control-lg {
    padding-right: 2rem
}

.custom-select-icon__icon {
    position: absolute;
    top: 0;
    line-height: inherit !important;
    font-size: .8125rem;
    z-index: 2;
    pointer-events: none
}

[dir] .custom-select-icon__icon {
    padding-top: .5rem
}

[dir=ltr] .custom-select-icon__icon {
    left: 0;
    padding-left: .75rem
}

[dir=rtl] .custom-select-icon__icon {
    right: 0;
    padding-right: .75rem
}

.custom-select-sm + .custom-select-icon__icon, .form-control-sm + .custom-select-icon__icon {
    font-size: .7109375rem
}

.form-control-lg + .custom-select-icon__icon {
    font-size: 1.21875rem
}

[dir] .form-control-lg + .custom-select-icon__icon {
    padding-top: .5rem
}

.mdk-drawer {
    z-index: 1
}

[dir] .mdk-drawer__content {
    background: transparent
}

.navbar {
    height: 52px
}

[dir] .navbar {
    padding-top: 0;
    padding-bottom: 0
}

[dir] .navbar-shadow {
    box-shadow: 0 6px 6px -3px rgba(39, 44, 51, .1), 0 10px 14px 1px rgba(39, 44, 51, .04), 0 4px 18px 3px rgba(39, 44, 51, .02)
}

.top-navbar {
    top: 64px
}

@media (min-width: 576px) {
    .top-sm-navbar {
        top: 64px
    }
}

@media (min-width: 768px) {
    .top-md-navbar {
        top: 64px
    }
}

@media (min-width: 992px) {
    .top-lg-navbar {
        top: 64px
    }
}

@media (min-width: 1200px) {
    .top-xl-navbar {
        top: 64px
    }
}

@media (min-width: 1366px) {
    .top-xxl-navbar {
        top: 64px
    }
}

.navbar-height {
    min-height: 64px
}

.navbar-brand {
    font-size: 1.625rem;
    font-weight: 500;
    display: flex;
    align-items: center
}

[dir] .navbar-brand {
    padding-top: 0;
    padding-bottom: 0
}

[dir=ltr] .navbar-brand {
    margin-right: 1rem
}

[dir=rtl] .navbar-brand {
    margin-left: 1rem
}

[dir=ltr] .navbar-brand-icon {
    margin-right: .5rem
}

[dir=rtl] .navbar-brand-icon {
    margin-left: .5rem
}

.navbar-divider {
    width: 1px;
    height: 64px
}

[dir] .navbar-divider {
    background: hsla(0, 0%, 100%, .24)
}

[dir=ltr] .navbar-divider, [dir=rtl] .navbar-divider {
    margin-left: 1rem;
    margin-right: 1rem
}

.navbar-nav .nav-link {
    font-size: 14px;
    align-items: center;
    font-weight: 500;
    position: relative
}

[dir] .navbar-nav .nav-link {
    padding: 0
}

.navbar-nav .dropdown-menu {
    position: absolute
}

.navbar-toggler {
    width: 64px;
    height: 64px;
    display: inline-block
}

[dir] .navbar-toggler {
    border: none;
    padding: 0;
    text-align: center
}

.navbar-toggler-right {
    order: 1
}

@media (max-width: 767.98px) {
    .navbar .container {
        max-width: none
    }
}

.navbar-expand-sm .nav-link {
    height: 64px
}

@media (max-width: 575.98px) {
    .navbar-expand-sm.navbar-list {
        height: auto
    }

    .navbar-expand-sm.navbar-list .navbar-list__item {
        width: 100%
    }

    [dir] .navbar-expand-sm.navbar-list .navbar-list__item {
        padding: 1rem
    }

    [dir] .navbar-expand-sm.navbar-list.navbar-light .navbar-list__item:not(:last-child) {
        border-bottom: 1px solid #e9edf2
    }
}

@media (min-width: 576px) {
    .navbar-expand-sm .navbar-nav .nav-item {
        display: flex;
        align-items: center
    }

    [dir=ltr] .navbar-expand-sm .navbar-nav .nav-link, [dir=rtl] .navbar-expand-sm .navbar-nav .nav-link {
        padding-left: 0;
        padding-right: 0
    }

    [dir=ltr] .navbar-expand-sm .navbar-nav + .navbar-nav, [dir=ltr] .navbar-expand-sm .navbar-nav .nav-item + .nav-item, [dir=ltr] .navbar-expand-sm .navbar-nav .nav-link + .nav-link {
        margin-left: 1rem
    }

    [dir=rtl] .navbar-expand-sm .navbar-nav + .navbar-nav, [dir=rtl] .navbar-expand-sm .navbar-nav .nav-item + .nav-item, [dir=rtl] .navbar-expand-sm .navbar-nav .nav-link + .nav-link {
        margin-right: 1rem
    }

    .navbar-expand-sm .navbar-list__content {
        flex-direction: row
    }

    .navbar-expand-sm .navbar-collapse__content {
        display: flex;
        width: 100%
    }

    .navbar-expand-sm .navbar-collapse__content .navbar-nav {
        align-items: center
    }
}

.navbar-expand-md .nav-link {
    height: 64px
}

@media (max-width: 767.98px) {
    .navbar-expand-md.navbar-list {
        height: auto
    }

    .navbar-expand-md.navbar-list .navbar-list__item {
        width: 100%
    }

    [dir] .navbar-expand-md.navbar-list .navbar-list__item {
        padding: 1rem
    }

    [dir] .navbar-expand-md.navbar-list.navbar-light .navbar-list__item:not(:last-child) {
        border-bottom: 1px solid #e9edf2
    }
}

@media (min-width: 768px) {
    .navbar-expand-md .navbar-nav .nav-item {
        display: flex;
        align-items: center
    }

    [dir=ltr] .navbar-expand-md .navbar-nav .nav-link, [dir=rtl] .navbar-expand-md .navbar-nav .nav-link {
        padding-left: 0;
        padding-right: 0
    }

    [dir=ltr] .navbar-expand-md .navbar-nav + .navbar-nav, [dir=ltr] .navbar-expand-md .navbar-nav .nav-item + .nav-item, [dir=ltr] .navbar-expand-md .navbar-nav .nav-link + .nav-link {
        margin-left: 1rem
    }

    [dir=rtl] .navbar-expand-md .navbar-nav + .navbar-nav, [dir=rtl] .navbar-expand-md .navbar-nav .nav-item + .nav-item, [dir=rtl] .navbar-expand-md .navbar-nav .nav-link + .nav-link {
        margin-right: 1rem
    }

    .navbar-expand-md .navbar-list__content {
        flex-direction: row
    }

    .navbar-expand-md .navbar-collapse__content {
        display: flex;
        width: 100%
    }

    .navbar-expand-md .navbar-collapse__content .navbar-nav {
        align-items: center
    }
}

.navbar-expand-lg .nav-link {
    height: 64px
}

@media (max-width: 991.98px) {
    .navbar-expand-lg.navbar-list {
        height: auto
    }

    .navbar-expand-lg.navbar-list .navbar-list__item {
        width: 100%
    }

    [dir] .navbar-expand-lg.navbar-list .navbar-list__item {
        padding: 1rem
    }

    [dir] .navbar-expand-lg.navbar-list.navbar-light .navbar-list__item:not(:last-child) {
        border-bottom: 1px solid #e9edf2
    }
}

@media (min-width: 992px) {
    .navbar-expand-lg .navbar-nav .nav-item {
        display: flex;
        align-items: center
    }

    [dir=ltr] .navbar-expand-lg .navbar-nav .nav-link, [dir=rtl] .navbar-expand-lg .navbar-nav .nav-link {
        padding-left: 0;
        padding-right: 0
    }

    [dir=ltr] .navbar-expand-lg .navbar-nav + .navbar-nav, [dir=ltr] .navbar-expand-lg .navbar-nav .nav-item + .nav-item, [dir=ltr] .navbar-expand-lg .navbar-nav .nav-link + .nav-link {
        margin-left: 1rem
    }

    [dir=rtl] .navbar-expand-lg .navbar-nav + .navbar-nav, [dir=rtl] .navbar-expand-lg .navbar-nav .nav-item + .nav-item, [dir=rtl] .navbar-expand-lg .navbar-nav .nav-link + .nav-link {
        margin-right: 1rem
    }

    .navbar-expand-lg .navbar-list__content {
        flex-direction: row
    }

    .navbar-expand-lg .navbar-collapse__content {
        display: flex;
        width: 100%
    }

    .navbar-expand-lg .navbar-collapse__content .navbar-nav {
        align-items: center
    }
}

.navbar-expand .nav-link {
    height: 49px
}

.navbar-expand.navbar-list {
    height: auto
}

.navbar-expand.navbar-list .navbar-list__item {
    width: 100%
}

[dir] .navbar-expand.navbar-list .navbar-list__item {
    padding: 1rem
}

[dir] .navbar-expand.navbar-list.navbar-light .navbar-list__item:not(:last-child) {
    border-bottom: 1px solid #e9edf2
}

.navbar-expand .navbar-nav .nav-item {
    display: flex;
    align-items: center;
}

[dir=ltr] .navbar-expand .navbar-nav .nav-link, [dir=rtl] .navbar-expand .navbar-nav .nav-link {
    padding-left: 0;
    padding-right: 0;
    text-decoration: auto;
}

.navbar-expand .navbar-list__content {
    flex-direction: row
}

.navbar-expand .navbar-collapse__content {
    display: flex;
    width: 100%
}

.navbar-expand .navbar-collapse__content .navbar-nav {
    align-items: center
}

@media (max-width: 575.98px) {
    [dir] .navbar-collapse__content {
        padding: 1rem
    }

    [dir] .navbar-collapse .navbar-nav + .navbar-nav {
        margin-top: 1rem
    }
}

[dir=ltr] .navbar-list {
    padding-left: 0;
    padding-right: 0
}

.navbar-list__content {
    display: flex;
    flex-direction: column
}

[dir] .navbar-list__item {
    padding-top: .5rem;
    padding-bottom: .5rem
}

[dir] .navbar-submenu {
    background: #fff;
    border-bottom: 2px solid #e9edf2
}

@media (max-width: 575.98px) {
    [dir] .navbar-submenu .navbar-collapse {
        border-bottom: 2px solid #e9edf2
    }
}

[dir=ltr] .mr-navbar-x {
    margin-right: 1rem
}

.bg-primary .navbar-dark .navbar-toggler {
    color: #fff
}

[dir] .navbar .ps {
    padding-top: 3px;
    padding-bottom: 3px
}

.navbar .ps .nav {
    flex-wrap: nowrap
}

.navbar--active-undeline .navbar-nav .nav-link.active:before, .navbar--active-undeline .navbar-nav > .active > .nav-link:before {
    position: absolute;
    width: 100%;
    height: 4px;
    content: " ";
    bottom: 0
}

[dir] .navbar--active-undeline .navbar-nav .nav-link.active:before, [dir] .navbar--active-undeline .navbar-nav > .active > .nav-link:before {
    border-radius: 2px;
    background-color: var(--primary)
}

[dir=ltr] .navbar--active-undeline .navbar-nav .nav-link.active:before, [dir=ltr] .navbar--active-undeline .navbar-nav > .active > .nav-link:before {
    left: 0
}

[dir=rtl] .navbar--active-undeline .navbar-nav .nav-link.active:before, [dir=rtl] .navbar--active-undeline .navbar-nav > .active > .nav-link:before {
    right: 0
}

[dir] .navbar-dark-pickled-bluewood {
    background: #303956
}

.navbar-dark-pickled-bluewood .navbar-text-50 {
    color: hsla(0, 0%, 100%, .5)
}

.navbar-dark-pickled-bluewood .navbar-text-70 {
    color: hsla(0, 0%, 100%, .7)
}

.navbar-dark-pickled-bluewood .navbar-text-100 {
    color: #fff
}

[dir] .navbar-dark-pickled-bluewood .navbar-search {
    background-color: #212a45;
    border-color: #212a45
}

.navbar-dark-pickled-bluewood .navbar-search .form-control {
    color: #fff
}

.navbar-dark-pickled-bluewood .navbar-search .form-control::-moz-placeholder {
    color: hsla(0, 0%, 100%, .5)
}

.navbar-dark-pickled-bluewood .navbar-search .form-control:-ms-input-placeholder {
    color: hsla(0, 0%, 100%, .5)
}

.navbar-dark-pickled-bluewood .navbar-search .form-control::placeholder {
    color: hsla(0, 0%, 100%, .5)
}

[dir] .navbar-dark-pickled-bluewood .navbar-search .form-control:focus {
    background-color: transparent !important
}

.navbar-dark-pickled-bluewood .navbar-search .btn {
    color: hsla(0, 0%, 100%, .5)
}

[dir] .navbar-dark-pickled-bluewood .navbar-search .btn {
    background: transparent
}

[dir] .navbar-dark-pickled-bluewood .navbar-avatar {
    background-color: #212a45
}

.navbar-dark-pickled-bluewood .navbar-btn {
    color: #fff
}

[dir] .navbar-dark-pickled-bluewood .navbar-btn {
    background-color: #212a45;
    border-color: #212a45;
    box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, .15), 0 1px 1px rgba(39, 44, 51, .075)
}

.navbar-dark-pickled-bluewood .navbar-btn:hover {
    color: #fff
}

[dir] .navbar-dark-pickled-bluewood .navbar-btn:hover {
    background-color: #151a2b;
    border-color: #111523
}

.navbar-dark-pickled-bluewood .navbar-btn.focus, .navbar-dark-pickled-bluewood .navbar-btn:focus {
    color: #fff
}

[dir] .navbar-dark-pickled-bluewood .navbar-btn.focus, [dir] .navbar-dark-pickled-bluewood .navbar-btn:focus {
    background-color: #151a2b;
    border-color: #111523;
    box-shadow: inset 0 1px 0 hsla(0, 0%, 100%, .15), 0 1px 1px rgba(39, 44, 51, .075), 0 0 0 1px rgba(66, 74, 97, .5)
}

.navbar-dark-pickled-bluewood .navbar-btn.disabled, .navbar-dark-pickled-bluewood .navbar-btn:disabled {
    color: #fff
}

[dir] .navbar-dark-pickled-bluewood .navbar-btn.disabled, [dir] .navbar-dark-pickled-bluewood .navbar-btn:disabled {
    background-color: #212a45;
    border-color: #212a45
}

.navbar-dark-pickled-bluewood .navbar-btn:not(:disabled):not(.disabled).active, .navbar-dark-pickled-bluewood .navbar-btn:not(:disabled):not(.disabled):active, .show > .navbar-dark-pickled-bluewood .navbar-btn.dropdown-toggle {
    color: #fff
}

[dir] .navbar-dark-pickled-bluewood .navbar-btn:not(:disabled):not(.disabled).active, [dir] .navbar-dark-pickled-bluewood .navbar-btn:not(:disabled):not(.disabled):active, [dir] .show > .navbar-dark-pickled-bluewood .navbar-btn.dropdown-toggle {
    background-color: #111523;
    border-color: #0c101a
}

[dir] .navbar-dark-pickled-bluewood .navbar-btn:not(:disabled):not(.disabled).active:focus, [dir] .navbar-dark-pickled-bluewood .navbar-btn:not(:disabled):not(.disabled):active:focus, [dir] .show > .navbar-dark-pickled-bluewood .navbar-btn.dropdown-toggle:focus {
    box-shadow: inset 0 3px 5px rgba(39, 44, 51, .125), 0 0 0 1px rgba(66, 74, 97, .5)
}

.navbar-dark-pickled-bluewood .navbar-brand, .navbar-dark-pickled-bluewood .navbar-brand:focus, .navbar-dark-pickled-bluewood .navbar-brand:hover {
    color: #fff
}

.navbar-dark-pickled-bluewood .navbar-nav .nav-link {
    color: hsla(0, 0%, 100%, .7)
}

.navbar-dark-pickled-bluewood .navbar-nav .nav-link:focus, .navbar-dark-pickled-bluewood .navbar-nav .nav-link:hover {
    color: #fff
}

.navbar-dark-pickled-bluewood .navbar-nav .nav-link.disabled {
    color: hsla(0, 0%, 100%, .25)
}

.navbar-dark-pickled-bluewood .navbar-nav .active > .nav-link, .navbar-dark-pickled-bluewood .navbar-nav .nav-link.active, .navbar-dark-pickled-bluewood .navbar-nav .nav-link.show, .navbar-dark-pickled-bluewood .navbar-nav .show > .nav-link {
    color: #fff
}

.navbar-dark-pickled-bluewood .navbar-toggler {
    color: hsla(0, 0%, 100%, .7)
}

[dir] .navbar-dark-pickled-bluewood .navbar-toggler {
    border-color: hsla(0, 0%, 100%, .1)
}

[dir] .navbar-dark-pickled-bluewood .navbar-toggler-icon {
    background-image: url('data:image/svg+xml;charset=utf-8,<svg xmlns="http://www.w3.org/2000/svg" width="30" height="30"><path stroke="%23858D94" stroke-linecap="round" stroke-miterlimit="10" stroke-width="2" d="M4 7h22M4 15h22M4 23h22"/></svg>')
}

[dir] .navbar-dark-pickled-bluewood .navbar-border {
    border-color: hsla(0, 0%, 100%, .1) !important
}

[dir] .navbar-dark-pickled-bluewood .badge-notifications {
    border: 1px solid white
}

.dropdown-toggle:focus {
    outline: 0
}

[data-caret=false]:after, [data-caret=false]:before {
    display: none !important
}

.dropdown-menu {
    display: block;
    visibility: hidden;
    opacity: 0;
    transition: opacity .2s cubic-bezier(.3, .5, .5, 1), margin .2s cubic-bezier(.1, .3, .8, 1), visibility .3s ease
}

[dir] .dropdown-menu {
    box-shadow: 0 5px 5px -3px rgba(39, 44, 51, .1), 0 8px 10px 1px rgba(39, 44, 51, .04), 0 3px 14px 2px rgba(39, 44, 51, .02);
    margin-top: 0 !important;
    background-clip: initial
}

.dropdown-menu:after, .dropdown-menu:before {
    content: "";
    height: 0;
    opacity: 0;
    transition: opacity .1s cubic-bezier(.3, .5, .5, 1);
    position: absolute;
    top: calc(-8px + -.5rem);
    width: 1px
}

[dir] .dropdown-menu:after, [dir] .dropdown-menu:before {
    border: 8px solid transparent;
    border-bottom-color: #fff
}

[dir=ltr] .dropdown-menu:after, [dir=ltr] .dropdown-menu:before {
    left: 10px
}

.dropdown-menu:before {
    top: calc(-9px + -.5rem)
}

[dir] .dropdown-menu:before {
    border-bottom-color: transparent
}

[dir=ltr] .dropdown-menu-right:after, [dir=ltr] .dropdown-menu-right:before {
    left: auto;
    right: 10px
}

[dir] .dropup .dropdown-menu {
    margin-bottom: 0 !important
}

.dropup .dropdown-menu:after, .dropup .dropdown-menu:before {
    top: auto;
    bottom: calc(-8px + -.5rem)
}

[dir] .dropup .dropdown-menu:after, [dir] .dropup .dropdown-menu:before {
    border-bottom-color: transparent;
    border-top-color: #fff
}

.dropup .dropdown-menu:before {
    bottom: calc(-9px + -.5rem)
}

[dir] .dropup .dropdown-menu:before {
    border-top-color: transparent
}

.dropdown-menu.show, .show > .dropdown-menu {
    visibility: visible;
    opacity: 1
}

[dir] .dropdown-menu.show, [dir] .show > .dropdown-menu {
    margin-top: .5rem !important
}

[dir] .dropup .dropdown-menu.show, [dir] .dropup .show > .dropdown-menu {
    margin-bottom: .5rem !important
}

.dropdown-menu.show:after, .dropdown-menu.show:before, .show > .dropdown-menu:after, .show > .dropdown-menu:before {
    opacity: 1
}

.dropdown-item {
    display: flex;
    align-items: center
}

.dropdown-item > .material-icons {
    font-size: 18px
}

[dir=ltr] .dropdown-item > .material-icons {
    margin-right: 5px
}

.dropdown-item.active, .dropdown-item:active {
    color: #272c33;
    font-weight: 700;
    position: relative
}

[dir] .dropdown-item.active, [dir] .dropdown-item:active {
    background-color: transparent
}

.dropdown-item.active:after, .dropdown-item:active:after {
    position: absolute;
    top: 0;
    bottom: 0;
    width: 4px;
    content: ""
}

[dir] .dropdown-item.active:after, [dir] .dropdown-item:active:after {
    border-radius: 1px;
    background-color: var(--primary)
}

[dir=ltr] .dropdown-item.active:after, [dir=ltr] .dropdown-item:active:after {
    left: -1px
}

.dropdown-menu .close {
    line-height: 0
}

[dir] .dropdown-menu .close {
    padding: .5rem
}

[dir=ltr] .dropdown-menu-full, [dir=rtl] .dropdown-menu-full {
    left: 0 !important;
    right: 0 !important
}

[dir] .dropdown-menu-caret-center {
    transform-origin: 50% top
}

[dir] .dropup .dropdown-menu-caret-center {
    transform-origin: 50% bottom
}

[dir=ltr] .dropdown-menu-caret-center:after, [dir=ltr] .dropdown-menu-caret-center:before {
    left: 50%;
    margin-left: -4px
}

@media (max-width: 767.98px) {
    .dropdown-xs-down-full {
        position: static !important
    }

    .dropdown-xs-down-full .dropdown-menu {
        width: 100% !important
    }

    [dir] .dropdown-xs-down-full .dropdown-menu {
        border-radius: 0
    }

    [dir=ltr] .dropdown-xs-down-full .dropdown-menu, [dir=rtl] .dropdown-xs-down-full .dropdown-menu {
        left: 0;
        right: 0
    }

    .dropdown-xs-down-full .dropdown-menu:after, .dropdown-xs-down-full .dropdown-menu:before {
        display: none
    }
}

.dropdown-header {
    color: #272c33;
    font-weight: 500
}

.dropdown-notifications .dropdown-menu {
    min-width: 300px
}

[dir] .dropdown-notifications .dropdown-menu {
    padding: 0
}

.dropdown-notifications .dropdown-menu .list-group {
    position: relative;
    overflow: hidden
}

.dropdown-notifications .dropdown-menu .list-group-item {
    display: flex;
    flex-direction: column
}

[dir] .dropdown-notifications .dropdown-menu .list-group-item {
    border-color: rgba(39, 44, 51, .05)
}

.dropdown-notifications .dropdown-menu .unread-indicator {
    display: inline-block;
    content: "";
    width: .5rem;
    height: .5rem
}

[dir] .dropdown-notifications .dropdown-menu .unread-indicator {
    border-radius: 100%
}

.dropdown-notifications .dropdown-toggle {
    display: flex
}

.dropdown-notifications .dropdown-toggle .material-icons {
    font-size: 2rem
}

.dropdown-notifications .badge-notifications {
    position: relative;
    display: block
}

[dir=ltr] .dropdown-notifications .badge-notifications {
    margin-left: -.75rem
}

[dir=rtl] .dropdown-notifications .badge-notifications {
    margin-right: -.75rem
}

.dropdown-notifications .dropdown-menu {
    width: 300px
}

.overlay {
    position: relative
}

.overlay__content {
    position: absolute;
    top: 0;
    bottom: 0;
    transition: opacity .4s, background-color .4s;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;
    pointer-events: none
}

[dir=ltr] .overlay__content, [dir=rtl] .overlay__content {
    left: 0;
    right: 0
}

.overlay__action {
    transition: opacity .4s, transform .4s;
    opacity: 0
}

[dir] .overlay__action {
    transform: translate3d(0, 10px, 0)
}

.overlay--show .overlay__action, .overlay--show .overlay__content {
    opacity: 1
}

[dir] .overlay--show .overlay__action {
    transform: translateZ(0)
}

.overlay--duserselect {
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none
}

[dir] .overlay--light .overlay__content {
    background-color: rgba(248, 249, 250, .35)
}

[dir] .overlay--light.overlay--show .overlay__content {
    background-color: rgba(248, 249, 250, .95)
}

[dir] .overlay--dark .overlay__content {
    background-color: rgba(48, 56, 64, .35)
}

[dir] .overlay--dark.overlay--show .overlay__content {
    background-color: rgba(48, 56, 64, .95)
}

[dir] .overlay--black .overlay__content {
    background-color: rgba(39, 44, 51, .35)
}

[dir] .overlay--black.overlay--show .overlay__content {
    background-color: rgba(39, 44, 51, .95)
}

.indicator-line {
    display: block;
    width: 18px;
    height: 4px
}

[dir] .indicator-line {
    background-color: #e9edf2
}

.map {
    width: 100%;
    min-height: 300px;
    position: relative;
    overflow: hidden
}

.map-pin {
    width: 64px;
    height: 50px;
    position: absolute;
    top: -25px;
    color: #888;
    font-weight: 600;
    font-size: 14px;
    white-space: nowrap
}

[dir] .map-pin {
    background-size: 32px 32px;
    background-repeat: no-repeat;
    text-align: center;
    background-position: top
}

[dir=ltr] .map-pin {
    left: -32px
}

.map-pin span {
    position: absolute;
    bottom: 0;
    width: 100%
}

[dir] .map-pin span {
    text-align: center
}

[dir=ltr] .map-pin span {
    left: 0
}

.jqvmap-zoomin, .jqvmap-zoomout {
    box-sizing: content-box
}

[dir] .jqvmap-zoomin, [dir] .jqvmap-zoomout {
    background: var(--primary);
    padding: .25rem
}

.jqvmap-label {
    z-index: 1;
    color: #272c33
}

[dir] .jqvmap-label {
    border: 1px solid #e9edf2;
    border-radius: .25rem;
    background: #fff;
    padding: .25rem .5rem
}

.dashboard-area-tabs__tab {
    display: flex;
    flex-direction: column
}

.dashboard-area-tabs__tab:hover {
    text-decoration: none
}

.dashboard-area-tabs__tab, .dashboard-area-tabs__tab * {
    color: #272c33
}

[dir] .dashboard-area-tabs__tab:not(.active) {
    background-color: #f5f7fa
}

.dashboard-area-tabs__tab:not(.active), .dashboard-area-tabs__tab:not(.active) * {
    color: rgba(39, 44, 51, .5)
}

.dashboard-area-tabs__tab.active {
    position: relative
}

.dashboard-area-tabs__tab.active:before {
    content: " ";
    height: 4px;
    top: 0;
    position: absolute
}

[dir] .dashboard-area-tabs__tab.active:before {
    background-color: var(--primary)
}

[dir=ltr] .dashboard-area-tabs__tab.active:before {
    left: 0;
    right: 0
}

[dir] .dashboard-location-tabs__tab {
    margin-bottom: 8px;
    cursor: pointer
}

[dir] .dashboard-location-tabs__tab:not(.active) .progress-bar {
    background: rgba(39, 44, 51, .2) !important
}

[dir] .list-todo:last-child {
    margin-bottom: 0
}

[dir] .list-todo li:not(:last-child) {
    margin-bottom: 1rem
}

.list-todo .custom-control-input:checked ~ .custom-control-label {
    text-decoration: line-through
}

[dir=ltr] .list-todo .custom-control {
    padding-left: 2rem
}

[dir=ltr] .list-todo .custom-control-label:after, [dir=ltr] .list-todo .custom-control-label:before {
    left: -2rem
}

.list-skills li {
    display: flex;
    align-items: center
}

[dir] .list-skills li:not(:last-child) {
    margin-bottom: 1rem
}

[dir=ltr] .list-skills li > div + div {
    padding-left: 1rem
}

.list-skills li > div:first-child {
    width: 110px
}

[dir] .list-skills:last-child {
    margin-bottom: 0
}

[dir] .posts-card {
    margin-bottom: 12px
}

[dir] .posts-card:last-child {
    margin-bottom: 0
}

[dir] .posts-card__content {
    padding: .5rem
}

.posts-card__title {
    white-space: nowrap
}

.posts-card .card-title, .posts-card .card-title > a, .posts-card__tag, .posts-card__title {
    max-width: 180px
}

.posts-card__tag {
    font-size: .8125rem
}

.posts-card .card-title, .posts-card__tag {
    display: inline-block;
    overflow-x: hidden;
    text-overflow: ellipsis
}

@media (min-width: 576px) {
    .posts-card__tag {
        width: 100px
    }

    .posts-card__date {
        width: 140px
    }

    .posts-card .card-title, .posts-card .card-title > a, .posts-card__title {
        max-width: 300px
    }
}

@media (max-width: 1199.98px) {
    .posts-card__meta {
        width: 100%;
        order: 3;
        align-items: flex-start
    }

    [dir] .posts-card__meta {
        padding-top: .4375rem
    }
}

@media (max-width: 575.98px) {
    .posts-card--wrap .posts-card__meta {
        width: 100%;
        order: 3;
        align-items: flex-start
    }

    [dir] .posts-card--wrap .posts-card__meta {
        padding-top: .4375rem
    }

    .posts-card--wrap .card-title, .posts-card--wrap .card-title > a, .posts-card--wrap .posts-card__date, .posts-card--wrap .posts-card__tag, .posts-card--wrap .posts-card__title {
        width: auto;
        max-width: none
    }
}

@media (max-width: 767.98px) {
    .posts-card--wrap-sm .posts-card__meta {
        width: 100%;
        order: 3;
        align-items: flex-start
    }

    [dir] .posts-card--wrap-sm .posts-card__meta {
        padding-top: .4375rem
    }

    .posts-card--wrap-sm .card-title, .posts-card--wrap-sm .card-title > a, .posts-card--wrap-sm .posts-card__date, .posts-card--wrap-sm .posts-card__tag, .posts-card--wrap-sm .posts-card__title {
        width: auto;
        max-width: none
    }
}

@media (max-width: 991.98px) {
    .posts-card--wrap-md .posts-card__meta {
        width: 100%;
        order: 3;
        align-items: flex-start
    }

    [dir] .posts-card--wrap-md .posts-card__meta {
        padding-top: .4375rem
    }

    .posts-card--wrap-md .card-title, .posts-card--wrap-md .card-title > a, .posts-card--wrap-md .posts-card__date, .posts-card--wrap-md .posts-card__tag, .posts-card--wrap-md .posts-card__title {
        width: auto;
        max-width: none
    }
}

@media (max-width: 1199.98px) {
    .posts-card--wrap-lg .posts-card__meta {
        width: 100%;
        order: 3;
        align-items: flex-start
    }

    [dir] .posts-card--wrap-lg .posts-card__meta {
        padding-top: .4375rem
    }

    .posts-card--wrap-lg .card-title, .posts-card--wrap-lg .card-title > a, .posts-card--wrap-lg .posts-card__date, .posts-card--wrap-lg .posts-card__tag, .posts-card--wrap-lg .posts-card__title {
        width: auto;
        max-width: none
    }
}

@media (max-width: 1365.98px) {
    .posts-card--wrap-xl .posts-card__meta {
        width: 100%;
        order: 3;
        align-items: flex-start
    }

    [dir] .posts-card--wrap-xl .posts-card__meta {
        padding-top: .4375rem
    }

    .posts-card--wrap-xl .card-title, .posts-card--wrap-xl .card-title > a, .posts-card--wrap-xl .posts-card__date, .posts-card--wrap-xl .posts-card__tag, .posts-card--wrap-xl .posts-card__title {
        width: auto;
        max-width: none
    }
}

.posts-card--wrap-xxl .posts-card__meta {
    width: 100%;
    order: 3;
    align-items: flex-start
}

[dir] .posts-card--wrap-xxl .posts-card__meta {
    padding-top: .4375rem
}

.posts-card--wrap-xxl .card-title, .posts-card--wrap-xxl .card-title > a, .posts-card--wrap-xxl .posts-card__date, .posts-card--wrap-xxl .posts-card__tag, .posts-card--wrap-xxl .posts-card__title {
    width: auto;
    max-width: none
}

[dir] .posts-cards {
    margin-bottom: 12px
}

.posts-card-popular {
    position: relative;
    overflow: hidden
}

.posts-card-popular__content {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%
}

[dir=ltr] .posts-card-popular__content {
    left: 0
}

[dir=rtl] .posts-card-popular__content {
    right: 0
}

.posts-card-popular .card-img {
    height: 196px;
    -o-object-fit: cover;
    object-fit: cover
}

.posts-card-popular .card-title, .posts-card-popular a {
    color: #fff
}

[dir] .posts-card-popular .card-body {
    border: none
}

.posts-card-popular__title {
    position: absolute;
    bottom: 0
}

[dir=ltr] .posts-card-popular__title, [dir=rtl] .posts-card-popular__title {
    left: 0;
    right: 0
}

.posts-card-popular__title .text-muted {
    color: hsla(0, 0%, 100%, .54) !important
}

.accordion {
    display: flex;
    flex-direction: column
}

[dir] .accordion {
    margin-bottom: 1.5rem
}

[dir=ltr] .accordion {
    padding-left: 0
}

[dir=rtl] .accordion {
    padding-right: 0
}

.accordion__item {
    position: relative;
    display: block
}

[dir] .accordion__item:not(:last-child) {
    margin-bottom: -1px;
    border-bottom: 1px solid #e9edf2
}

[dir=ltr] .accordion__item:first-child {
    border-top-left-radius: .25rem;
    border-top-right-radius: .25rem
}

[dir=ltr] .accordion__item:last-child {
    border-bottom-right-radius: .25rem;
    border-bottom-left-radius: .25rem
}

.accordion__toggle {
    display: flex;
    align-items: center;
    width: 100%;
    color: #272c33;
    font-weight: 500
}

[dir=ltr] .accordion__toggle {
    padding: .75rem 1rem .75rem 1.5rem
}

[dir] .accordion__toggle-icon {
    transform: rotate(0)
}

[dir=ltr] .accordion__item.open .accordion__toggle-icon {
    transform: rotate(-180deg)
}

.accordion__menu-link {
    display: flex;
    align-items: center;
    width: 100%;
    color: rgba(39, 44, 51, .7)
}

[dir=ltr] .accordion__menu-link {
    padding: .5rem 1rem .5rem 1.5rem
}

[dir] .accordion__menu-link:not(:last-child) {
    margin-bottom: -1px;
    border-bottom: 1px solid #e9edf2
}

.accordion__menu-link:focus, .accordion__menu-link:hover {
    z-index: 1
}

.accordion__menu-link.active {
    color: #272c33;
    font-weight: 500
}

.accordion__menu-link a {
    color: inherit
}

[dir] .accordion--boxed {
    margin-bottom: 0
}

[dir] .accordion--boxed .accordion__item {
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid #dfe2e6;
    border-radius: .5rem;
    margin-bottom: 1.5rem;
    box-shadow: 0 3px 3px -2px rgba(39, 44, 51, .1), 0 3px 4px 0 rgba(39, 44, 51, .04), 0 1px 8px 0 rgba(39, 44, 51, .02)
}

[dir] .accordion--boxed .accordion__item:last-child {
    margin-bottom: 0
}

.aie-header-bg button {
    background: linear-gradient(180deg, #06205d 0%, #111737 100%);
    color: white !important;
    cursor: pointer;
}

.accordion-button:focus {
    z-index: 3;
    border-color: #59595a !important;
    box-shadow: none !important;
}

.accordion-button:not(.collapsed):after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
    transform: rotate(-180deg)
}

.accordion-button.collapsed:after {
    background-image: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23ffffff'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e") !important;
    transform: rotate(0deg)
}

.accordion-button {
    padding: 10px 15px;
    font-weight: bold;
}

.accordion-body {
    padding: 0rem 1rem 1rem 1rem;
}

.mdk-header-layout__content {
    padding-top: 75px
}

.has-secondary-nav .mdk-header-layout__content {
    padding-top: 111px !important;
}

.loading-wrapper {
    display: flex !important;
    justify-content: center !important;
    vertical-align: middle !important;
    align-items: center !important;
    flex-direction: row !important;
}

.loading-wrapper .loading {
    width: 8vmax;
    height: 8vmax;
    border-right: 4px solid #ffffff;
    border-radius: 100%;
    -webkit-animation: spinRight 800ms linear infinite;
    animation: spinRight 800ms linear infinite;
}

.loading-wrapper .loading:before, .loading-wrapper .loading:after {
    content: "";
    width: 6vmax;
    height: 6vmax;
    display: block;
    position: absolute;
    top: calc(50% - 3vmax);
    left: calc(50% - 3vmax);
    border-left: 3px solid #ffffff;
    border-radius: 100%;
    -webkit-animation: spinLeft 800ms linear infinite;
    animation: spinLeft 800ms linear infinite;
}

.loading-wrapper .loading:after {
    width: 4vmax;
    height: 4vmax;
    top: calc(50% - 2vmax);
    left: calc(50% - 2vmax);
    border: 0;
    border-right: 2px solid #ffffff;
    -webkit-animation: none;
    animation: none;
}

@-webkit-keyframes spinLeft {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(720deg);
    }
}

@keyframes spinLeft {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(720deg);
    }
}

@-webkit-keyframes spinRight {
    from {
        transform: rotate(360deg);
    }
    to {
        transform: rotate(0deg);
    }
}

@keyframes spinRight {
    from {
        transform: rotate(360deg);
    }
    to {
        transform: rotate(0deg);
    }
}

.is-offline #header {
    top: 26px !important;
}

.offline-feedback {
    -webkit-transition: opacity 2.0s ease-in;
    -moz-transition: opacity 2.0s ease-in;
    -o-transition: opacity 2.0s ease-in;
    opacity: 1;
}

.accordion {
    --bs-accordion-color: #212529;
    --bs-accordion-bg: #fff;
    --bs-accordion-transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out, border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out, border-radius 0.15s ease;
    --bs-accordion-border-color: var(--bs-border-color);
    --bs-accordion-border-width: 1px;
    --bs-accordion-border-radius: 0.375rem;
    --bs-accordion-inner-border-radius: calc(0.375rem - 1px);
    --bs-accordion-btn-padding-x: 1.25rem;
    --bs-accordion-btn-padding-y: 1rem;
    --bs-accordion-btn-color: #212529;
    --bs-accordion-btn-bg: var(--bs-accordion-bg);
    --bs-accordion-btn-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%23212529'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    --bs-accordion-btn-icon-width: 1.25rem;
    --bs-accordion-btn-icon-transform: rotate(-180deg);
    --bs-accordion-btn-icon-transition: transform 0.2s ease-in-out;
    --bs-accordion-btn-active-icon: url("data:image/svg+xml,%3csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 16 16' fill='%230c63e4'%3e%3cpath fill-rule='evenodd' d='M1.646 4.646a.5.5 0 0 1 .708 0L8 10.293l5.646-5.647a.5.5 0 0 1 .708.708l-6 6a.5.5 0 0 1-.708 0l-6-6a.5.5 0 0 1 0-.708z'/%3e%3c/svg%3e");
    --bs-accordion-btn-focus-border-color: #86b7fe;
    --bs-accordion-btn-focus-box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
    --bs-accordion-body-padding-x: 1.25rem;
    --bs-accordion-body-padding-y: 1rem;
    --bs-accordion-active-color: #0c63e4;
    --bs-accordion-active-bg: #e7f1ff;
}

.accordion-button {
    position: relative;
    display: flex;
    align-items: center;
    width: 100%;
    font-size: 1rem;
    color: var(--bs-accordion-btn-color);
    text-align: left;
    background-color: var(--bs-accordion-btn-bg);
    border: 0;
    border-radius: 0;
    overflow-anchor: none;
    transition: var(--bs-accordion-transition);
}

@media (prefers-reduced-motion: reduce) {
    .accordion-button {
        transition: none;
    }
}

.accordion-button:not(.collapsed) {
    color: var(--bs-accordion-active-color);
    background-color: var(--bs-accordion-active-bg);
    box-shadow: inset 0 calc(-1 * var(--bs-accordion-border-width)) 0 var(--bs-accordion-border-color);
}

.accordion-button:not(.collapsed)::after {
    background-image: var(--bs-accordion-btn-active-icon);
    transform: var(--bs-accordion-btn-icon-transform);
}

.accordion-button::after {
    flex-shrink: 0;
    width: var(--bs-accordion-btn-icon-width);
    height: var(--bs-accordion-btn-icon-width);
    margin-left: auto;
    content: "";
    background-image: var(--bs-accordion-btn-icon);
    background-repeat: no-repeat;
    background-size: var(--bs-accordion-btn-icon-width);
    transition: var(--bs-accordion-btn-icon-transition);
}

@media (prefers-reduced-motion: reduce) {
    .accordion-button::after {
        transition: none;
    }
}

.accordion-button:hover {
    z-index: 2;
}

.accordion-button:focus {
    z-index: 3;
    border-color: var(--bs-accordion-btn-focus-border-color);
    outline: 0;
    box-shadow: var(--bs-accordion-btn-focus-box-shadow);
}

.accordion-header {
    margin-bottom: 0;
}

.accordion-item {
    color: var(--bs-accordion-color);
    background-color: var(--bs-accordion-bg);
    border: var(--bs-accordion-border-width) solid var(--bs-accordion-border-color);
}

.accordion-item:first-of-type {
    border-top-left-radius: var(--bs-accordion-border-radius);
    border-top-right-radius: var(--bs-accordion-border-radius);
}

.accordion-item:first-of-type .accordion-button {
    border-top-left-radius: var(--bs-accordion-inner-border-radius);
    border-top-right-radius: var(--bs-accordion-inner-border-radius);
}

.accordion-item:not(:first-of-type) {
    border-top: 0;
}

.accordion-item:last-of-type {
    border-bottom-right-radius: var(--bs-accordion-border-radius);
    border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-item:last-of-type .accordion-button.collapsed {
    border-bottom-right-radius: var(--bs-accordion-inner-border-radius);
    border-bottom-left-radius: var(--bs-accordion-inner-border-radius);
}

.accordion-item:last-of-type .accordion-collapse {
    border-bottom-right-radius: var(--bs-accordion-border-radius);
    border-bottom-left-radius: var(--bs-accordion-border-radius);
}

.accordion-body {
    padding: var(--bs-accordion-body-padding-y) var(--bs-accordion-body-padding-x);
}

.accordion-flush .accordion-collapse {
    border-width: 0;
}

.accordion-flush .accordion-item {
    border-right: 0;
    border-left: 0;
    border-radius: 0;
}

.accordion-flush .accordion-item:first-child {
    border-top: 0;
}

.accordion-flush .accordion-item:last-child {
    border-bottom: 0;
}

.accordion-flush .accordion-item .accordion-button, .accordion-flush .accordion-item .accordion-button.collapsed {
    border-radius: 0;
}

.badge {
    --bs-badge-font-size: 0.75em;
    --bs-badge-font-weight: 700;
    --bs-badge-color: #fff;
    --bs-badge-border-radius: 0.375rem;
    color: var(--bs-badge-color);
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: var(--bs-badge-border-radius);
}

.btn .badge {
    position: relative;
    top: -1px;
}

.page-has-error .heading {
    margin: 0 auto 4rem auto;
    color: black;
    font-size: 2rem;
    line-height: 3rem;
    font-weight: 200;
    text-align: center;
}

.page-has-error h2 {
    margin: 20px auto 30px auto;
    font-size: 1.5rem;
    font-weight: 200;
    text-align: center;
}

.page-has-error h1, .page-has-error h2 {
    -webkit-transition: opacity 0.5s linear, margin-top 0.5s linear;
    transition: opacity 0.5s linear, margin-top 0.5s linear;
}

.page-has-error .gears {
    position: relative;
    margin: 0 auto;
    width: auto;
    height: 0;
}

.page-has-error .gear {
    position: relative;
    z-index: 0;
    width: 120px;
    height: 120px;
    margin: 0 auto;
    border-radius: 50%;
    background: black;
}

.page-has-error .gear:before {
    position: absolute;
    left: 5px;
    top: 5px;
    right: 5px;
    bottom: 5px;
    z-index: 2;
    content: "";
    border-radius: 50%;
    background: #f5f7fa;
}

.page-has-error .gear:after {
    position: absolute;
    left: 25px;
    top: 25px;
    z-index: 3;
    content: "";
    width: 70px;
    height: 70px;
    border-radius: 50%;
    border: 5px solid black;
    box-sizing: border-box;
    background: #f5f7fa;
}

.page-has-error .gear.one {
    left: -130px;
}

.page-has-error .gear.two {
    top: -75px;
}

.page-has-error .gear.three {
    top: -235px;
    left: 130px;
}

.page-has-error .gear .bar {
    position: absolute;
    left: -15px;
    top: 50%;
    z-index: 0;
    width: 150px;
    height: 30px;
    margin-top: -15px;
    border-radius: 5px;
    background: black;
}

.page-has-error .gear .bar:before {
    position: absolute;
    left: 5px;
    top: 5px;
    right: 5px;
    bottom: 5px;
    z-index: 1;
    content: "";
    border-radius: 2px;
    background: #f5f7fa;
}

.page-has-error .gear .bar:nth-child(2) {
    transform: rotate(60deg);
    -webkit-transform: rotate(60deg);
}

.page-has-error .gear .bar:nth-child(3) {
    transform: rotate(120deg);
    -webkit-transform: rotate(120deg);
}

@-webkit-keyframes clockwise {
    0% {
        -webkit-transform: rotate(0deg);
    }
    100% {
        -webkit-transform: rotate(360deg);
    }
}

@-webkit-keyframes anticlockwise {
    0% {
        -webkit-transform: rotate(360deg);
    }
    100% {
        -webkit-transform: rotate(0deg);
    }
}

@-webkit-keyframes clockwiseError {
    0% {
        -webkit-transform: rotate(0deg);
    }
    20% {
        -webkit-transform: rotate(30deg);
    }
    40% {
        -webkit-transform: rotate(25deg);
    }
    60% {
        -webkit-transform: rotate(30deg);
    }
    100% {
        -webkit-transform: rotate(0deg);
    }
}

@-webkit-keyframes anticlockwiseErrorStop {
    0% {
        -webkit-transform: rotate(0deg);
    }
    20% {
        -webkit-transform: rotate(-30deg);
    }
    60% {
        -webkit-transform: rotate(-30deg);
    }
    100% {
        -webkit-transform: rotate(0deg);
    }
}

@-webkit-keyframes anticlockwiseError {
    0% {
        -webkit-transform: rotate(0deg);
    }
    20% {
        -webkit-transform: rotate(-30deg);
    }
    40% {
        -webkit-transform: rotate(-25deg);
    }
    60% {
        -webkit-transform: rotate(-30deg);
    }
    100% {
        -webkit-transform: rotate(0deg);
    }
}

.page-has-error .gear.one {
    animation: anticlockwiseErrorStop 2s linear infinite;
}

.page-has-error .gear.two {
    -webkit-animation: anticlockwiseError 2s linear infinite;
}

.page-has-error .gear.three {
    -webkit-animation: clockwiseError 2s linear infinite;
}

.custom-invalid {
    border-color: #dc3545;
}

.apm.forms .nav-link.active {
    font-size: 14px;
}

.apm-card-footer {
    background-color: var(--apm-form-card-bg-color) !important;
}

#seconderyNavbar {
    font-family: 'Inter Regular';
    font-weight: normal;
    font-weight: 500;
    font-size: 14px;
    letter-spacing: 0.005em;
    color: #787878;
}

.MuiInputBase-adornedEnd {
    padding-right: 0px !important;
}

.form-card {
    padding: 25px;
    border-radius: 10px;
    background: #f8fafc;
    border: 1px solid #e2e8f0;
}

.MuiMenu-list li, .MuiSelect-select, .MuiInputBase-adornedEnd, .dropbtn {
    font-size: 16px !important;
}

.MuiInputAdornment-root {
    margin-left: 0 !important;
}

.apm-datepicker input {
    opacity: 1;
    -webkit-text-fill-color: rgba(0, 0, 0, 1) !important;
}

.rounded-circle {
    border-radius: 50% !important;
}

.pending-actions-bg {
    background-color: #F2F5F8 !important;
}

.monitoring-logs th {
    font-weight: 700;
    border-bottom: 1px solid rgb(224, 224, 224);
}

.monitoring-logs td {
    font-weight: 400;
    line-height: 1.43;
    vertical-align: inherit;
    border-bottom: 1px solid rgb(224, 224, 224);
    text-align: left;
    color: rgba(0, 0, 0, 0.87);
    cursor: inherit;
    overflow: hidden;
    padding: 0.5rem;
    text-overflow: ellipsis;
    white-space: nowrap;
    z-index: 0;
    background-color: inherit;
    background-image: inherit;
    opacity: 1;
    transition: all 150ms ease-in-out 0s;
}

.monitoring-logs th {
    vertical-align: middle;
    line-height: 1.3;
    font-size: 13px;
    letter-spacing: 0.01071em;
    display: table-cell;
    text-align: left;
    color: rgba(0, 0, 0, 0.87);
    overflow: visible;
    padding: 0.25rem 0.5rem 0.4rem;
    z-index: 1;
    background-color: inherit;
    background-image: inherit;
    opacity: 1;
    transition: all 150ms ease-in-out 0s;
}

.MuiPaper-root.MuiPaper-elevation {
    box-shadow: none;
    overflow: hidden;
}

.monitoring-logs .header-unit {
    font-size: 80%;
    font-weight: normal;
    color: rgb(129, 129, 129);
}

.MuiTablePagination-select {
    font-size: 14px !important;
}

.MuiTablePagination-selectLabel, .MuiTablePagination-displayedRows {
    margin-bottom: 3px !important;
}

.page-title, .section-title, .sub-title {
    font-family: 'VAG Rounded Std';
}

.page-title {
    font-weight: 700;
    font-size: 32px;
    line-height: 1.4;
}

.section-title {
    font-weight: 700;
    font-size: 24px;
    line-height: 1.4;
}

.sub-title {
    font-size: 20px;
    font-weight: 300;
    line-height: 1.8;
    margin-bottom: 8px !important;
}

.page-main {
    padding-top: 52px;
}

.breadcrumb-wrapper {
    background: #dbe3ea;
    padding: 14px 0;
    margin-bottom: 40px;
}

.breadcrumb-custom {
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;
    font-size: 16px;
    font-family: 'Inter Regular', sans-serif;
}

.breadcrumb-custom li + li::before {
    content: '';
    margin: 0 1em 0 0.8em;
    display: inline-block;
    height: 0.5em;
    width: 0.5em;
    border-right: 1px solid;
    border-bottom: 1px solid;
    transform: rotate(-45deg);
    vertical-align: middle;
}

.breadcrumb-custom li.active {
    font-weight: 600;
}

.apm-card {
    padding: 10px;
    padding-top: 15px;
    border-radius: 10px;
    background: #f8fafc;
    border: 1px solid #e2e8f0;
}

.comment-box .ck-content {
    padding: 0 14px !important;
    min-height: 180px;
    border-radius: 4px !important;
    border: 1px solid #dfe0df !important;
}

.comment-box .ck.ck-editor__editable.ck-focused:not(.ck-editor__nested-editable) {
    box-shadow: none;
    border: 1px solid #dfe0df;
}

.btn.btn-link {
    text-decoration: underline;
    color: #1b2064;
    background: #f2f5f8;
    border: 1px solid #dfe0df;
    font-family: 'DINNextLTPro', sans-serif;
    font-weight: 400;
}

.btn.btn-link:not(:active) {
    box-shadow: none !important;
}

.btn.btn-small {
    padding: 5px 10px;
    line-height: 1;
}

.MuiInputBase-root.MuiFilledInput-root {
    border-radius: 4px;
    background-color: #f2f4f5;
    border: 1px solid #dfe0df;
}

.MuiFilledInput-root.MuiFilledInput-underline::before, .MuiFilledInput-root.MuiFilledInput-underline::after {
    display: none;
}

.table-responsive, .MuiPaper-root.MuiPaper-elevation {
    border: 1px solid #dfe0df;
    border-radius: 6px;
}

.MuiPaper-root.MuiPaper-elevation {
    box-shadow: none;
    overflow: hidden;
}

body .MuiTableSortLabel-root {
    margin-left: 8px;
}

body .Mui-TableHeadCell-Content-Labels:hover .MuiTableSortLabel-icon {
    opacity: 0.5;
}

body .MuiTableSortLabel-root.Mui-active .MuiTableSortLabel-icon {
    opacity: 1 !important;
}

body .MuiFormControl-root {
    --input-color: #1b2064;
    --border-color: rgba(0, 0, 0, 0.23);
    width: 100%;
}

body .MuiOutlinedInput-root fieldset {
    padding: 0 8px;
    top: 0;
    border: 1px solid var(--border-color);
}

body .MuiOutlinedInput-root fieldset legend {
    display: none;
}

body .MuiInputBase-root.MuiOutlinedInput-root:hover fieldset, body .MuiInputBase-root.MuiOutlinedInput-root:hover .sidelabel {
    border-color: var(--input-color);
}

body .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused fieldset, body .MuiInputBase-root.MuiOutlinedInput-root.Mui-focused .sidelabel {
    border-color: #fc7a1d;
}

body .MuiInputLabel-root + .MuiOutlinedInput-root .MuiOutlinedInput-input {
    padding: 20px 14px 7px;
    font-size: 15px;
}

body .MuiFormLabel-root {
    color: var(--input-color);
    font-size: 15px;
}

body .MuiFormLabel-root.Mui-focused, body .MuiFormLabel-root.MuiFormLabel-filled {
    color: rgba(0, 0, 0, 0.6);
}

body .MuiFormLabel-root.MuiInputLabel-outlined {
    transform: translate(14px, 14px) scale(1);
    left: 0 !important;
}

body .MuiFormLabel-root.MuiInputLabel-outlined.Mui-focused, body .MuiFormLabel-root.MuiInputLabel-outlined.MuiFormLabel-filled {
    transform: translate(14px, 4px) scale(0.75);
    font-size: 1rem !important;
}

body .MuiInputBase-root.MuiOutlinedInput-root .sidelabel {
    background: #f2f4f5;
    color: #1b2064;
    padding: 13px;
    white-space: nowrap;
    flex: 0 0 auto;
    border-left: 1px solid rgba(0, 0, 0, 0.23);
}

body .mui-success {
    --input-color: #04a759;
    --border-color: #04a759;
}

body .mui-error {
    --input-color: #d32f2f;
    --border-color: #d32f2f;
}

body .mui-error fieldset, body .mui-error .sidelabel, body .mui-success fieldset, body .mui-success .sidelabel {
    border-color: var(--border-color) !important;
}

body .mui-error .MuiSvgIcon-root, body .mui-error .MuiFormLabel-root, body .mui-success .MuiSvgIcon-root, body .mui-success .MuiFormLabel-root {
    color: var(--input-color);
}

body .MuiFormHelperText-root {
    margin: 3px 0 0;
    text-align: right;
    color: var(--input-color);
    text-transform: uppercase;
}

body .MuiInputBase-input {
    color: var(--input-color);
}

body .MuiSwitch-thumb {
    background: linear-gradient(90deg, #ffffff 0%, #dcdcdc 72.27%);
}

body .Mui-checked .MuiSwitch-thumb {
    background: #0080ff;
}

body .MuiSwitch-track {
    opacity: 1;
    background: #9a9b9c;
    box-shadow: inset 2px 4px 4px rgba(0, 0, 0, 0.25);
}

body .MuiTypography-root {
    color: #1b2064;
    font-family: 'DINNextLTPro', sans-serif;
}

body .MuiSwitch-root + .MuiFormControlLabel-label {
    font-size: 16px;
    font-weight: 500;
}

.MuiInputBase-input.MuiFilledInput-input {
    padding-top: 13.5px;
    padding-bottom: 13.5px;
}

.MuiFormLabel-filled + .MuiFilledInput-root .MuiFilledInput-input {
    padding-top: 25px;
    padding-bottom: 8px;
}

[dir] .alert-custom {
    background-color: #ffecec;
    border-color: #ed4b48;
    color: #ed4b48;
    text-transform: uppercase;
    font-size: 12px;
    display: flex;
    align-items: center;
    padding: 8px 12px;
    font-weight: 600;
}

[dir] .alert-custom svg {
    margin-right: 8px;
}

.btn-outline-secondary:not(:hover) {
    color: #333;
}

.btn {
    box-shadow: 0px 4px 4px rgb(0 0 0 / 14%) !important;
}

.btn:hover {
    box-shadow: 0px 0 0 rgb(0 0 0 / 14%) !important;
}

.btn:not(:disabled):not(.disabled):active, .btn:not(:disabled):not(.disabled):active:focus {
    box-shadow: inset 0px -4px 12px rgb(0 0 0 / 14%) !important;
}

.milestone-root {
    background: #ffffff;
    border: 1px solid #e2e8f0;
    padding: 38px;
    border-radius: 10px;
}

.milestone {
    list-style: none;
    padding: 0;
    margin: 0;
    display: flex;
    overflow: hidden;
}

.milestone > li {
    flex: 1;
    position: relative;
}

.milestone > li:last-child {
    flex: 0 0 auto;
}

.milestone > li::before {
    content: '';
    position: absolute;
    top: 16px;
    height: 8px;
    width: 100%;
}

.milestone > li:last-child::before {
    display: none;
}

.milestone > li::before, .milestone .sign {
    background: #f0f0f0;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.08);
}

.milestone .sign {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    position: relative;
    margin-bottom: 11px;
    position: relative;
    z-index: 10;
}

.milestone .sign::before {
    content: '';
    position: absolute;
    top: 4px;
    bottom: 4px;
    left: 4px;
    right: 4px;
    border-radius: inherit;
}

.milestone  .sign::after,.milestone .sign::after  {
    content: '';
    position: absolute;
    top: 40%;
    bottom: 40%;
    left: 40%;
    right: 40%;
    width: auto;
    height: auto;
    border: none;
    background-color: white;
    border-radius: inherit;
}
.milestone .done:not(.originator) .sign::after,.milestone .approved:not(.originator) .sign::after  {
    content: ''!important;
    position: absolute!important;
    top: 11px!important;
    left: 16px!important;
    width: 8px!important;
    height: 14px!important;
    border-right: 2px solid #fff!important;
    border-bottom: 2px solid #fff!important;
    transform: rotate(45deg)!important;
    border-radius: unset!important;
    background-color: transparent!important;
}

.milestone .current .sign::after, .milestone .current ~ li .sign::after {
    top: 40%;
    bottom: 40%;
    left: 40%;
    right: 40%;
    width: auto;
    height: auto;
    border: none;
    background-color: white;
    border-radius: inherit;
}

.milestone .fastforward::after {
    background-color: #04A759;
}

.milestone > li.current:not(.done) .sign::before, .milestone > li.fastforward .sign::before {
    background-color: #fa6515;
}

.milestone > li.fastforward:before {
    background-color: #fa6515;
}

.milestone > li.current:not(.originator) .sign::before {
    background: linear-gradient(180deg, #06205D 0%, #111737 100%) !important;
}

.milestone .current .sign::after {
    background-color: #fff;
}

.milestone .label h4 {
    font-size: 16px;
    color: #1b2064;
    line-height: 1.2;
    margin-bottom: 0;
}

.milestone .label p {
    font-size: 13px;
    color: #333333;
    text-transform: uppercase;
    margin-bottom: 8px;
}

.milestone .current:after {
    content: '';
    position: absolute;
    top: 18px;
    height: 4px;
    width: 100vw;
    z-index: 8;
    right: 100%;
}

.milestone .approved:after {
    background-color: #04A759 !important;
    content: '';
    position: absolute;
    top: 18px;
    height: 4px;
    width: 100vw;
    z-index: 9;
    right: 100%;
}

.milestone .fastforward:after {
    content: '';
    position: absolute;
    top: 18px;
    height: 4px;
    width: 100vw;
    z-index: 8;
    right: 100%;
}

.milestone > li.approved .sign::before, .milestone > li.done .sign::before {
    background-color: #04A759 !important;
}

.milestone > li.rejected .sign::before {
    background-color: #ED4B48 !important;
}

.milestone > li.originator .sign::after {
    top: 50%;
    left: 54%;
    width: 13.2px;
    height: 16.8px;
    border: none;
    border-radius: 0;
    background-size: contain;
    transform: translate(-50%, -50%);
    background: url('data:image/svg+xml;base64,PHN2ZyB3aWR0aD0iMTQiIGhlaWdodD0iMTgiIHZpZXdCb3g9IjAgMCAxNCAxOCIgZmlsbD0ibm9uZSIgeG1sbnM9Imh0dHA6Ly93d3cudzMub3JnLzIwMDAvc3ZnIj4NCjxwYXRoIGZpbGwtcnVsZT0iZXZlbm9kZCIgY2xpcC1ydWxlPSJldmVub2RkIiBkPSJNMy42IDlDMy42IDkuMTU5MTMgMy42NjMyMSA5LjMxMTc0IDMuNzc1NzQgOS40MjQyNkMzLjg4ODI2IDkuNTM2NzkgNC4wNDA4NyA5LjYgNC4yIDkuNkgxMS4xNTE2TDguNTc1MiAxMi4xNzUyQzguNDYyNTQgMTIuMjg3OSA4LjM5OTI0IDEyLjQ0MDcgOC4zOTkyNCAxMi42QzguMzk5MjQgMTIuNzU5MyA4LjQ2MjU0IDEyLjkxMjEgOC41NzUyIDEzLjAyNDhDOC42ODc4NiAxMy4xMzc1IDguODQwNjcgMTMuMjAwOCA5IDEzLjIwMDhDOS4xNTkzMyAxMy4yMDA4IDkuMzEyMTQgMTMuMTM3NSA5LjQyNDggMTMuMDI0OEwxMy4wMjQ4IDkuNDI0OEMxMy4wODA3IDkuMzY5MDYgMTMuMTI1IDkuMzAyODUgMTMuMTU1MyA5LjIyOTk2QzEzLjE4NTUgOS4xNTcwNiAxMy4yMDExIDkuMDc4OTIgMTMuMjAxMSA5QzEzLjIwMTEgOC45MjEwOCAxMy4xODU1IDguODQyOTMgMTMuMTU1MyA4Ljc3MDA0QzEzLjEyNSA4LjY5NzE0IDEzLjA4MDcgOC42MzA5MyAxMy4wMjQ4IDguNTc1Mkw5LjQyNDggNC45NzUyQzkuMzEyMTQgNC44NjI1MyA5LjE1OTMzIDQuNzk5MjQgOSA0Ljc5OTI0QzguODQwNjcgNC43OTkyNCA4LjY4Nzg2IDQuODYyNTMgOC41NzUyIDQuOTc1MkM4LjQ2MjU0IDUuMDg3ODYgOC4zOTkyNCA1LjI0MDY3IDguMzk5MjQgNS40QzguMzk5MjQgNS41NTkzMyA4LjQ2MjU0IDUuNzEyMTQgOC41NzUyIDUuODI0OEwxMS4xNTE2IDguNEg0LjJDNC4wNDA4NyA4LjQgMy44ODgyNiA4LjQ2MzIxIDMuNzc1NzQgOC41NzU3NEMzLjY2MzIxIDguNjg4MjYgMy42IDguODQwODcgMy42IDlaTTAuNiAxNy40QzAuNDQwODcgMTcuNCAwLjI4ODI1OCAxNy4zMzY4IDAuMTc1NzM2IDE3LjIyNDNDMC4wNjMyMTQyIDE3LjExMTcgMCAxNi45NTkxIDAgMTYuOFYxLjJDMCAxLjA0MDg3IDAuMDYzMjE0MiAwLjg4ODI1NyAwLjE3NTczNiAwLjc3NTczNUMwLjI4ODI1OCAwLjY2MzIxMyAwLjQ0MDg3IDAuNTk5OTk5IDAuNiAwLjU5OTk5OUMwLjc1OTEzIDAuNTk5OTk5IDAuOTExNzQyIDAuNjYzMjEzIDEuMDI0MjYgMC43NzU3MzVDMS4xMzY3OSAwLjg4ODI1NyAxLjIgMS4wNDA4NyAxLjIgMS4yVjE2LjhDMS4yIDE2Ljk1OTEgMS4xMzY3OSAxNy4xMTE3IDEuMDI0MjYgMTcuMjI0M0MwLjkxMTc0MiAxNy4zMzY4IDAuNzU5MTMgMTcuNCAwLjYgMTcuNFoiIGZpbGw9IndoaXRlIi8+DQo8L3N2Zz4NCg==') no-repeat center;
}

.milestone > li.fastforward .sign::after {
    top: 50%;
    left: 50%;
    width: 40.2px;
    height: 28.8px;
    border: none;
    border-radius: 0;
    background-size: contain;
    transform: translate(-50%, -50%);
    background: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='26' height='26' fill='white' class='bi bi-clock-history' viewBox='0 0 16 16'%3E%3Cpath d='M8.515 1.019A7 7 0 0 0 8 1V0a8 8 0 0 1 .589.022l-.074.997zm2.004.45a7.003 7.003 0 0 0-.985-.299l.219-.976c.383.086.76.2 1.126.342l-.36.933zm1.37.71a7.01 7.01 0 0 0-.439-.27l.493-.87a8.025 8.025 0 0 1 .979.654l-.615.789a6.996 6.996 0 0 0-.418-.302zm1.834 1.79a6.99 6.99 0 0 0-.653-.796l.724-.69c.27.285.52.59.747.91l-.818.576zm.744 1.352a7.08 7.08 0 0 0-.214-.468l.893-.45a7.976 7.976 0 0 1 .45 1.088l-.95.313a7.023 7.023 0 0 0-.179-.483zm.53 2.507a6.991 6.991 0 0 0-.1-1.025l.985-.17c.067.386.106.778.116 1.17l-1 .025zm-.131 1.538c.033-.17.06-.339.081-.51l.993.123a7.957 7.957 0 0 1-.23 1.155l-.964-.267c.046-.165.086-.332.12-.501zm-.952 2.379c.184-.29.346-.594.486-.908l.914.405c-.16.36-.345.706-.555 1.038l-.845-.535zm-.964 1.205c.122-.122.239-.248.35-.378l.758.653a8.073 8.073 0 0 1-.401.432l-.707-.707z'/%3E%3Cpath d='M8 1a7 7 0 1 0 4.95 11.95l.707.707A8.001 8.001 0 1 1 8 0v1z'/%3E%3Cpath d='M7.5 3a.5.5 0 0 1 .5.5v5.21l3.248 1.856a.5.5 0 0 1-.496.868l-3.5-2A.5.5 0 0 1 7 9V3.5a.5.5 0 0 1 .5-.5z'/%3E%3C/svg%3E") no-repeat center;
}

.milestone li.rejected .sign::after {
    top: 50%;
    left: 50%;
    width: 13.2px;
    height: 16.8px;
    border: none;
    border-radius: 0;
    background-size: contain;
    transform: translate(-50%, -50%);
    color: white;
    background: url("data:image/svg+xml;base64,ICAgIAo8c3ZnIHN0eWxlPSJjb2xvcjogd2hpdGUiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgd2lkdGg9IjI2IiBoZWlnaHQ9IjI2IiBmaWxsPSJjdXJyZW50Q29sb3IiIGNsYXNzPSJiaSBiaS14IiB2aWV3Qm94PSIwIDAgMTYgMTYiPiAKPHBhdGggZD0iTTQuNjQ2IDQuNjQ2YS41LjUgMCAwIDEgLjcwOCAwTDggNy4yOTNsMi42NDYtMi42NDdhLjUuNSAwIDAgMSAuNzA4LjcwOEw4LjcwNyA4bDIuNjQ3IDIuNjQ2YS41LjUgMCAwIDEtLjcwOC43MDhMOCA4LjcwN2wtMi42NDYgMi42NDdhLjUuNSAwIDAgMS0uNzA4LS43MDhMNy4yOTMgOCA0LjY0NiA1LjM1NGEuNS41IDAgMCAxIDAtLjcwOHoiIGZpbGw9IndoaXRlIj48L3BhdGg+CiA8L3N2Zz4=") no-repeat center;
}

@media (max-width: 1199px) {
    .milestone {
        display: block;
    }

    .milestone > li {
        padding-bottom: 18px;
        display: flex;
    }

    .milestone > li::before, .milestone .sign {
        box-shadow: inset 4px 0px 4px rgb(0 0 0 / 8%);
    }

    .milestone > li::before {
        top: 0;
        height: 100%;
        width: 8px;
        left: 16px;
    }

    .milestone .sign {
        flex: 0 0 auto;
        margin-right: 10px;
    }

    .milestone .current:after {
        top: -980px;
        height: 1000px;
        width: 4px;
        right: auto;
        left: 18px;
    }
}

.dropbtn {
    padding: 5px 30px 5px 12px;
    font-size: 16px;
    color: inherit;
    border: 1px solid #dfe0df;
    background: no-repeat;
    border-radius: 4px;
    min-width: 112px;
    text-align: left;
    position: relative;
    transition: 0.3s ease-in-out;
    outline: none;
}

.dropbtn:hover {
    background: #333;
    color: #fff;
}

.dropbtn:after {
    content: '';
    border-bottom: 2px solid;
    border-right: 2px solid;
    height: 9px;
    width: 9px;
    position: absolute;
    top: 10px;
    right: 12px;
    transform: rotate(45deg);
}

.droplist {
    position: fixed;
    top: 0;
    left: 0;
    width: 230px;
    padding: 8px;
    z-index: 10000;
    background: #fff;
    opacity: 0;
    visibility: hidden;
    transform: translateY(30px);
    box-shadow: 0px 10px 8px rgba(0, 0, 0, 0.04), 0px 4px 3px rgba(0, 0, 0, 0.1);
    margin-top: 4px;
    border-radius: 8px;
}

.droplist.active {
    opacity: 1;
    visibility: visible;
    transform: translateY(0);
    transition: opacity 0.3s, visibility 0.3s, transform 0.3s;
}

.droplist .item {
    display: block;
    background: none;
    outline: none;
    text-align: left;
    padding: 4px 10px;
    border: 2px solid transparent;
    border-radius: 6px;
    transition: border-color 0.3s;
    margin-bottom: 4px;
    width: 100%;
}

.droplist .item:hover {
    border-color: #1b2064;
}

.droplist .item svg {
    margin-right: 4px;
}

.droplist .item [class*='fa-'] {
    font-size: 22px;
    margin-right: 4px;
}

.btn-orange {
    background: var(--orange) !important;
    color: #fff;
}

.btn.btn-orange:hover, .btn.btn-orange:not(:disabled):not(.disabled):active {
    color: #fff;
    border-color: #e86a02;
    background: #e86a02 !important;
}

.btn.btn-normal {
    text-transform: none;
    font-weight: 500;
    font-size: 14px;
    padding: 11px 20px;
}

.btn .bi {
    font-size: 16px;
    vertical-align: middle;
}

.popup .modal-header {
    padding: 1rem 1rem 0;
    border-bottom: none;
}

.popup .modal-body {
    padding: 0 1rem 1rem;
}

.popup .modal-content {
    border: none;
}

.popup .btn-close {
    height: 0.8em;
    width: 0.8em;
    background-size: 0.8em;
}

.popup .modal-footer {
    background: #f2f5f8;
    border-top-color: #e5e7eb;
}

.popup .modal-footer .btn {
    line-height: 1;
    text-transform: none;
    font-size: 14px;
    padding: 14px 16px;
}

@media (min-width: 576px) {
    .popup .modal-dialog {
        max-width: 590px;
    }
}

.generate-roport-form {
    padding: 0 24px;
}

.generate-roport-form .header {
    text-align: center;
    color: #6b7280;
    font-size: 16px;
}

.generate-roport-form .header .title {
    color: #1f2937;
    font-size: 20px;
    margin-bottom: 7px;
}

.generate-roport-form .field-group {
    display: flex;
    margin-top: 17px;
    border: 1px solid #e5e7eb;
    border-radius: 10px;
    padding: 15px 20px;
}

.generate-roport-form .field-group .text {
    flex: 1;
    padding: 0 10px;
}

.generate-roport-form .field-group .text h6 {
    font-size: 16px;
    color: #1b2064;
    margin-bottom: 3px;
}

.generate-roport-form .input {
    flex: 0 0 46%;
}

.form-card .accordion-item {
    background-color: inherit !important;
}

.sort-head {
    cursor: pointer;
}

.sort-head .fas {
    opacity: 0;
    position: absolute;
    margin-left: 5px;
    margin-top: 3px;
}

.sort-head:hover .fas {
    opacity: 0.5;
}

.sort-head .fas.active {
    opacity: 1;
}

.ck-content, .comment-box, .note-box {
    background-color: white !important;
}

.ck-content a {
    text-decoration: underline;
    color: blue;
    cursor: pointer;
  }
  
  .ck-content a:hover {
    color: red;
  }

.disable{
    pointer-events: none;
    opacity: 0.5;
}