.rs-nav-default .rs-nav-item:hover{
    background: transparent!important;
}
.rs-nav-default .rs-nav-item, #header .nav-link{
    color: #F5F7FF !important;
    font-weight: 700;
}
#seconderyNavbar .rs-nav-default .rs-nav-item,#seconderyNavbar .rs-dropdown-item, #header .dropdown-item{
    color:  #1B2064 !important;
    font-family: 'DINNextLTPro';
    font-weight: 400;
}

